import { DuplicatePaymentManagementService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";

export const useDuplicatePaymentsListQuery = () => {
  const query = usePaginatedDataTableQuery({
    queryFn: DuplicatePaymentManagementService.list.request,
    queryKey: DuplicatePaymentManagementService.list.queryKey
  });

  const onFetchData = async () => {
    await query.refetch();
  };

  return {
    onFetchData,
    ...query
  };
};
