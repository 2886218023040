import { createContext, useContext } from "react";
import {
  CommonStore,
  ConfirmModalStore,
  DocumentsStore,
  RolesStore,
  ErrorStore,
  FormLayoutStore,
  FolderManagementStore,
  BreadcrumbStore,
  PatientFileStore,
  UserPermissionsStore, ActiveReportStore
} from "stores";

type Store = {
  commonStore: CommonStore,
  confirmModalStore: ConfirmModalStore,
  documentsStore: DocumentsStore,
  rolesStore: RolesStore,
  formLayoutStore: FormLayoutStore,
  errorStore: ErrorStore,
  folderManagementStore: FolderManagementStore,
  breadcrumbStore: BreadcrumbStore,
  patientFileStore: PatientFileStore,
  userPermissionsStore: UserPermissionsStore,
  activeReportStore: ActiveReportStore
}

export const store: Store = {
  commonStore: new CommonStore(),
  confirmModalStore: new ConfirmModalStore(),
  documentsStore: new DocumentsStore(),
  rolesStore: new RolesStore(),
  formLayoutStore: new FormLayoutStore(),
  errorStore: new ErrorStore(),
  folderManagementStore: new FolderManagementStore(),
  breadcrumbStore: new BreadcrumbStore(),
  patientFileStore: new PatientFileStore(),
  userPermissionsStore: new UserPermissionsStore(),
  activeReportStore: new ActiveReportStore()
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}