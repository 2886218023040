import { PropsWithChildren } from "react";
import { useAuthorization } from "hooks";

type AuthorizationWrapperProps = PropsWithChildren & {
  permissions: string[];
}
function AuthorizationWrapper({ permissions, children }: AuthorizationWrapperProps) {
  const { hasAccess } = useAuthorization(permissions ?? []);
  if(!hasAccess) return null;

  return <>{children}</>;
}

export default AuthorizationWrapper;