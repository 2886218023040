import { usePaginatedDataTableQuery } from "hooks/utils";
import { AttorneyManagementService } from "api";
import { AttorneyForListDto } from "types";

export const useAttorneyListQuery = () => {
  const attorneysListQuery = usePaginatedDataTableQuery<AttorneyForListDto>({
    queryFn: queryParams => AttorneyManagementService.list.request(queryParams),
    queryKey: AttorneyManagementService.list.queryKey
  });

  return {
    ...attorneysListQuery
  };
};
