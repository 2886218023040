import { useQuery } from "@tanstack/react-query";
import { UsersManagementService } from "api";

export const useProfileQuery = () => {
  const profileQuery = useQuery({
    queryKey: [UsersManagementService.details.queryKey, "me"],
    queryFn: () => UsersManagementService.details.request("me"),
    enabled: false,
    retry: false
  });

  const onFetchProfile = () => {
    return profileQuery.refetch();
  };

  return {
    ...profileQuery,
    onFetchProfile
  };
};