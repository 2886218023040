import { usePaginatedDataTableQuery } from "hooks/utils";
import { AdjustersManagementService } from "api";
import { InsuranceCompanyForListDto } from "types";

export const useAdjustersListQuery = () => {
  const adjustersListQuery = usePaginatedDataTableQuery<InsuranceCompanyForListDto>({
    queryFn: queryParams =>
      AdjustersManagementService.list.request(queryParams),
    queryKey: AdjustersManagementService.list.queryKey
  });

  return {
    ...adjustersListQuery
  };
};
