import { useMutation } from "@tanstack/react-query";
import { DoctorManagementService } from "api";
import React from "react";
import { store } from "stores";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";

export const useEnableDoctorMutation = () => {
  const enableDoctorMutation = useMutation({
    mutationKey: [DoctorManagementService.enable.queryKey],
    mutationFn: (doctorId: string) => DoctorManagementService.enable.request(doctorId),
    onSuccess() {
      SnackbarUtils.success("Doctor enabled successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });
  
  const onEnableDoctor = (doctorId?: string) => {
    if(!doctorId) return;
    enableDoctorMutation.mutate(doctorId);
  };
  
  const enableDoctorHandler = (doctorId: string, doctorName: string, callback?: Function) => {
    store.confirmModalStore.open({
      title: "Confirm account deactivation",
      body: <p>Are you sure you want to enable <strong>{doctorName}</strong> account?</p>,
      options: {
        onConfirm: async () => {
          try {
            await onEnableDoctor(doctorId);
            if(callback) await callback();
          } catch(e) {
            console.error(e);
          }
        }
      }
    });
  };
  return {
    onEnableDoctor,
    enableDoctorHandler,
    ...enableDoctorMutation
  };
};