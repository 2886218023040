import { useMutation } from "@tanstack/react-query";
import { ActiveReportService } from "api";
import { ApiError } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useParams } from "react-router-dom";

export const useUnSignReportMutation = () => {
  const { activeReportId } = useParams<{ activeReportId: string }>();
  const unSignReportMutation = useMutation({
    mutationKey: [ActiveReportService.unsign.queryKey],
    mutationFn: () => ActiveReportService.unsign.request(activeReportId!),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Active report unsigned successfully");
    }
  });

  const onUnSignReport = async () => {
    return await unSignReportMutation.mutateAsync();
  };

  return {
    ...unSignReportMutation,
    onUnSignReport
  };
};