import React, { HTMLAttributeAnchorTarget } from "react";
import { Button, ButtonProps } from "@mui/material";

type PrimeButtonProps = ButtonProps & {
  target?: HTMLAttributeAnchorTarget | undefined;
}
function PrimeButton ({ children, ...rest }: PrimeButtonProps): JSX.Element {
  return (
    <Button variant="contained" {...rest}>
      {children}
    </Button>
  );
}

export default PrimeButton;