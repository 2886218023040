import { LookupService } from "api";
import { useQuery } from "@tanstack/react-query";

export const useAdjustersLookupQuery = (adjusterName: string) => {
  const adjustersLookupQuery = useQuery({
    queryKey: [LookupService.adjusters.queryKey, adjusterName],
    queryFn: ({ queryKey }) => {
      const [, adjusterName] = queryKey;
      return LookupService.adjusters.request(adjusterName);
    },
    enabled: !!adjusterName
  });

  return {
    ...adjustersLookupQuery,
    options:
      adjustersLookupQuery.data
        ?.filter(x => !!x.name)
        .map(x => ({ id: x.id, label: x.name })) ?? []
  };
};
