import { useIsLoadingMutation } from "hooks/utils";
import { DocumentsService } from "api";

export const useIsLoadingUploadDocuments = () => {
  const { isLoading } = useIsLoadingMutation(
    DocumentsService.uploadFiles.queryKey
  );
  const { isLoading: isLoadingMultipart } = useIsLoadingMutation(
    DocumentsService.uploadMultiPartFile.queryKey
  );
  const { isLoading: isLoadingMultipartDetails } = useIsLoadingMutation(
    DocumentsService.multipartUploadDetails.queryKey
  );

  return {
    isLoading: isLoading || isLoadingMultipart || isLoadingMultipartDetails
  };
};
