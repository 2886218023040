import { useMutation } from "@tanstack/react-query";
import { DoctorManagementService } from "api";
import React from "react";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { store } from "stores";
import { ApiError } from "types";

export const useDisableDoctorMutation = () => {
  const disableDoctorMutation = useMutation({
    mutationKey: [DoctorManagementService.disable.queryKey],
    mutationFn: (doctorId: string) => DoctorManagementService.disable.request(doctorId),
    onSuccess() {
      SnackbarUtils.success("Doctor disabled successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });
  
  const onDisableDoctor = async (doctorId?: string) => {
    if(!doctorId) return;
    return await disableDoctorMutation.mutateAsync(doctorId);
  };
  
  const disableDoctorHandler = (doctorId: string, doctorName: string, callback?: Function) => {
    store.confirmModalStore.open({
      title: "Confirm account deactivation",
      body: <p>Are you sure you want to disable <strong>{doctorName}</strong> account?</p>,
      options: {
        onConfirm: async () => {
          try {
            await onDisableDoctor(doctorId);
            if(callback) await callback();
          } catch(e) {
            console.error(e);
          }
        }
      }
    });
  };
  
  return {
    onDisableDoctor,
    disableDoctorHandler,
    ...disableDoctorMutation
  };
};