import { AuthLoginRequest, msalInstance } from "config";
import { AppRoutes } from "router/AppRoutes";
import { SnackbarUtils } from "utils";
import { router } from "router";

async function loginRequest() {
  const response = await msalInstance.loginRedirect(AuthLoginRequest);
  await router.navigate(`/${AppRoutes.doctors.root}`);
  SnackbarUtils.success("Login successfully", {
    toastId: "login-id"
  });
  return response;
}

async function logoutRequest() {
  const accounts = msalInstance.getAllAccounts();
  const account = accounts[0];
  return await msalInstance.logoutPopup({ account });
}

async function tokenRequest() {
  const accounts = msalInstance.getAllAccounts();
  const account = accounts[0];
  return await msalInstance.acquireTokenSilent({
    ...AuthLoginRequest,
    account
  });
}

export const AuthService = {
  login: {
    request: loginRequest,
    queryKey: "login"
  },
  logout: {
    request: logoutRequest,
    queryKey: "logout"
  },
  token: {
    request: tokenRequest,
    queryKey: "getToken"
  }
};