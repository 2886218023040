import { useQuery } from "@tanstack/react-query";
import { DoctorManagementService } from "api";
import { ApiError } from "types";
import { ErrorsUtils } from "utils";
export const useDownloadSignatureQuery = (doctorId: string, signatureId?: string | null) => {
  const downloadSignatureQuery = useQuery({
    queryKey: [DoctorManagementService.downloadSignature.queryKey],
    queryFn: () => DoctorManagementService.downloadSignature.request(doctorId, signatureId),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    enabled: false
  });

  return {
    ...downloadSignatureQuery
  };
};