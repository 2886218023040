import { MRT_ColumnDef } from "material-react-table";
import { AppointmentFinanceForListDto, EAppointmentType, EDuplicatePaymentTransactionPaidStatus } from "types";
import { DateUtils, FormatUtils } from "utils";
import { useUsersLookupQuery, usePermissions } from "hooks";
import { AppPermissions, UserRoles } from "app-constants";
import { Chip } from "@mui/material";

export const useFinancialsDetailsColumns = () => {
  const { data: doctorsLookup } = useUsersLookupQuery(UserRoles.Doctor);
  const { hasPermission } = usePermissions();
  const billerView = hasPermission([AppPermissions.BillingManagement.Actions.BillerView]);
  const financeView = hasPermission([AppPermissions.BillingManagement.Actions.FinanceView]);
  const doctorView = hasPermission([AppPermissions.BillingManagement.Actions.DoctorView]);
  const fullView = hasPermission([AppPermissions.BillingManagement.Actions.View]);

  const fullViewColumns: Array<MRT_ColumnDef<AppointmentFinanceForListDto>> = [
    {
      header: "Doctor Name",
      accessorKey: "doctorName",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "assignedDoctorIdsFilter",
      filterVariant: "multi-select",
      filterSelectOptions: FormatUtils.formatDropdownOptions("id", "name", doctorsLookup)
    },
    {
      header: "Patient Name",
      accessorKey: "patientName",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Received Date",
      accessorKey: "receivedDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.receivedDate ?? "");
      }
    },
    {
      header: "Date of Service",
      accessorKey: "dateOfService",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.dateOfService ?? "");
      }
    },
    {
      header: "Appointment Type",
      accessorKey: "appointmentType",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "typesFilter",
      filterVariant: "multi-select",
      filterSelectOptions: [
        {
          value: EAppointmentType.InitialEvaluation,
          text: "Initial Evaluation"
        },
        {
          value: EAppointmentType.ReEvaluation,
          text: "Re Evaluation"
        },
        {
          value: EAppointmentType.Supplemental,
          text: "Supplemental"
        },
        {
          value: EAppointmentType.Deposition,
          text: "Deposition"
        }
      ],
      Cell: ({ row }) => {
        switch (row.original.appointmentType) {
          case EAppointmentType.InitialEvaluation:
            return "Initial Evaluation";
          case EAppointmentType.ReEvaluation:
            return "Re Evaluation";
          case EAppointmentType.Supplemental:
            return "Supplemental";
          case EAppointmentType.Deposition:
            return "Deposition";
        }
      }
    },
    {
      header: "Expected Amount",
      accessorKey: "expectedAmount",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    },
    {
      header: "Payments Total Amount",
      accessorKey: "expectedAmount", // TODO: Change to actual payment amount
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    },
    {
      header: "Number of Payments",
      accessorKey: "expectedAmount", // TODO: Change to actual Number of Payments
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    },
    {
      header: "Deposit Date",
      accessorKey: "depositDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.depositDate ?? "");
      }
    },
    {
      header: "Financial Status",
      accessorKey: "paidStatus",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        switch (row.original.paidStatus) {
          case EDuplicatePaymentTransactionPaidStatus.NotPaid:
            return <Chip label='Unpaid' size='small' color='default' />;
          case EDuplicatePaymentTransactionPaidStatus.FullyPaid:
            return <Chip label='Paid' size='small' color='default' />;
          case EDuplicatePaymentTransactionPaidStatus.PartiallyPaid:
            return <Chip label='Partially Paid' size='small' color='default' />;
        }
      },
      id: "statusFilter",
      filterVariant: "multi-select",
      enableColumnFilter: true,
      filterSelectOptions: [
        {
          value: EDuplicatePaymentTransactionPaidStatus.NotPaid,
          text: "Unpaid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.FullyPaid,
          text: "Paid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.PartiallyPaid,
          text: "Partially Paid"
        }
      ]
    },
    {
      header: "Doctor Paid Date",
      accessorKey: "dateOfService", // TODO: Change to actual Doctor Paid Date
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.depositDate ?? "");
      }
    }
  ];
  const billerViewColumns: Array<MRT_ColumnDef<AppointmentFinanceForListDto>> = [
    {
      header: "Doctor Name",
      accessorKey: "doctorName",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "assignedDoctorIdsFilter",
      filterVariant: "multi-select",
      filterSelectOptions: FormatUtils.formatDropdownOptions("id", "name", doctorsLookup)
    },
    {
      header: "Patient Name",
      accessorKey: "patientName",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Received Date",
      accessorKey: "receivedDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.receivedDate ?? "");
      }
    },
    {
      header: "Date of Service",
      accessorKey: "dateOfService",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.dateOfService ?? "");
      }
    },
    {
      header: "Appointment Type",
      accessorKey: "appointmentType",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "typesFilter",
      filterVariant: "multi-select",
      filterSelectOptions: [
        {
          value: EAppointmentType.InitialEvaluation,
          text: "Initial Evaluation"
        },
        {
          value: EAppointmentType.ReEvaluation,
          text: "Re Evaluation"
        },
        {
          value: EAppointmentType.Supplemental,
          text: "Supplemental"
        },
        {
          value: EAppointmentType.Deposition,
          text: "Deposition"
        }
      ],
      Cell: ({ row }) => {
        switch (row.original.appointmentType) {
          case EAppointmentType.InitialEvaluation:
            return "Initial Evaluation";
          case EAppointmentType.ReEvaluation:
            return "Re Evaluation";
          case EAppointmentType.Supplemental:
            return "Supplemental";
          case EAppointmentType.Deposition:
            return "Deposition";
        }
      }
    },
    {
      header: "Expected Amount",
      accessorKey: "expectedAmount",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    }
  ];
  const financeViewColumns: Array<MRT_ColumnDef<AppointmentFinanceForListDto>> = [
    {
      header: "Doctor Name",
      accessorKey: "doctorName",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "assignedDoctorIdsFilter",
      filterVariant: "multi-select",
      filterSelectOptions: FormatUtils.formatDropdownOptions("id", "name", doctorsLookup)
    },
    {
      header: "Patient Name",
      accessorKey: "patientName",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Received Date",
      accessorKey: "receivedDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.receivedDate ?? "");
      }
    },
    {
      header: "Date of Service",
      accessorKey: "dateOfService",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.dateOfService ?? "");
      }
    },
    {
      header: "Appointment Type",
      accessorKey: "appointmentType",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "typesFilter",
      filterVariant: "multi-select",
      filterSelectOptions: [
        {
          value: EAppointmentType.InitialEvaluation,
          text: "Initial Evaluation"
        },
        {
          value: EAppointmentType.ReEvaluation,
          text: "Re Evaluation"
        },
        {
          value: EAppointmentType.Supplemental,
          text: "Supplemental"
        },
        {
          value: EAppointmentType.Deposition,
          text: "Deposition"
        }
      ],
      Cell: ({ row }) => {
        switch (row.original.appointmentType) {
          case EAppointmentType.InitialEvaluation:
            return "Initial Evaluation";
          case EAppointmentType.ReEvaluation:
            return "Re Evaluation";
          case EAppointmentType.Supplemental:
            return "Supplemental";
          case EAppointmentType.Deposition:
            return "Deposition";
        }
      }
    },
    {
      header: "Expected Amount",
      accessorKey: "expectedAmount",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    },
    {
      header: "Number of Payments",
      accessorKey: "expectedAmount", // TODO: Change to actual Number of Payments
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    },
    {
      header: "Financial Status",
      accessorKey: "paidStatus",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        switch (row.original.paidStatus) {
          case EDuplicatePaymentTransactionPaidStatus.NotPaid:
            return <Chip label='Unpaid' size='small' color='default' />;
          case EDuplicatePaymentTransactionPaidStatus.FullyPaid:
            return <Chip label='Paid' size='small' color='default' />;
          case EDuplicatePaymentTransactionPaidStatus.PartiallyPaid:
            return <Chip label='Partially Paid' size='small' color='default' />;
        }
      },
      id: "statusFilter",
      filterVariant: "multi-select",
      enableColumnFilter: true,
      filterSelectOptions: [
        {
          value: EDuplicatePaymentTransactionPaidStatus.NotPaid,
          text: "Unpaid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.FullyPaid,
          text: "Paid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.PartiallyPaid,
          text: "Partially Paid"
        }
      ]
    }
  ];
  const doctorViewColumns: Array<MRT_ColumnDef<AppointmentFinanceForListDto>> = [
    {
      header: "Patient Name",
      accessorKey: "patientName",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Received Date",
      accessorKey: "receivedDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.receivedDate ?? "");
      }
    },
    {
      header: "Date of Service",
      accessorKey: "dateOfService",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.dateOfService ?? "");
      }
    },
    {
      header: "Appointment Type",
      accessorKey: "appointmentType",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "typesFilter",
      filterVariant: "multi-select",
      filterSelectOptions: [
        {
          value: EAppointmentType.InitialEvaluation,
          text: "Initial Evaluation"
        },
        {
          value: EAppointmentType.ReEvaluation,
          text: "Re Evaluation"
        },
        {
          value: EAppointmentType.Supplemental,
          text: "Supplemental"
        },
        {
          value: EAppointmentType.Deposition,
          text: "Deposition"
        }
      ],
      Cell: ({ row }) => {
        switch (row.original.appointmentType) {
          case EAppointmentType.InitialEvaluation:
            return "Initial Evaluation";
          case EAppointmentType.ReEvaluation:
            return "Re Evaluation";
          case EAppointmentType.Supplemental:
            return "Supplemental";
          case EAppointmentType.Deposition:
            return "Deposition";
        }
      }
    },
    {
      header: "Payments Total Amount",
      accessorKey: "expectedAmount", // TODO: Change to actual payment amount
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    },
    {
      header: "Financial Status",
      accessorKey: "paidStatus",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        switch (row.original.paidStatus) {
          case EDuplicatePaymentTransactionPaidStatus.NotPaid:
            return <Chip label='Unpaid' size='small' color='default' />;
          case EDuplicatePaymentTransactionPaidStatus.FullyPaid:
            return <Chip label='Paid' size='small' color='default' />;
          case EDuplicatePaymentTransactionPaidStatus.PartiallyPaid:
            return <Chip label='Partially Paid' size='small' color='default' />;
        }
      },
      id: "statusFilter",
      filterVariant: "multi-select",
      enableColumnFilter: true,
      filterSelectOptions: [
        {
          value: EDuplicatePaymentTransactionPaidStatus.NotPaid,
          text: "Unpaid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.FullyPaid,
          text: "Paid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.PartiallyPaid,
          text: "Partially Paid"
        }
      ]
    },
    {
      header: "Doctor Paid Date",
      accessorKey: "dateOfService", // TODO: Change to actual Doctor Paid Date
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.depositDate ?? "");
      }
    }
  ];

  let columns: Array<MRT_ColumnDef<AppointmentFinanceForListDto>>;
  if (fullView) {
    columns = fullViewColumns;
  } else if (financeView) {
    columns = financeViewColumns;
  } else if (doctorView) {
    columns = doctorViewColumns;
  } else if (billerView) {
    columns = billerViewColumns;
  } else {
    columns = [];
  }
  return {
    columns
  };
};
