import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";

export const useBodyPartsLookupQuery = () => {
  const bodyPartsLookupQuery = useQuery({
    queryKey: [LookupService.bodyParts.queryKey],
    queryFn: LookupService.bodyParts.request
  });

  const getSpecialityById = (bodyPartId?: string) => {
    if(!bodyPartId) return "";
    const bodyParts = bodyPartsLookupQuery.data;
    return bodyParts?.find(bodyPart => bodyPart.id === bodyPartId)?.name ?? "";
  };
  const getBodyPartsByIds = (bodyParts?: string[]): string[] => {
    if(!bodyParts?.length) return [];
    const bodyPartsIds = bodyPartsLookupQuery.data;
    return bodyPartsIds?.filter(bodyPart => bodyParts?.includes(bodyPart.id))?.map((bodyPart) => bodyPart.name ?? "") ?? [];
  };
  return {
    ...bodyPartsLookupQuery,
    getSpecialityById,
    getBodyPartsByIds
  };
};