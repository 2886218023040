import { MRT_TableInstance, MRT_ShowHideColumnsButton as ShowHideColumnsButton } from "material-react-table";
import React from "react";

type FilterProps<TData extends Record<string, any> = {}> = {
  table: MRT_TableInstance<TData>;
};
function ViewColumn<T extends Record<string, any> = {}>({ table }: FilterProps<T>) {

  return (
    <ShowHideColumnsButton table={table}/>
  );
}

export default ViewColumn;