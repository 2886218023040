import { SnackbarUtils } from "utils";
import { ApiError } from "types";
import { AUTH } from "app-constants";

export default class ErrorsUtils {
  static renderApiErrors(error: ApiError) {
    if(ErrorsUtils.isInvalidEmail(error)) {
      SnackbarUtils.error(AUTH.INVALID_EMAIL_MESSAGE);
      return;
    }

    if(!error.details) {
      SnackbarUtils.error(error.message);
      return;
    }
    
    for(const errorKey in error.details) {
      const errorMessage = error?.details?.[errorKey]?.[0];
      if(errorMessage) {
        SnackbarUtils.error(errorMessage);
      }
    }
  }

  static isInvalidEmail = (error: ApiError) => {
    return error.details?.GenericError?.[0].includes(AUTH.MICROSOFT_GRAPH_API_ERROR_MESSAGE);
  };
}