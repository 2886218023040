import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MSAlConfig } from "config";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(MSAlConfig);
msalInstance.initialize().then(async () => {
  await msalInstance.handleRedirectPromise();
  msalInstance.addEventCallback((message) => {
    switch(message.eventType) {
      case EventType.LOGIN_FAILURE:
        console.error("login failure", message.error);
        break;
      case EventType.ACQUIRE_TOKEN_FAILURE:
        console.error("acquire token failure", message.error);
        break;
      case EventType.SSO_SILENT_FAILURE:
        console.error("silent failure", message.error);
        break;
      case EventType.LOGOUT_FAILURE:
        console.error("logout failure", message.error);
        break;
    }
  });
});