import { useMutation } from "@tanstack/react-query";
import { DocumentsService } from "api";
import { ErrorsUtils } from "utils";
import { ApiError } from "types";
import { useIsLoadingMutation } from "hooks";

type UploadSingleDocumentPayload = {
  folderId: string;
  files: File[];
  URL: string;
  entityId: string;
};
export const useUploadSingleDocumentMutation = () => {
  const uploadSingleDocumentMutation = useMutation({
    mutationKey: [DocumentsService.uploadFiles.queryKey],
    mutationFn: ({
      folderId,
      files,
      URL,
      entityId
    }: UploadSingleDocumentPayload) => {
      return DocumentsService.uploadFiles.request(
        URL,
        entityId,
        folderId,
        files
      );
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    retry: 3,
    retryDelay: 1000
  });

  const { isLoading } = useIsLoadingMutation(
    DocumentsService.uploadFiles.queryKey
  );

  const uploadSingleDocument = (payload: UploadSingleDocumentPayload) => {
    return uploadSingleDocumentMutation.mutateAsync({
      files: payload.files,
      folderId: payload.folderId,
      URL: payload.URL,
      entityId: payload.entityId
    });
  };

  return {
    ...uploadSingleDocumentMutation,
    uploadSingleDocument,
    isLoading
  };
};
