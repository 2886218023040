import { useMutation } from "@tanstack/react-query";
import { DocumentsService } from "api";
import { ApiError, MultipartUploadDetailsPayload } from "types";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils } from "utils";

export const useGetDocumentMultipartDetailsMutation = () => {
  const getDocumentMultipartDetailsMutation = useMutation({
    mutationKey: [DocumentsService.multipartUploadDetails.queryKey],
    mutationFn: (payload: MultipartUploadDetailsPayload) =>
      DocumentsService.multipartUploadDetails.request(payload),
    retry: 3,
    retryDelay: 1000,
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const { isLoading } = useIsLoadingMutation(
    DocumentsService.multipartUploadDetails.queryKey
  );

  const getDocumentMultipartDetails = (payload: MultipartUploadDetailsPayload) => {
    return getDocumentMultipartDetailsMutation.mutateAsync(payload);
  };

  return {
    ...getDocumentMultipartDetailsMutation,
    getDocumentMultipartDetails,
    isLoading
  };
};
