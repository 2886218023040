import { useQuery } from "@tanstack/react-query";
import { UsersManagementService } from "api";
import { useParams } from "react-router-dom";

export const useUserProfileQuery = () => {
  const { userId } = useParams<{ userId: string }>();
  const userProfileQuery = useQuery({
    queryKey: [UsersManagementService.details.queryKey, userId],
    queryFn: () => UsersManagementService.details.request(userId!)
  });

  return {
    ...userProfileQuery
  };
};