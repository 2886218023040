import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { RoleManagementService } from "api";

export const useRoleDetailsQuery = () => {
  const { roleId } = useParams<{ roleId: string }>();
  const roleDetailsQuery = useQuery({
    queryKey: [RoleManagementService.details.queryKey],
    queryFn: () => RoleManagementService.details.request(roleId!)
  });

  return {
    ...roleDetailsQuery,
    roleId
  };
};