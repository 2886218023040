import { useMutation } from "@tanstack/react-query";
import { AttorneyManagementService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, AttorneyForCreateUpdateDto } from "types";
import { useNavigate, useParams } from "react-router-dom";

export const useUpdateAttorneyMutation = () => {
  const navigate = useNavigate();
  const { attorneyId } = useParams<{ attorneyId: string }>();
  const updateAttorneyMutation = useMutation({
    mutationKey: [AttorneyManagementService.update.mutationKey],
    mutationFn: (attorney: AttorneyForCreateUpdateDto) => AttorneyManagementService.update.request(attorneyId!, attorney),
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Attorney updated successfully!");
      navigate(-1);
    }
  });

  const onUpdateAttorney = (attorney: AttorneyForCreateUpdateDto) => {
    if(!attorney) return;
    updateAttorneyMutation.mutate(attorney);
  };

  return {
    onUpdateAttorney,
    ...updateAttorneyMutation
  };
};