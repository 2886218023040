const YOUR_ACCOUNT_IS_DISABLED_MSG = "Your account is disabled, please contact your system admin";
const YOUR_ACCOUNT_IS_DISABLED_KEY = "User is Lockedout or Deleted";
const MICROSOFT_GRAPH_API_ERROR_MESSAGE = "Property netId is invalid.";
const INVALID_EMAIL_MESSAGE = "Please use a different email, email is invalid";
const AUTH = Object.freeze({
  YOUR_ACCOUNT_IS_DISABLED_MSG,
  YOUR_ACCOUNT_IS_DISABLED_KEY,
  MICROSOFT_GRAPH_API_ERROR_MESSAGE,
  INVALID_EMAIL_MESSAGE
});

export default AUTH;