import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";

export const useLocationsLookup = () => {
  const lookupUserGroupsQuery = useQuery({
    queryKey: [LookupService.locations.queryKey],
    queryFn: LookupService.locations.request
  });

  const getLocationsByIds = (locationIds: string[]) => {
    if(!lookupUserGroupsQuery?.data) return;
    return lookupUserGroupsQuery?.data.filter(location => locationIds.includes(location.id));
  };

  const getLocationsAsString = (locationIds: string[]) => {
    return getLocationsByIds(locationIds ?? [])?.map(obj => obj.name).join(", ");
  };
  
  return {
    ...lookupUserGroupsQuery,
    getLocationsByIds,
    getLocationsAsString
  };
};