import { ApiURLs, httpClient } from "api/core";
import { PaginatedDataTable, LocationForCreateUpdateDto, LocationForReadDto, LocationForListDto } from "types";

const URL = ApiURLs.locations;

async function getLocations(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<LocationForListDto>>(`${URL}?${queryParams}`);
}

async function deleteLocation(locationId: string) {
  return await httpClient.delete<void>(`${URL}/${locationId}`);
}

async function createLocation(location: LocationForCreateUpdateDto) {
  return await httpClient.post<string>(`${URL}/create-location`, location);
}

async function getLocationDetails(locationId: string) {
  return await httpClient.get<LocationForReadDto>(`${URL}/${locationId}`);
}

async function updateLocation(locationId: string, location: LocationForCreateUpdateDto) {
  return await httpClient.put<LocationForCreateUpdateDto>(`${URL}/${locationId}`, location);
}

export const LocationService = {
  list: {
    request: getLocations,
    queryKey: "locationsList"
  },
  delete: {
    request: deleteLocation,
    queryKey: "deleteLocation"
  },
  create: {
    request: createLocation,
    queryKey: "createLocation"
  },
  update: {
    request: updateLocation,
    queryKey: "updateLocation"
  },
  details: {
    request: getLocationDetails,
    queryKey: "locationDetails"
  }
};