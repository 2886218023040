import { AppBar, Divider, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import { LayoutWrapper } from "layout";
import { Outlet } from "react-router-dom";
import { useBlockNavigation } from "hooks";
import { useStore } from "stores";
import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "components/error-boundary";

function FormLayout() {
  const { formLayoutStore } = useStore();
  const { confirmNavigate } = useBlockNavigation();
  
  useEffect(() => {
    return () => {
      formLayoutStore.resetStore();
    };
  }, [formLayoutStore]);
  
  return (
    <LayoutWrapper>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          boxShadow: "0 2px 16px 0 rgba(182, 182, 182, 0.15)",
          marginBottom: 20,
          paddingX: "3rem !important"
        }}
      >
        <Toolbar>
          <Typography sx={{
            ml: 2,
            flex: 1
          }} variant="h6" component="div">
            {formLayoutStore.header}
          </Typography>
          <IconButton
            onClick={() => confirmNavigate(-1)}
            edge="start"
            color="inherit"
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
        </Toolbar>
        <Divider className="line"/>
      </AppBar>
      <ErrorBoundary>
        <Outlet/>
      </ErrorBoundary>
    </LayoutWrapper>
  );
}

export default observer(FormLayout);