import { useMutation } from "@tanstack/react-query";
import { DoctorManagementService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, DoctorForCreateUpdateFormContext } from "types";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useUploadSignatureMutation } from "hooks";

const microsoftGraphAPIErrorMessage = "Property netId is invalid.";

export const useCreateDoctorMutation = () => {
  const [doctor, setDoctor] = useState<DoctorForCreateUpdateFormContext>();
  const { onUploadSignature } = useUploadSignatureMutation();
  const navigate = useNavigate();

  const createDoctorMutation = useMutation({
    mutationKey: [DoctorManagementService.create.queryKey],
    mutationFn: (doctor: DoctorForCreateUpdateFormContext) => DoctorManagementService.create.request(doctor),
    onSuccess: async (data) => {
      SnackbarUtils.success("Doctor created successfully!");
      navigate(-1);
      await onUploadSignature(data!.doctorId, doctor!);
    },
    onError: (error: ApiError) => {
      if(error.details?.GenericError?.[0].includes(microsoftGraphAPIErrorMessage)) {
        SnackbarUtils.error("Please use a different email, email is invalid");
      } else {
        ErrorsUtils.renderApiErrors(error);
      }
    }
  });

  const onCreateDoctor = (doctor: DoctorForCreateUpdateFormContext) => {
    if(!doctor) return;
    setDoctor(doctor);
    createDoctorMutation.mutate(doctor);
  };
  
  return {
    onCreateDoctor,
    ...createDoctorMutation
  };
};