import { useMutation } from "@tanstack/react-query";
import { AttorneyManagementService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import {
  ApiError, AttorneyForCreateUpdateDto
} from "types";
import { useNavigate } from "react-router-dom";
export const useCreateAttorneyMutation = () => {
  const navigate = useNavigate();
  const createAttorneysMutation = useMutation({
    mutationKey: [AttorneyManagementService.create.mutationKey],
    mutationFn: (attorney: AttorneyForCreateUpdateDto) => AttorneyManagementService.create.request(attorney),
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Attorneys created successfully!");

      navigate(-1);
    }
  });

  const onCreateAttorneys = (attorney: AttorneyForCreateUpdateDto) => {
    if(!attorney) return;
    createAttorneysMutation.mutate(attorney);
  };
  
  return {
    onCreateAttorneys,
    ...createAttorneysMutation
  };
};