export default class Permissions {
  static Appointments = class {
    static Actions = class {
      public static Create = "Permission:Appointments:Create";
      public static Update = "Permission:Appointments:Update";
      public static View = "Permission:Appointments:View";
      public static UploadDocument = "Permission:Appointments:UploadDocument";
      public static DownloadDocument = "Permission:Appointments:DownloadDocument";
      public static DeleteDocument = "Permission:Appointments:DeleteDocument";
      public static CompleteEvaluation = "Permission:Appointments:CompleteEvaluation";
      public static CompleteDeposition = "Permission:Appointments:CompleteDeposition";
      public static Cancel = "Permission:Appointments:Cancel";
      public static Reschedule = "Permission:Appointments:Reschedule";
      public static ArchiveReport = "Permission:Appointments:ArchiveReport";
      public static SetIsExpected = "Permission:Appointments:SetIsExpected";
      public static SetInterpreterInformation = "Permission:Appointments:SetInterpreterInformation";
      public static AccessToMedicalTemplates = "Permission:Appointments:AccessToMedicalTemplates";

      public static List = [
        this.Create,
        this.Update,
        this.View,
        this.UploadDocument,
        this.DownloadDocument,
        this.DeleteDocument,
        this.CompleteEvaluation,
        this.CompleteDeposition,
        this.Cancel,
        this.Reschedule,
        this.ArchiveReport,
        this.SetIsExpected,
        this.SetInterpreterInformation,
        this.AccessToMedicalTemplates
      ];
    };

    static Properties = {};

    static DisplayName = "Appointments";
  };

  static ActiveReports = class {
    static Actions = class {
      public static Create = "Permission:ActiveReports:Create";
      public static Update = "Permission:ActiveReports:Update";
      public static Delete = "Permission:ActiveReports:Delete";
      public static View = "Permission:ActiveReports:View";
      public static SignFinalFile = "Permission:ActiveReports:SignFinalFile";
      public static UnsignFinalFile = "Permission:ActiveReports:UnsignFinalFile";
      public static Archive = "Permission:ActiveReports:Archive";
      public static UploadDocument = "Permission:ActiveReports:UploadDocument";
      public static DownloadDocument = "Permission:ActiveReports:DownloadDocument";
      public static DeleteDocument = "Permission:ActiveReports:DeleteDocument";
      public static SetIsExpected = "Permission:ActiveReports:SetIsExpected";
      public static ApproveRejectQualityControl = "Permission:ActiveReports:ApproveRejectQualityControl";
      public static AccessToMedicalTemplates = "Permission:ActiveReports:AccessToMedicalTemplates";

      public static List = [
        this.Create,
        this.Update,
        this.Delete,
        this.View,
        this.SignFinalFile,
        this.UnsignFinalFile,
        this.Archive,
        this.UploadDocument,
        this.DownloadDocument,
        this.DeleteDocument,
        this.SetIsExpected,
        this.ApproveRejectQualityControl,
        this.AccessToMedicalTemplates
      ];
    };

    static Properties = {};

    static DisplayName = "Active Reports";
  };

  static ArchiveReportPermissions = class {
    static Actions = class {
      public static View = "Permission:ArchivedReport:View";
      public static DownloadDocument = "Permission:ArchivedReport:DownloadDocument";
      public static AccessToMedicalTemplates = "Permission:ArchivedReport:AccessToMedicalTemplates";
      public static List = [
        this.View,
        this.DownloadDocument,
        this.AccessToMedicalTemplates
      ];
    };

    static Properties = {};

    static DisplayName = "Archived Report";
  };

  static AvailabilitySlotsPermissions = class {
    static Actions = class {
      public static Create = "Permission:AvailabilitySlots:Create";
      public static Update = "Permission:AvailabilitySlots:Update";
      public static Delete = "Permission:AvailabilitySlots:Delete";
      public static View = "Permission:AvailabilitySlots:View";

      public static List: string[] = [
        this.Create,
        this.Update,
        this.Delete,
        this.View
      ];
    };
  };

  static DoctorManagement = class {
    static Actions = class {
      public static Create = "Permission:DoctorManagement:Create";
      public static Update = "Permission:DoctorManagement:Update";
      public static Delete = "Permission:DoctorManagement:Delete";
      public static View = "Permission:DoctorManagement:View";
      public static EnableDisable = "Permission:DoctorManagement:EnableDisable";
      public static UploadDocument = "Permission:DoctorManagement:UploadDocument";
      public static DownloadDocument = "Permission:DoctorManagement:DownloadDocument";
      public static DeleteDocument = "Permission:DoctorManagement:DeleteDocument";

      public static List = [
        this.Create,
        this.Update,
        this.View,
        this.Delete,
        this.EnableDisable,
        this.UploadDocument,
        this.DownloadDocument,
        this.DeleteDocument
      ];
    };

    static Properties = {};

    static DisplayName = "Doctor Management";
  };

  static LocationsManagement = class {
    static Actions = class {
      public static Create = "Permission:LocationsManagement:Create";
      public static Update = "Permission:LocationsManagement:Update";
      public static Delete = "Permission:LocationsManagement:Delete";
      public static View = "Permission:LocationsManagement:View";

      public static List = [this.Create, this.Update, this.Delete, this.View];
    };

    static Properties = {};

    static DisplayName = "Locations Management";
  };

  static PatientFile = class {
    static Actions = class {
      public static Create = "Permission:PatientFiles:Create";
      public static Update = "Permission:PatientFiles:Update";
      public static Archive = "Permission:PatientFiles:Archive";
      public static View = "Permission:PatientFiles:View";
      public static UploadDocument = "Permission:PatientFiles:UploadDocument";
      public static ViewDocument = "Permission:PatientFiles:ViewDocument";
      public static DownloadDocument = "Permission:PatientFiles:DownloadDocument";
      public static DeleteDocument = "Permission:PatientFiles:DeleteDocument";

      public static List = [
        this.Create,
        this.Update,
        this.Archive,
        this.View,
        this.UploadDocument,
        this.ViewDocument,
        this.DownloadDocument,
        this.DeleteDocument
      ];
    };

    static Properties = class {
      public static DwcDocumentKey = "Permission:DwcDocumentKey:View";

      public static List = [this.DwcDocumentKey];
    };

    static DisplayName = "Patient Files";
  };

  static RoleManagement = class {
    static Actions = class {
      public static Create = "Permission:RoleManagement:Create";
      public static Update = "Permission:RoleManagement:Update";
      public static View = "Permission:RoleManagement:View";

      public static List = [this.Create, this.Update, this.View];
    };

    static Properties = {};

    static DisplayName = "Role Management";
  };

  static Specialities = class {
    static Actions = class {
      public static Create = "Permission:Specialities:Create";
      public static Update = "Permission:Specialities:Update";
      public static Delete = "Permission:Specialities:Delete";
      public static View = "Permission:Specialities:View";

      public static List: string[] = [
        this.Create,
        this.Update,
        this.Delete,
        this.View
      ];
    };

    static Properties = class {};
  };

  static UserManagement = class {
    static Actions = class {
      public static Create = "Permission:UserManagement:Create";
      public static Update = "Permission:UserManagement:Update";
      public static Delete = "Permission:UserManagement:Delete";
      public static View = "Permission:UserManagement:View";
      public static EnableDisable = "Permission:UserManagement:EnableDisable";
      public static MoveToDoctor = "Permission:UserManagement:MoveToDoctor";

      public static List = [
        this.Create,
        this.Update,
        this.View,
        this.Delete,
        this.EnableDisable,
        this.MoveToDoctor
      ];
    };

    static Properties = {};

    static DisplayName = "User Management";
  };

  static UsersGroups = class {
    static Actions = class {
      public static Create = "Permission:UsersGroups:Create";
      public static Update = "Permission:UsersGroups:Update";
      public static Delete = "Permission:UsersGroups:Delete";
      public static View = "Permission:UsersGroups:View";

      public static List: string[] = [
        this.Create,
        this.Update,
        this.Delete,
        this.View
      ];
    };

    static Properties = class {};
  };

  public static AdjustersManagement = class {
    public static Actions = class {
      public static Create: string = "Permission:InsuranceCompanyManagement:Create";
      public static Update: string = "Permission:InsuranceCompanyManagement:Update";
      public static Delete: string = "Permission:InsuranceCompanyManagement:Delete";
      public static View: string = "Permission:InsuranceCompanyManagement:View";

      public static List: string[] = [
        this.Create,
        this.Update,
        this.Delete,
        this.View
      ];
    };

    public static Properties = class {};

    public static DisplayName: string = "Insurance Company Management";
  };

  public static AttorneyManagement = class {
    public static Actions = class {
      public static Create: string = "Permission:AttorneyManagement:Create";
      public static Update: string = "Permission:AttorneyManagement:Update";
      public static Delete: string = "Permission:AttorneyManagement:Delete";
      public static View: string = "Permission:AttorneyManagement:View";

      public static List: string[] = [
        this.Create,
        this.Update,
        this.Delete,
        this.View
      ];
    };

    public static Properties = class {};

    public static DisplayName: string = "Attorney Management";
  };

  public static BillingManagement = class {
    public static Actions = class {
      public static Create: string = "Permission:BillingManagement:Create";
      public static Update: string = "Permission:BillingManagement:Update";
      public static Delete: string = "Permission:BillingManagement:Delete";
      public static View: string = "Permission:BillingManagement:View";
      public static AddBillingDetails: string = "Permission:BillingManagement:AddBillingDetails";
      public static SetPaymentDepositDate: string = "Permission:BillingManagement:SetPaymentDepositDate";
      public static SetAppointmentDepositDate: string = "Permission:BillingManagement:SetAppointmentDepositDate";
      public static SetPaidDate: string = "Permission:BillingManagement:SetPayDate";
      public static OverridePaidDate: string = "Permission:BillingManagement:OverridePaidDate";
      public static GeneratePayroll: string = "Permission:BillingManagement:GeneratePayroll";
      public static BillerView: string = "Permission:BillingManagement:BillerView";
      public static FinanceView: string = "Permission:BillingManagement:FinanceView";
      public static DoctorView: string = "Permission:BillingManagement:DoctorView";
      public static SetExpectedAmount: string = "Permission:BillingManagement:SetExpectedAmount";

      public static List: string[] = [
        this.Create,
        this.Update,
        this.Delete,
        this.View,
        this.AddBillingDetails,
        this.SetAppointmentDepositDate,
        this.SetPaymentDepositDate,
        this.AddBillingDetails,
        this.SetPaidDate,
        this.GeneratePayroll,
        this.OverridePaidDate,
        this.BillerView,
        this.FinanceView,
        this.DoctorView,
        this.SetExpectedAmount
      ];
    };

    public static DisplayName: string = "Finance and Billing";
  };

  public static DuplicatePaymentManagement = class {
    public static Actions = class {
      public static Create = "Permission:DuplicatePaymentManagement:Create";
      public static Update = "Permission:DuplicatePaymentManagement:Update";
      public static Delete = "Permission:DuplicatePaymentManagement:Delete";
      public static View = "Permission:DuplicatePaymentManagement:View";
      public static PayBack = "Permission:DuplicatePaymentManagement:PayBack";

      public static List = [
        this.Create,
        this.Update,
        this.Delete,
        this.View,
        this.PayBack
      ];

      public static DisplayName = "Duplicate Payment Management";
    };
  };
}
