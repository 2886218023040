import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ActiveReportService } from "api";
import {
  ApiError,
  EQualityApprovalStatus,
  QualityApprovalInformation
} from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useParams } from "react-router-dom";

export const useSetQualityApprovalMutation = () => {
  const queryClient = useQueryClient();
  const { activeReportId } = useParams<{ activeReportId: string }>();
  const setFinalReportUrlMutation = useMutation({
    mutationKey: [ActiveReportService.qualityApproval.queryKey],
    mutationFn: (qualityApprovalInformation: QualityApprovalInformation) =>
      ActiveReportService.qualityApproval.request(
        activeReportId!,
        qualityApprovalInformation
      ),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onSetQualityApprovalAsApproved = async () => {
    return await setFinalReportUrlMutation
      .mutateAsync({
        status: EQualityApprovalStatus.Approved
      })
      .then(() => {
        SnackbarUtils.success("Final report has been approved successfully");
        queryClient.invalidateQueries({
          queryKey: [ActiveReportService.profile.queryKey, activeReportId!]
        });
      });
  };
  const onSetQualityApprovalAsRejected = async (note?: string) => {
    return await setFinalReportUrlMutation
      .mutateAsync({
        status: EQualityApprovalStatus.Rejected,
        note
      })
      .then(() => {
        SnackbarUtils.success("Final report has been rejected successfully");
        queryClient.invalidateQueries({
          queryKey: [ActiveReportService.profile.queryKey, activeReportId!]
        });
      });
  };

  return {
    ...setFinalReportUrlMutation,
    onSetQualityApprovalAsApproved,
    onSetQualityApprovalAsRejected
  };
};
