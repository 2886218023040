import { AuthService } from "api";
import { queryClient } from "layout";
import { store } from "stores";

export const useLogout = () => {

  const onLogoutHandler = async () => {
    try {
      await AuthService.logout.request();
      queryClient.clear();
      store.userPermissionsStore.clear();
    } catch(e) {
      console.error(e);
    }
  };
  return {
    onLogoutHandler
  };
};