import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";
import { store } from "stores";
import React from "react";
import { AdjustersManagementService } from "api";

export const useDeleteAdjustersMutation = () => {
  const queryClient = useQueryClient();
  const deleteAdjustersMutation = useMutation({
    mutationKey: [AdjustersManagementService.delete.mutationKey],
    mutationFn: (adjusterId: string) =>
      AdjustersManagementService.delete.request(adjusterId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [AdjustersManagementService.list.queryKey]
      });
      SnackbarUtils.success("Adjusters deleted successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onDeleteAdjusters = async (adjusterId: string) => {
    return await deleteAdjustersMutation.mutateAsync(adjusterId);
  };

  const deleteAdjustersHandler = (
    adjusterId: string,
    adjusterFullName: string,
    callback?: Function
  ) => {
    store.confirmModalStore.open({
      title: "Confirm delete adjuster",
      body: (
        <p>
          Are you sure you want to delete <strong>{adjusterFullName}</strong> adjuster?
        </p>
      ),
      options: {
        onConfirm: async () => {
          store.confirmModalStore.setIsLoading(true);
          try {
            await onDeleteAdjusters(adjusterId);
            if (callback) await callback();
          } catch (e) {
            console.error(e);
          }
        }
      },
      buttonLabel: "Delete"
    });
  };

  return {
    ...deleteAdjustersMutation,
    onDeleteAdjusters,
    deleteAdjustersHandler
  };
};
