import moment from "moment/moment";

const TIME = "hh:mm A";
const FULL_TIME = "HH:mm:ss";
const DATE = "YYYY-MM-DD";
const FULL_DATE_FORMAT = "dddd, Do [of] MMMM";

const MIN_TIME = moment("08:00 AM", TIME);
const MAX_TIME = moment("06:00 PM", TIME);

const DATE_TIME_FORMAT = Object.freeze({
  TIME,
  MIN_TIME,
  MAX_TIME,
  DATE,
  FULL_TIME,
  FULL_DATE_FORMAT
});

export default DATE_TIME_FORMAT;