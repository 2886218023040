import { AppointmentService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";
import { AppointmentForListDto, AppointmentTabQueryParams, AppointmentTabs } from "types";
import { useEffect, useState } from "react";

export const useAppointmentsListQuery = () => {
  const defaultSearchParams = new URLSearchParams();
  defaultSearchParams.set(AppointmentTabQueryParams.Tab, AppointmentTabs.List);

  const [currentTabValue, setCurrentTabValue] = useState(AppointmentTabs.List);

  const appointmentsListQuery = usePaginatedDataTableQuery<AppointmentForListDto>({
    queryFn: AppointmentService.list.request,
    queryKey: AppointmentService.list.queryKey,
    defaultSearchParams
  });

  const { setSearchParams, searchParams } = appointmentsListQuery;

  const updateUrlTab = (tab: AppointmentTabs) => {
    searchParams.set(AppointmentTabQueryParams.Tab, tab);
    setSearchParams(appointmentsListQuery.searchParams);
  };
  
  const onFetchData = async () => {
    await appointmentsListQuery.refetch();
  };

  useEffect(() => {
    const defaultTab = searchParams.get(AppointmentTabQueryParams.Tab) as AppointmentTabs || AppointmentTabs.List;
    setCurrentTabValue(defaultTab);
  }, [searchParams.get(AppointmentTabQueryParams.Tab)]);
  
  return {
    ...appointmentsListQuery,
    onFetchData,
    currentTabValue,
    setCurrentTabValue,
    updateUrlTab
  };
};