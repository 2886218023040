import { ActiveReportService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";
import { ActiveReportForListDto } from "types";

export const useActiveReportsListQuery = () => {
  const activeReportsListQuery = usePaginatedDataTableQuery<ActiveReportForListDto>({
    queryFn: ActiveReportService.list.request,
    queryKey: ActiveReportService.list.queryKey
  });
  
  const onFetchData = async () => {
    await activeReportsListQuery.refetch();
  };
  
  return {
    onFetchData,
    ...activeReportsListQuery
  };
};