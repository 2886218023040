import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { GridProps } from "@mui/material/Grid/Grid";

type SkeletonCardItemProps = {
  cardGridProps?: GridProps;
}
function SkeletonCardItem({ cardGridProps }: SkeletonCardItemProps) {
  return (
    <Grid xs={12} md={4} {...cardGridProps} item>
      <Stack direction="column" alignItems="start" justifyContent="center">
        <Typography mb="5px" className="card-item-label" variant="body2" component="label">
          <Skeleton width={200} height={21} />
        </Typography>
        <Typography mb="5px" className="card-item-description" variant="body2" component="label">
          <Skeleton width={100} height={24} />
        </Typography>
      </Stack>
    </Grid>
  );
}

export default SkeletonCardItem;
