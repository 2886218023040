import { makeAutoObservable } from "mobx";
import { RoleWithClaimsForReadDto } from "types";

export default class UserPermissionsStore {
  constructor() {
    makeAutoObservable(this);
  }

  setPermissions = (permissions: RoleWithClaimsForReadDto[]) => {
    sessionStorage.setItem("permissions", JSON.stringify(permissions));
  };

  clear = () => {
    sessionStorage.removeItem("permissions");
  };

  get permissions() {
    const permissions = sessionStorage.getItem("permissions");

    if(!permissions) return [];

    return JSON.parse(permissions) as RoleWithClaimsForReadDto[];
  };
}