import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "stores";
import { Path } from "@remix-run/router/history";

export const useBlockNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "Are you sure you want to leave this page?";
    };
    
    window.addEventListener("beforeunload", beforeUnload);
    
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
    
  }, [navigate, location]);
  
  const confirmNavigate = (to: string | Partial<Path> | number) => {
    store.confirmModalStore.open({
      title: "Navigate site?",
      body: "Changes you made may not be saved.",
      options: {
        onConfirm: () => {
          navigate(to as any);
        }
      }
    });
  };
  return {
    confirmNavigate
  };
};