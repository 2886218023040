import "./GeneralScreenTemplate.scss";

import { Box, Stack } from "@mui/material";
import { PrimeButton } from "components/common";

type GeneralScreenTemplateProps = {
  image: string;
  title: string;
  description: string;
  button?: {
    click: () => void;
    text: string
  }
}

function GeneralScreenTemplate({
  title,
  description,
  image,
  button
}: GeneralScreenTemplateProps) {
  return (
    <Stack alignItems="center" justifyContent="center" spacing={2} sx={{ height: "80vh" }} direction="column">
      <Box>
        <img className="template-image" src={image} alt="template"/>
      </Box>
      <Box>
        <Stack alignItems="center" spacing={2} mt={0}>
          <span className="template-title">{title}</span>
          <span className="template-description">{description}</span>
        </Stack>
      </Box>
      {button && (
        <Box>
          <PrimeButton onClick={button.click} color="secondary">
            {button.text}
          </PrimeButton>
        </Box>
      )}
    </Stack>
  );
}

export default GeneralScreenTemplate;