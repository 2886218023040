function DoctorsIcon() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Doctors icon</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Left-Side-Nav-/-drop-down" transform="translate(-43, -81)">
          <g id="Group-3" transform="translate(0, 71)">
            <g id="Doctors-icon" transform="translate(41, 8)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <g className="sidebar-icon" id="stethoscope_FILL1_wght400_GRAD0_opsz48" transform="translate(2, 2)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M11.5,20 C9.63333333,20 8.08333333,19.3450146 6.85,18.0350438 C5.61666667,16.725073 5,15.1606174 5,13.3416771 L5,12.465582 C3.58333333,12.2820192 2.39583333,11.6520651 1.4375,10.5757196 C0.479166667,9.49937422 0,8.22695035 0,6.75844806 L0,1.00125156 L3,1.00125156 L3,0 L4.5,0 L4.5,3.50438048 L3,3.50438048 L3,2.50312891 L1.5,2.50312891 L1.5,6.75844806 C1.5,7.94326241 1.9125,8.94868586 2.7375,9.7747184 C3.5625,10.6007509 4.56666667,11.0137672 5.75,11.0137672 C6.93333333,11.0137672 7.9375,10.6007509 8.7625,9.7747184 C9.5875,8.94868586 10,7.94326241 10,6.75844806 L10,2.50312891 L8.5,2.50312891 L8.5,3.50438048 L7,3.50438048 L7,0 L8.5,0 L8.5,1.00125156 L11.5,1.00125156 L11.5,6.75844806 C11.5,8.22695035 11.0208333,9.49937422 10.0625,10.5757196 C9.10416667,11.6520651 7.91666667,12.2820192 6.5,12.465582 L6.5,13.3416771 C6.5,14.7601168 6.97083333,15.9741343 7.9125,16.9837297 C8.85416667,17.993325 10.05,18.4981227 11.5,18.4981227 C12.85,18.4981227 14.0208333,17.9974969 15.0125,16.9962453 C16.0041667,15.9949937 16.5,14.7768043 16.5,13.3416771 L16.5,11.4142678 C15.9166667,11.2473926 15.4375,10.9219858 15.0625,10.4380476 C14.6875,9.9541093 14.5,9.39507718 14.5,8.76095119 C14.5,7.99332499 14.7666667,7.34251147 15.3,6.80851064 C15.8333333,6.2745098 16.4833333,6.00750939 17.25,6.00750939 C18.0166667,6.00750939 18.6666667,6.2745098 19.2,6.80851064 C19.7333333,7.34251147 20,7.99332499 20,8.76095119 C20,9.39507718 19.8125,9.9541093 19.4375,10.4380476 C19.0625,10.9219858 18.5833333,11.2473926 18,11.4142678 L18,13.3416771 C18,15.1939925 17.3625,16.7667918 16.0875,18.0600751 C14.8125,19.3533584 13.2833333,20 11.5,20 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DoctorsIcon;