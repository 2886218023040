import { useForm } from "react-hook-form";
import { EditFileStatusModalFormContext, FileStatus } from "types";
import { useCallback, useState } from "react";
import { useIsLoadingMutation } from "hooks/utils";
import { DocumentsService } from "api";

export const useEditFileStatus = () => {
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const formContext = useForm<EditFileStatusModalFormContext>({
    defaultValues: {
      fileStatus: FileStatus.Pending
    }
  });
  const { isLoading } = useIsLoadingMutation(DocumentsService.expected.queryKey);

  const onOpenEditFileStatus = useCallback((selectedFolderId: string) => {
    setOpen(true);
    setSelectedFolderId(selectedFolderId);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
    formContext.reset();
  }, [formContext]);

  return {
    open,
    onCloseEditFileStatus: closeHandler,
    formContext,
    isLoading,
    onOpenEditFileStatus,
    selectedFolderId
  };
};
