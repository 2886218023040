import React from "react";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { DialogProps } from "@mui/material/Dialog/Dialog";

type PrimeModalProps = DialogProps & {
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}
function PrimeModal(props: PrimeModalProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { onClose, open, ...rest } = props;
  
  const handleClose = () => {
    onClose();
  };
  
  return (
    <Dialog
      fullScreen={fullScreen}
      aria-labelledby="responsive-dialog-title"
      onClose={handleClose}
      open={open}
      fullWidth
      sx={{ zIndex: 1298 }}
      {...rest}
    >
      {props.children}
    </Dialog>
  );
}

export default PrimeModal;