import { useMutation } from "@tanstack/react-query";
import { UsersManagementService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, UserForCreateUpdateDto } from "types";
import { useNavigate, useParams } from "react-router-dom";

type MutationFnProps = {
  user: UserForCreateUpdateDto;
  userId: string;
}
export const useUpdateUserMutation = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  
  const updateUserMutation = useMutation({
    mutationKey: [UsersManagementService.update.queryKey],
    mutationFn: ({ user, userId }: MutationFnProps) => UsersManagementService.update.request(user, userId),
    onSuccess() {
      SnackbarUtils.success("User updated successfully!");
      navigate(-1);
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onUpdateUser = (user: UserForCreateUpdateDto) => {
    if(!user || !userId) return;
    updateUserMutation.mutate({
      user,
      userId
    });
  };
  
  return {
    onUpdateUser,
    ...updateUserMutation
  };
};