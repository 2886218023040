import { makeAutoObservable } from "mobx";

export default class CommonStore {
  isOpenNavbar = true;
  constructor() {
    makeAutoObservable(this);
  }
  
  toggleNavbar = () => {
    this.isOpenNavbar = !this.isOpenNavbar;
  };
}