import { useMutation } from "@tanstack/react-query";
import { ActiveReportService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, ActiveReportForCreateUpdateDto } from "types";
import { useNavigate, useParams } from "react-router-dom";

type MutationFnProps = {
  activeReport: ActiveReportForCreateUpdateDto;
  activeReportId: string;
}
export const useUpdateActiveReportMutation = () => {
  const { activeReportId } = useParams<{ activeReportId: string }>();
  const navigate = useNavigate();

  const updateActiveReportMutation = useMutation({
    mutationKey: [ActiveReportService.update.queryKey],
    mutationFn: ({ activeReport, activeReportId }: MutationFnProps) => ActiveReportService.update.request(activeReportId, activeReport),
    onSuccess() {
      SnackbarUtils.success("Active report updated successfully!");
      navigate(-1);
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onUpdateActiveReport = (activeReport: ActiveReportForCreateUpdateDto) => {
    if(!activeReport || !activeReportId) return;
    updateActiveReportMutation.mutate({
      activeReport,
      activeReportId
    });
  };

  return {
    onUpdateActiveReport,
    ...updateActiveReportMutation
  };
};