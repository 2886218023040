import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from "react";
import type { AppointmentPaymentDistributionLookupListItemDto, DuplicatePaymentTransactionForRead } from "types";

type DistributeOnAppointmentsProps = {
  paymentTransactions: Map<string, AppointmentPaymentDistributionLookupListItemDto>;
  onDeleteAppointment: (id: string) => void;
  onAddAppointments: (
    appointments: Map<string, AppointmentPaymentDistributionLookupListItemDto>
  ) => void;
  onDeleteAllAppointments: () => void;
  getAppointmentsArray: () => AppointmentPaymentDistributionLookupListItemDto[];
};

const DistributeOnAppointmentsContext =
  createContext<DistributeOnAppointmentsProps>({
    paymentTransactions: new Map(),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDeleteAppointment: id => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onAddAppointments: appointments => {},
    onDeleteAllAppointments: () => {},
    getAppointmentsArray: () => []
  });

type DistributeOnAppointmentsContextProviderProps = PropsWithChildren<{
  defaultTransactions?: DuplicatePaymentTransactionForRead[]
}>;
export function DistributeOnAppointmentsContextProvider({
  children,
  defaultTransactions
}: DistributeOnAppointmentsContextProviderProps) {
  const [paymentTransactions, setPaymentTransactions] = useState<Map<string, AppointmentPaymentDistributionLookupListItemDto>>(new Map());

  useEffect(() => {
    if (defaultTransactions) {
      const items = new Map<string, AppointmentPaymentDistributionLookupListItemDto>();
      defaultTransactions.forEach(transaction => {
        items.set(transaction.appointmentFinanceId!, {
          id: transaction.appointmentFinanceId!,
          doctorFullName: transaction.appointmentFinance.doctorName,
          patientFullName: transaction.appointmentFinance.patientName,
          dateOfService: transaction.appointmentFinance.dateOfService ?? "",
          type: transaction.appointmentFinance.appointmentType,
          expectedAmount: transaction.paidAmount
        });
      });
      setPaymentTransactions(items);
    }
  }, [defaultTransactions]);

  const onDeleteAppointment = (id: string) => {
    const items = new Map(paymentTransactions);
    items.delete(id);
    setPaymentTransactions(items);
  };

  const onAddAppointments = (
    appointments: Map<string, AppointmentPaymentDistributionLookupListItemDto>
  ) => {
    const items = new Map(appointments);
    setPaymentTransactions(items);
  };

  const onDeleteAllAppointments = () => {
    setPaymentTransactions(new Map());
  };

  const getAppointmentsArray = (): AppointmentPaymentDistributionLookupListItemDto[] => {
    return Array.from(paymentTransactions.values());
  };

  return (
    <DistributeOnAppointmentsContext.Provider
      value={{
        paymentTransactions,
        onDeleteAppointment,
        onAddAppointments,
        onDeleteAllAppointments,
        getAppointmentsArray
      }}
    >
      {children}
    </DistributeOnAppointmentsContext.Provider>
  );
}

export const useDistributeOnAppointmentsContext = () =>
  useContext(DistributeOnAppointmentsContext);
