import { Option } from "types";

const APPOINTMENT_NOTICE: Option[] = [
  {
    id: "Insurance Company",
    label: "Insurance Company"
  },
  {
    id: "Applicant Attorney",
    label: "Applicant Attorney"
  },
  {
    id: "Defendant Attorney",
    label: "Defendant Attorney"
  },
  {
    id: "Patient",
    label: "Patient"
  }
];

export default APPOINTMENT_NOTICE;