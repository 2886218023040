import { styled } from "@mui/material/styles";
import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import { MRT_TableInstance } from "material-react-table";
const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border: none;
    background-color: transparent;
    &:hover .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: none;
    } 
  }
`;

type SearchField<TData extends Record<string, any> = {}> = {
  table: MRT_TableInstance<TData>;
};
function SearchField<T extends Record<string, any> = {}>({ table }: SearchField<T>) {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");

  const onSearchHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchValue(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      table.setGlobalFilter(value);
    }, 500);

    setTimeoutId(newTimeoutId);
  }, [table, timeoutId]);

  useEffect(() => {
    setSearchValue(table.getState().globalFilter);
  }, [table.getState().globalFilter]);

  return (
    <StyledTextField
      variant="outlined"
      sx={{
        "& fieldset": { border: "none" }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
      value={searchValue}
      placeholder="Search here"
      fullWidth
      onChange={onSearchHandler}
    />
  );
}

export default SearchField;