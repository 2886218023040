import { AvailabilityExceptionType } from "types";

export class WeekDaysSlot {
  days = [];
  months = [];
  exceptions: WeekDaysExceptionSlot[] = [];

  timeRange = new TimeRange();
}

export class WeekDaysExceptionSlot {
  days: string[] = [];
  months: string[] = [];
  exceptionType: AvailabilityExceptionType = "Available";
  timeRange = new TimeRange();
}

export class SpecificDaysSlot {
  date = null;
  timeRange = new TimeRange();
}

export class DateRangeSlot {
  dateRange = new DateRange();
  availableSaturday = false;
  availableSunday = false;
  exceptions: DateRangeExceptionSlot[] = [];

  timeRange = new TimeRange();
}

export class DateRangeExceptionSlot {
  exceptionType: AvailabilityExceptionType = "Available";
  dateRange = new DateRange();
  timeRange = new TimeRange();
}

class TimeRange {
  startTime = null;
  endTime = null;
}

class DateRange {
  endDate: string | null = null;
  startDate: string | null = null;
}