import { httpClient } from "api/core";
import { AxiosRequestConfig } from "axios";
import {
  AbortMultipartUploadDto,
  CompleteMultipartUploadDto,
  DocumentForMultiPartUploadResponseDto,
  MultipartUploadDetailsPayload,
  UploadMultiPartFilePayload
} from "types";
async function getFolders<T>(URL: string, entityId: string) {
  return await httpClient.get<T>(`${URL}/${entityId}/folders`);
}

async function getDocument(
  URL: string,
  entityId: string,
  folderId: string,
  documentId: string,
  params?: AxiosRequestConfig["params"]
) {
  return await httpClient.get<Blob>(
    `${URL}/${entityId}/folders/${folderId}/documents/${documentId}`,
    {
      params,
      responseType: "blob"
    }
  );
}

async function getDocumentURL(
  URL: string,
  entityId: string,
  folderId: string,
  documentId: string,
  params?: AxiosRequestConfig["params"]
) {
  return await httpClient.get<string>(
    `${URL}/${entityId}/folders/${folderId}/documents/${documentId}/access-medical-template`,
    {
      params
    }
  );
}

async function deleteDocument(
  URL: string,
  entityId: string,
  folderId: string,
  documentId: string
) {
  return await httpClient.delete<void>(
    `${URL}/${entityId}/folders/${folderId}/documents/${documentId}`
  );
}

function getMultipartUploadDetails(
  payload: MultipartUploadDetailsPayload
): Promise<DocumentForMultiPartUploadResponseDto> {
  return httpClient.post<DocumentForMultiPartUploadResponseDto>(
    `${payload.URL}/${payload.entityId}/folders/${payload.folderId}/initiate-upload`,
    {
      documents: payload.documents
    }
  );
}

async function uploadDocuments(
  URL: string,
  entityId: string,
  folderId: string,
  files: File[]
) {
  await uploadSingleFile(URL, entityId, folderId, files);
}

async function uploadSingleFile(
  URL: string,
  entityId: string,
  folderId: string,
  files: File[]
) {
  const formData = new FormData();
  files.forEach(file => {
    formData.append("attachments", file, file.name);
  });
  return await httpClient.post<void>(
    `${URL}/${entityId}/folders/${folderId}/documents`,
    formData
  );
}

async function uploadMultiPartFile(payload: UploadMultiPartFilePayload) {
  const formData = new FormData();
  formData.append("chunkSize", payload.chunkSize.toString());
  formData.append("multipartSessionUploadId", payload.multipartSessionUploadId);
  formData.append("draftDocumentId", payload.draftDocumentId);
  formData.append("fileName", payload.fileName);
  formData.append("isLastPart", payload.isLastPart.toString());
  formData.append("partNumber", payload.partNumber.toString());
  formData.append("file", payload.part);
  return await httpClient.post<void>(
    `${payload.URL}/${payload.entityId}/folders/${payload.folderId}/documents-multipart`,
    formData
  );
}

async function abortMultipartUpload(
  URL: string,
  entityId: string,
  folderId: string,
  abort: AbortMultipartUploadDto
) {
  return await httpClient.put(
    `${URL}/${entityId}/folders/${folderId}/documents-multipart-abort`,
    abort
  );
}

function completeMultiPartUpload(
  URL: string,
  entityId: string,
  folderId: string,
  data: CompleteMultipartUploadDto
) {
  return httpClient.put(
    `${URL}/${entityId}/folders/${folderId}/documents-multipart-complete`,
    data
  );
}

async function setIsExpected(
  URL: string,
  entityId: string,
  folderId: string,
  expected: boolean
) {
  return await httpClient.put(
    `${URL}/${entityId}/folders/${folderId}/set-is-expected`,
    {
      isExpected: expected
    }
  );
}

export const DocumentsService = {
  download: {
    request: getDocument,
    queryKey: "getDocument"
  },
  delete: {
    request: deleteDocument,
    queryKey: "deleteDocument"
  },
  folders: {
    request: getFolders,
    queryKey: "getFolders"
  },
  uploadFiles: {
    request: uploadDocuments,
    queryKey: "uploadFiles"
  },
  expected: {
    request: setIsExpected,
    queryKey: "setIsExpected"
  },
  documentUrl: {
    request: getDocumentURL,
    queryKey: "getDocumentURL"
  },
  abortMultipartUpload: {
    request: abortMultipartUpload,
    queryKey: "abortMultipartUpload"
  },
  uploadMultiPartFile: {
    request: uploadMultiPartFile,
    queryKey: "uploadMultiPartFile"
  },
  multipartUploadDetails: {
    request: getMultipartUploadDetails,
    queryKey: "getMultipartUploadDetails"
  },
  completeMultiPartUpload: {
    request: completeMultiPartUpload,
    queryKey: "completeMultiPartUpload"
  }
};
