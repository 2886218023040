import { ApiURLs, httpClient } from "api/core";
import {
  AppointmentForCalendarDto,
  AppointmentForCreateUpdateDto,
  AppointmentForListDto,
  AppointmentForReadDto,
  AppointmentForRescheduleRequestDto,
  CancelCreateFormContext,
  InterpreterInformation,
  PaginatedDataTable
} from "types";
import { AppointmentUtils } from "utils";

const URL = ApiURLs.appointmentManagement;
async function createAppointment(appointment: AppointmentForCreateUpdateDto) {
  return await httpClient.post<void>(`${URL}`, appointment);
}
async function getAppointments(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<AppointmentForListDto>>(
    `${URL}?${queryParams}`
  );
}

async function completeDepositionOrEvaluation(appointmentId: string) {
  return await httpClient.put<void>(
    `${URL}/${appointmentId}/complete-evaluation`,
    {}
  );
}

async function cancelAppointment(
  appointmentId: string,
  cancelledAppointment: CancelCreateFormContext
) {
  return await httpClient.put<void>(
    `${URL}/${appointmentId}/cancel-appointment`,
    cancelledAppointment
  );
}

async function rescheduleAppointment(
  appointmentId: string,
  appointmentForReschedule: AppointmentForRescheduleRequestDto
) {
  return await httpClient.put<void>(
    `${URL}/${appointmentId}/reschedule-appointment`,
    appointmentForReschedule
  );
}

async function setInterpreterInformation(
  appointmentId: string,
  interpreterInformation: InterpreterInformation
) {
  return await httpClient.put<void>(
    `${URL}/${appointmentId}/set-interpreter-information`,
    interpreterInformation
  );
}

async function getAppointmentsCalendar(currentDate: string) {
  const { startOfDay, endOfDay } =
    AppointmentUtils.startAndEndOfMonth(currentDate);
  return await httpClient.get<AppointmentForCalendarDto[]>(`${URL}/calendar`, {
    params: {
      startDateFilter: startOfDay,
      endDateFilter: endOfDay
    }
  });
}
async function getAppointmentDetails(appointmentId: string) {
  return await httpClient.get<AppointmentForReadDto>(`${URL}/${appointmentId}`);
}

async function updateAppointment(
  appointment: AppointmentForCreateUpdateDto,
  appointmentId: string
) {
  return await httpClient.put<void>(`${URL}/${appointmentId}`, appointment);
}

export const AppointmentService = {
  create: {
    request: createAppointment,
    queryKey: "createAppointment"
  },
  list: {
    request: getAppointments,
    queryKey: "getAppointments"
  },
  calendar: {
    request: getAppointmentsCalendar,
    queryKey: "getAppointmentsCalendar"
  },
  details: {
    request: getAppointmentDetails,
    queryKey: "getAppointmentDetails"
  },
  update: {
    request: updateAppointment,
    queryKey: "updateAppointment"
  },
  complete: {
    request: completeDepositionOrEvaluation,
    queryKey: "completeDepositionOrEvaluation"
  },
  cancel: {
    request: cancelAppointment,
    queryKey: "cancelAppointment"
  },
  reschedule: {
    request: rescheduleAppointment,
    queryKey: "rescheduleAppointment"
  },
  setInterpreterInformation: {
    request: setInterpreterInformation,
    queryKey: "setInterpreterInformation"
  }
};
