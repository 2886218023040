import React from "react";
import { Box, Stack } from "@mui/material";
import { Density, Filter, FullScreen, SearchField, ViewColumn } from "components/common/data-table/toolbar";
import { MRT_TableInstance } from "material-react-table";

type TopToolbarProps<TData extends Record<string, any> = {}> = {
  table: MRT_TableInstance<TData>;
};

function TopToolbar<T extends Record<string, any> = {}>({ table }: TopToolbarProps<T>) {
  return (
    <Stack
      sx={{
        backgroundColor: "#FBFBFB",
        borderTopRightRadius: "7px",
        borderTopLeftRadius: "7px"
      }}
      p={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <SearchField table={table} />
      </Box>
      <Stack direction="row">
        <Filter table={table}/>
        <FullScreen table={table}/>
        <Density table={table}/>
        <ViewColumn table={table}/>
      </Stack>
    </Stack>
  );
}

export default TopToolbar;