import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ArchivedReportService } from "api";

export const useArchivedReportProfileQuery = (autoFetch = true) => {
  const { archivedReportId } = useParams<{ archivedReportId: string }>();
  const archivedReportProfileQuery = useQuery({
    queryKey: [ArchivedReportService.profile.queryKey, archivedReportId],
    queryFn: () => ArchivedReportService.profile.request(archivedReportId!),
    enabled: autoFetch
  });

  const onFetchActiveReportProfile = () => {
    return archivedReportProfileQuery.refetch();
  };

  return {
    ...archivedReportProfileQuery,
    onFetchActiveReportProfile
  };
};