import { BillingManagementService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";
import { PaymentForListDto } from "types";

export const usePaymentsListQuery = () => {
  const query = usePaginatedDataTableQuery<PaymentForListDto>({
    queryFn: BillingManagementService.list.request,
    queryKey: BillingManagementService.list.queryKey
  });

  const onFetchData = async () => {
    await query.refetch();
  };

  return {
    onFetchData,
    ...query
  };
};
