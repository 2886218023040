import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DuplicatePaymentManagementService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useIsLoadingMutation } from "hooks/utils";
import { ApiError } from "types";

export const usePayBackMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: [DuplicatePaymentManagementService.payBack.mutationKey],
    mutationFn: DuplicatePaymentManagementService.payBack.request,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [DuplicatePaymentManagementService.list.queryKey]
      });
      SnackbarUtils.success("Payback successful");
    },
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onPayBack = async (duplicatePaymentId: string, paybackDate: string) => {
    return await mutation.mutateAsync({ duplicatePaymentId, paybackDate });
  };
  const { isLoading } = useIsLoadingMutation(
    DuplicatePaymentManagementService.payBack.mutationKey
  );

  return {
    ...mutation,
    onPayBack,
    isLoading
  };
};
