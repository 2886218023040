import { useFormContext } from "react-hook-form";
import { AppointmentForCreateUpdateFormContext } from "types/AppointmentForCreateUpdateFormContext";
import { useLocationsLookup, useUsersLookupQuery } from "hooks/lookups";
import { APPOINTMENT_NOTICE, DATE_TIME_FORMAT, UserRoles } from "app-constants";
import { useDoctorAssignedToPatientFileMutation } from "hooks/doctor-management";
import { useEffect, useMemo } from "react";
import {
  ApiError,
  AppointmentType,
  DoctorAssignedToPatienForAppointmentCreateUpdateDto, EAppointmentType,
  Option
} from "types";
import { Moment } from "moment/moment";
import moment from "moment";
import { DateUtils, ErrorsUtils, AppointmentUtils } from "utils";

export const useAppointmentForm = () => {
  const formContext = useFormContext<AppointmentForCreateUpdateFormContext>();
  const patientFileId = formContext.watch("patientFileId");
  const selectedDate = formContext.watch("scheduleDetail.date");
  const type = formContext.watch("type");
  const { data: doctorsLookup } = useUsersLookupQuery(UserRoles.Doctor);
  const { data: doctorAssignedToPatientFile, onFetchDoctorAssignedToPatientFile } = useDoctorAssignedToPatientFileMutation();
  const { data: locationLookup } = useLocationsLookup();
  const availabilitySlots = doctorAssignedToPatientFile?.availabilitySlots;
  const appointmentType = useMemo<Array<{ id: AppointmentType, label: string }>>(() => {
    return [
      {
        id: EAppointmentType.InitialEvaluation,
        label: "Initial Evaluation"
      },
      {
        id: EAppointmentType.ReEvaluation,
        label: "Re-Evaluation"
      },
      {
        id: EAppointmentType.Deposition,
        label: "Deposition"
      }
    ];
  }, []);

  const appointmentNoticeRequesterOptions = useMemo<Option[]>(() => APPOINTMENT_NOTICE, []);

  const setCalculateDurationToTime = (value: Moment) => {
    const periodInMinutes = getAppointmentInfo(doctorAssignedToPatientFile).period;
    if (periodInMinutes !== undefined && value.isValid()) {
      const newTime = value.clone().add(periodInMinutes, "minutes");
      const newTimeFormatted = newTime.format(DATE_TIME_FORMAT.FULL_TIME);
      formContext.setValue("scheduleDetail.duration.endTime", newTimeFormatted);
    }
  };

  useEffect(() => {
    if(patientFileId) {
      formContext.clearErrors();
    }
  }, [formContext, patientFileId]);

  useEffect(() => {
    if(type && doctorAssignedToPatientFile) {
      formContext.setValue("instructions", getAppointmentInfo(doctorAssignedToPatientFile).instructions);
      const startTime = moment(`1970-01-01T${formContext.getValues("scheduleDetail.duration.startTime")}`);
      setCalculateDurationToTime(startTime);
    }
    // eslint-disable-next-line
  }, [type, doctorAssignedToPatientFile, formContext]);

  useEffect(() => {
    if(type && type !== EAppointmentType.Deposition) {
      formContext.setValue("addressUrl", "");
    }
  }, [type, formContext]);

  const resetFields = () => {
    formContext.setValue("fileId", "");
    formContext.setValue("doctorUserId", "");
    formContext.setValue("instructions", "");
    formContext.setValue("generalMedicalLiaisonId", "");
    formContext.setValue("doctorExclusiveMedicalAssistantId", "");
    formContext.setValue("doctorOfficeManagerId", "");
    formContext.setValue("appointmentTime", "");
    formContext.setValue("scheduleDetail", {
      date: "",
      duration: {
        startTime: "",
        endTime: ""
      }
    });
    formContext.setValue("locationId", "");
  };
  const getAppointmentInfo = (doctorAssigned?: DoctorAssignedToPatienForAppointmentCreateUpdateDto) => {
    if(!doctorAssigned) {
      return {
        instructions: "",
        period: 0
      };
    }
    const type = formContext.getValues("type");
    if(type === EAppointmentType.Deposition) {
      return {
        instructions: doctorAssigned.depositionAppointmentInfo?.instructions,
        period: doctorAssigned.depositionAppointmentInfo?.period
      };
    }
    if(type === EAppointmentType.ReEvaluation) {
      return {
        instructions: doctorAssigned.reEvaluationAppointmentInfo?.instructions,
        period: doctorAssigned.reEvaluationAppointmentInfo?.period
      };
    }

    return {
      instructions: doctorAssigned.initialEvaluationAppointmentInfo?.instructions,
      period: doctorAssigned.initialEvaluationAppointmentInfo?.period
    };
  };

  const fillNonEditableFields = (response: DoctorAssignedToPatienForAppointmentCreateUpdateDto) => {
    const instructions = getAppointmentInfo(response).instructions;
    formContext.setValue("doctorUserId", response.id);
    formContext.setValue("instructions", instructions);
    formContext.setValue("generalMedicalLiaisonId", response.generalMedicalLiaisonId);
    formContext.setValue("doctorExclusiveMedicalAssistantId", response.doctorExclusiveMedicalAssistantId);
    formContext.setValue("doctorOfficeManagerId", response.doctorOfficeManagerId);
    formContext.setValue("locationId", response.locationIdAssignedToPatientFile ?? "");
    formContext.setValue("specialityId", response.specialityIdAssignedToPatientFile ?? "");
    formContext.setValue("appointments", response.appointments);
    formContext.setValue("scheduleDetail", {
      date: "",
      duration: {
        startTime: "",
        endTime: ""
      }
    });
  };

  const onChangePatientProfile = async (patientFileId?: string) => {
    if(!patientFileId) {
      resetFields();
      return;
    }
    try {
      const response = await onFetchDoctorAssignedToPatientFile(patientFileId);
      fillNonEditableFields(response);
    } catch(e) {
      ErrorsUtils.renderApiErrors(e as ApiError);
    }
  };
  const onDisabledRangeTimeDuration = (value: Moment) => {
    const selectedTime = value.format(DATE_TIME_FORMAT.FULL_TIME);
    const timeRange = formContext.watch("doctorAvailabilityTimeRange");
    const start = DateUtils.formatToFullTime(timeRange?.startTime ?? "");
    const end = DateUtils.formatToFullTime(timeRange?.endTime ?? "");

    const isSameOrAfter = DateUtils.formatToFullTime(selectedTime).isSameOrAfter(start);
    const isBefore = DateUtils.formatToFullTime(selectedTime).isBefore(end);
    return !(isSameOrAfter && isBefore);
  };

  const onChangeAppointmentTime = (value: string) => {
    if(!value) {
      formContext.setValue("doctorAvailabilityTimeRange.startTime", "");
      formContext.setValue("doctorAvailabilityTimeRange.endTime", "");
      formContext.setValue("scheduleDetail.duration.startTime", "");
      formContext.setValue("scheduleDetail.duration.endTime", "");
      return;
    }

    const timeRange = AppointmentUtils.getSelectedTimeRange(value);
    formContext.setValue("doctorAvailabilityTimeRange.startTime", timeRange.startTime);
    formContext.setValue("doctorAvailabilityTimeRange.endTime", timeRange.endTime);
  };

  const onChangeScheduleDetailDurationStartTime = (value: Moment | null) => {
    if(!value) return;
    setCalculateDurationToTime(value);
  };

  const onChangeScheduleDetailDate = () => {
    formContext.setValue("appointmentTime", "");
    formContext.setValue("scheduleDetail.duration", {
      startTime: "",
      endTime: ""
    });
  };

  const shouldDisabledScheduleDetailDate = (date: Moment) => {
    const currentDay = date.format(DATE_TIME_FORMAT.DATE);
    return !availabilitySlots?.[currentDay];
  };

  const formattedSelectedDate = moment(selectedDate)?.format(DATE_TIME_FORMAT.DATE);

  const appointmentTimeOptions = availabilitySlots?.[formattedSelectedDate]?.map((availabilitySlot) => {
    return {
      id: `${formattedSelectedDate}_${availabilitySlot.startTime}_${availabilitySlot.endTime}`,
      label: `${DateUtils.formatToTime(availabilitySlot.startTime)} - ${DateUtils.formatToTime(availabilitySlot.endTime)}`
    };
  }) ?? [];

  const getMinMaxTime = () => {
    if(!formContext.watch("appointmentTime") || formContext.watch("id")) return;

    const selectedTimeRange = AppointmentUtils.getSelectedTimeRange(formContext.watch("appointmentTime") ?? "");

    const minTime = moment(selectedTimeRange.startTime, DATE_TIME_FORMAT.FULL_TIME);
    const maxTime = moment(selectedTimeRange.endTime, DATE_TIME_FORMAT.FULL_TIME);

    return {
      minTime,
      maxTime
    };
  };

  return {
    appointmentTimeOptions,
    doctorsLookup,
    locationLookup,
    appointmentType,
    appointmentNoticeRequesterOptions,
    shouldDisabledScheduleDetailDate,
    onChangeScheduleDetailDurationStartTime,
    onChangeScheduleDetailDate,
    onChangeAppointmentTime,
    onDisabledRangeTimeDuration,
    onChangePatientProfile,
    minTime: getMinMaxTime()?.minTime,
    maxTime: getMinMaxTime()?.maxTime,
    isDeposition: type === EAppointmentType.Deposition
  };
};
