import { PatientFileStatus } from "types";

const PATIENT_FILE_STATUS: Array<{ id: PatientFileStatus, label: string }> = [
  {
    id: "InProgress",
    label: "In Progress"
  },
  {
    id: "Closed",
    label: "Closed"
  },
  {
    id: "Draft",
    label: "Draft"
  }
];

export default PATIENT_FILE_STATUS;