import { useMutation } from "@tanstack/react-query";
import { UsersManagementService } from "api";
import React from "react";
import { store } from "stores";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";

export const useEnableUserMutation = () => {
  const enableUserMutation = useMutation({
    mutationKey: [UsersManagementService.enable.queryKey],
    mutationFn: (userId: string) => UsersManagementService.enable.request(userId),
    onSuccess() {
      SnackbarUtils.success("User enabled successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });
  
  const onEnableUser = (userId?: string) => {
    if(!userId) return;
    enableUserMutation.mutate(userId);
  };
  
  const enableUserHandler = (userId: string, userName: string, callback?: Function) => {
    store.confirmModalStore.open({
      title: "Confirm account deactivation",
      body: <p>Are you sure you want to enable <strong>{userName}</strong> account?</p>,
      options: {
        onConfirm: async () => {
          try {
            await onEnableUser(userId);
            if(callback) await callback();
          } catch(e) {
            console.error(e);
          }
        }
      }
    });
  };
  return {
    onEnableUser,
    enableUserHandler,
    ...enableUserMutation
  };
};