import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { RouteObject } from "react-router-dom";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";

const ArchivedReportProfilePage = WithLoadable(
  lazy(() => import("pages/archived-reports/ArchivedReportProfilePage"))
);
const ArchivedReportListPage = WithLoadable(
  lazy(() => import("pages/archived-reports/ArchivedReportListPage"))
);
const ArchivedReportPreFolderDetailsPage = WithLoadable(
  lazy(() => import("pages/active-reports/ActiveReportPreFolderDetailsPage"))
);
const ArchivedReportPostFolderDetailsPage = WithLoadable(
  lazy(() => import("pages/active-reports/ActiveReportPostFolderDetailsPage"))
);
const ArchivedReportNoticesFolderDetailsPage = WithLoadable(
  lazy(
    () => import("pages/active-reports/ActiveReportNoticesFolderDetailsPage")
  )
);
export const ArchivedReportsRoutes: RouteObject = {
  path: AppRoutes.archivedReports.root,
  element: (
    <AuthorizationGuard
      permissions={[AppPermissions.ArchiveReportPermissions.Actions.View]}
    />
  ),
  children: [
    {
      index: true,
      element: <ArchivedReportListPage />
    },
    {
      path: `${AppRoutes.activeReports.profile}/:archivedReportId`,
      children: [
        { index: true, element: <ArchivedReportProfilePage /> },
        {
          path: `${AppRoutes.folders.root}/:folderId/${AppRoutes.folders.preEvaluationFolder}`,
          element: <ArchivedReportPreFolderDetailsPage />
        },
        {
          path: `${AppRoutes.folders.root}/:folderId/${AppRoutes.folders.postEvaluationFolder}`,
          element: <ArchivedReportPostFolderDetailsPage />
        },
        {
          path: `${AppRoutes.folders.root}/:folderId/${AppRoutes.folders.notices}`,
          element: <ArchivedReportNoticesFolderDetailsPage />
        }
      ]
    }
  ]
};
