import { useMutation } from "@tanstack/react-query";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";
import { store } from "stores";
import React from "react";
import { UsersManagementService } from "api";

export const useDeleteUserMutation = () => {
  const deleteUserMutation = useMutation({
    mutationKey: [UsersManagementService.delete.queryKey],
    mutationFn: (userId: string) => UsersManagementService.delete.request(userId),
    onSuccess() {
      SnackbarUtils.success("User deleted successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });
  
  const onDeleteUser = async (userId: string) => {
    return await deleteUserMutation.mutateAsync(userId);
  };
  
  const deleteUserHandler = (userId: string, userFullName: string, callback?: Function) => {
    store.confirmModalStore.open({
      title: "Confirm delete user",
      body: <p>Are you sure you want to delete <strong>{userFullName}</strong> user file?</p>,
      options: {
        onConfirm: async () => {
          try {
            await onDeleteUser(userId);
            if(callback) await callback();
          } catch(e) {
            console.error(e);
          }
        }
      }
    });
  };
  
  return {
    ...deleteUserMutation,
    onDeleteUser,
    deleteUserHandler
  };
};