import { ApiError } from "types";
import { makeAutoObservable } from "mobx";

export default class ErrorStore {
  error: ApiError | null = null;
  constructor() {
    makeAutoObservable(this);
  }

  setServerError = (error: ApiError) => {
    this.error = error;
  };
}