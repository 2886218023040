import { ApiURLs, httpClient } from "api/core";
import {
  AppointmentFinanceForListDtoPaginatedList,
  PaginatedDataTable,
  PaymentForCreateUpdateDto,
  PaymentForListDto,
  PaymentProfileForReadDto
} from "types";

const URL = ApiURLs.billingManagement;

async function getPayments(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<PaymentForListDto>>(
    `${URL}/payments?${queryParams}`
  );
}

async function createPayment(payment: PaymentForCreateUpdateDto) {
  return await httpClient.post<void>(`${URL}/payments`, payment);
}

async function getPaymentDetails(paymentId: string) {
  return await httpClient.get<PaymentProfileForReadDto>(
    `${URL}/payments/${paymentId}`
  );
}

async function getAppointmentFinances(queryParams: string) {
  return await httpClient.get<AppointmentFinanceForListDtoPaginatedList>(
    `${URL}/appointment-finances?${queryParams}`
  );
}

export const BillingManagementService = {
  list: {
    request: getPayments,
    queryKey: "payments-list"
  },
  create: {
    request: createPayment,
    mutationKey: "create-payment"
  },
  details: {
    request: getPaymentDetails,
    queryKey: "get-payment-details"
  },
  appointmentFinances: {
    request: getAppointmentFinances,
    queryKey: "appointment-finances-list"
  }
};
