import { useMutation } from "@tanstack/react-query";
import { LocationService } from "api";
import { ErrorsUtils, ObjectUtils, SnackbarUtils } from "utils";
import { ApiError, LocationForCreateUpdateDto } from "types";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";

export const useCreateLocationMutation = () => {
  const navigate = useNavigate();
  const createLocationMutation = useMutation({
    mutationKey: [LocationService.create.queryKey],
    mutationFn: (location: LocationForCreateUpdateDto) => LocationService.create.request(location),
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess(locationId: string) {
      SnackbarUtils.success("Location created successfully!");
      const locationProfilePageUrl = `/${AppRoutes.management.root}/${AppRoutes.locations.root}/${AppRoutes.locations.profile}/${locationId}`;
      navigate(locationProfilePageUrl);
    }
  });

  const onCreateLocation = (location: LocationForCreateUpdateDto) => {
    if(!location) return;
    createLocationMutation.mutate(ObjectUtils.replaceEmptyStringsWithNull(location));
  };
  
  return {
    onCreateLocation,
    ...createLocationMutation
  };
};