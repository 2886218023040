import { makeAutoObservable } from "mobx";
import { DoctorSpecialityLocationDto } from "types";

export default class PatientFileStore {
  specialitiesLocations: DoctorSpecialityLocationDto[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSpecialitiesLocations = (list: DoctorSpecialityLocationDto[]) => {
    this.specialitiesLocations = list;
  };
}