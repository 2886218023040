import React from "react";
import { List, Stack } from "@mui/material";
import { MenuItem as MenuItemType } from "types";
import { MenuListItem } from "components/layout/menu";

type MenuListItemsProps = {
  menuListItems: MenuItemType[];
}
const MenuListItems: React.FC<MenuListItemsProps> = ({ menuListItems }) => {
  
  const renderMenuItems = (menuListItems: MenuItemType[]) => {
    return menuListItems.map((menuItem) => <MenuListItem menuItem={menuItem} key={menuItem.to}/>);
  };
  
  return (
    <List component="nav" disablePadding>
      <Stack mt={4} spacing={2} alignItems="center">
        {renderMenuItems(menuListItems)}
      </Stack>
    </List>
  );
};

export default MenuListItems;
