import { AppointmentService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";
import { AppointmentForListDto } from "types";
import { useParams } from "react-router-dom";

export const useAppointmentsHistoryListQuery = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const appointmentsHistoryListQuery = usePaginatedDataTableQuery<AppointmentForListDto>({
    queryFn: AppointmentService.list.request,
    queryKey: AppointmentService.list.queryKey,
    initialColumnFilters: [
      { id: "patientFileIdsFilter", value: [patientId] }
    ]
  });
  
  const onFetchData = async () => {
    await appointmentsHistoryListQuery.refetch();
  };
  
  return {
    onFetchData,
    ...appointmentsHistoryListQuery
  };
};