import { useMutation } from "@tanstack/react-query";
import { AppointmentService } from "api";
import { ApiError, CancelCreateFormContext } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useNavigate } from "react-router-dom";

type AppointmentPayload = { appointmentId: string; cancelledAppointment: CancelCreateFormContext }
export const useCancelAppointmentMutation = () => {
  const navigate = useNavigate();

  const cancelAppointmentMutation = useMutation({
    mutationKey: [AppointmentService.cancel.queryKey],
    mutationFn: ({ appointmentId, cancelledAppointment }: AppointmentPayload) =>
      AppointmentService.cancel.request(appointmentId, cancelledAppointment),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Appointment cancelled successfully!");
      navigate(-1);
    }
  });

  const onCancelAppointment = (appointmentPayload: AppointmentPayload) => {
    if(!appointmentPayload) return;

    return cancelAppointmentMutation.mutate(appointmentPayload);
  };

  return {
    ...cancelAppointmentMutation,
    onCancelAppointment
  };
};