import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "stores";
import { QLINICA_REGEX } from "app-constants";

const useBreadcrumb = () => {
  const { breadcrumbStore } = useStore();
  const location = useLocation();

  function formatLabel(label: string): string {
    return label.replace(/-/g, " ").replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  }

  useEffect(() => {
    const paths = location.pathname.split("/").filter((path) => path !== "");
    const filteredPaths = paths.filter((path) => !QLINICA_REGEX.UUID_REGEX.test(path));
    const breadcrumbs = filteredPaths.map((path, index) => ({
      label: formatLabel(path),
      link: index !== filteredPaths.length - 1 ? `/${filteredPaths.slice(0, index + 1).join("/")}` : undefined
    }));

    breadcrumbStore.setBreadcrumbs(breadcrumbs);

    return () => {
      breadcrumbStore.clearBreadcrumbs();
    };
  }, [location, breadcrumbStore]);
};

export default useBreadcrumb;
