import { ThemeOption } from "types";

export default function themeTypography(theme: ThemeOption) {
  return {
    fontFamily: theme.mainFontFamily,
    h6: {
      fontWeight: 500,
      fontFamily: theme.secondaryFontFamily,
      fontSize: "1.125rem",
      lineHeight: "27px"
    },
    h5: {
      fontSize: "1.25rem",
      fontFamily: theme.secondaryFontFamily,
      fontWeight: 500,
      lineHeight: "30px"
    },
    h4: {
      fontSize: "1.5rem",
      fontFamily: theme.secondaryFontFamily,
      fontWeight: 600,
      lineHeight: "36px"
    },
    h3: {
      fontSize: "1.75rem",
      fontFamily: theme.secondaryFontFamily,
      fontWeight: 600,
      lineHeight: "42px"
    },
    h2: {
      fontSize: "2rem",
      fontFamily: theme.secondaryFontFamily,
      fontWeight: 700,
      lineHeight: "48px"
    },
    h1: {
      fontSize: "2.375rem",
      fontFamily: theme.secondaryFontFamily,
      fontWeight: 700,
      lineHeight: "57px"
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.334em"
    },
    body2: {
      letterSpacing: "0em",
      fontWeight: 400,
      lineHeight: "1.5em"
    },
    button: {
      textTransform: "capitalize"
    },
    mainContent: {
      backgroundColor: theme.backgroundColor,
      width: "100%",
      minHeight: "calc(100vh - 88px)",
      flexGrow: 1,
      padding: "20px",
      marginTop: "88px",
      marginRight: "20px",
      borderRadius: "12px"
    },
    menuCaption: {
      fontSize: "0.875rem",
      fontWeight: 500,
      padding: "6px",
      textTransform: "capitalize",
      marginTop: "10px"
    },
    subMenuCaption: {
      fontSize: "0.6875rem",
      fontWeight: 500,
      textTransform: "capitalize"
    },
    commonAvatar: {
      cursor: "pointer",
      borderRadius: "8px"
    },
    smallAvatar: {
      width: "22px",
      height: "22px",
      fontSize: "1rem"
    },
    mediumAvatar: {
      width: "34px",
      height: "34px",
      fontSize: "1.2rem"
    },
    largeAvatar: {
      width: "44px",
      height: "44px",
      fontSize: "1.5rem"
    }
  };
}