import { RouteObject } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";
import { FormLayout } from "layout";

const AppointmentListPage = WithLoadable(
  lazy(() => import("pages/appointments/AppointmentListPage"))
);
const AppointmentProfilePage = WithLoadable(
  lazy(() => import("pages/appointments/AppointmentProfilePage"))
);
const AppointmentFolderDetailsPage = WithLoadable(
  lazy(() => import("pages/appointments/AppointmentFolderDetailsPage"))
);
const AppointmentNoticesFolderDetailsPage = WithLoadable(
  lazy(() => import("pages/appointments/AppointmentNoticesFolderDetailsPage"))
);
const AppointmentCreatePage = WithLoadable(
  lazy(() => import("pages/appointments/AppointmentCreatePage"))
);

const AppointmentUpdatePage = WithLoadable(
  lazy(() => import("pages/appointments/AppointmentUpdatePage"))
);

export const AppointmentsRoutes: RouteObject = {
  path: AppRoutes.appointments.root,
  element: (
    <AuthorizationGuard
      permissions={[AppPermissions.Appointments.Actions.View]}
    />
  ),
  children: [
    {
      index: true,
      element: <AppointmentListPage />
    },
    {
      path: `${AppRoutes.appointments.profile}/:appointmentId`,
      children: [
        { index: true, element: <AppointmentProfilePage /> },
        {
          path: `${AppRoutes.folders.root}/:folderId`,
          element: <AppointmentFolderDetailsPage />
        },
        {
          path: `${AppRoutes.folders.root}/:folderId/${AppRoutes.folders.notices}`,
          element: <AppointmentNoticesFolderDetailsPage />
        }
      ]
    }
  ]
};

export const AppointmentFormsRoutes: RouteObject = {
  path: AppRoutes.appointments.root,
  element: <FormLayout />,
  children: [
    {
      path: AppRoutes.appointments.create,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.Appointments.Actions.Create]}
        />
      ),
      children: [{ index: true, element: <AppointmentCreatePage /> }]
    },
    {
      path: `${AppRoutes.appointments.update}/:appointmentId`,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.Appointments.Actions.Update]}
        />
      ),
      children: [{ index: true, element: <AppointmentUpdatePage /> }]
    }
  ]
};
