import { EAppointmentStatus, Option } from "types";

const OPTIONS: Option[] = [
  {
    id: "InProgress",
    label: "In Progress"
  },
  {
    id: "Canceled",
    label: "Canceled"
  },
  {
    id: "Archived",
    label: "Archived"
  }
];

const ARCHIVED_OPTIONS: Option[] = [
  {
    id: "Canceled",
    label: "Canceled"
  },
  {
    id: "Archived",
    label: "Archived"
  }
];

const STATUS = {
  [EAppointmentStatus.InProgress]: "In Progress",
  [EAppointmentStatus.Archived]: "Archived",
  [EAppointmentStatus.Canceled]: "Canceled"
};

const APPOINTMENT_STATUS = Object.freeze({
  OPTIONS,
  STATUS,
  ARCHIVED_OPTIONS
});
export default APPOINTMENT_STATUS;