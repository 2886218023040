import "./CardItem.scss";
import { Grid, Stack, Typography } from "@mui/material";
import { GridProps } from "@mui/material/Grid/Grid";
import SkeletonCardItem from "components/common/card/SkeletonCardItem";
import React, { PropsWithChildren, ReactNode } from "react";

type CardItemProps = PropsWithChildren & {
  title?: string;
  description?: ReactNode;
  cardGridProps?: GridProps;
  isLoading?: boolean;
  imageURL?: string
}
const CardItem = ({ title, description, cardGridProps, isLoading, imageURL, children }: CardItemProps) => {
  if(isLoading) {
    return <SkeletonCardItem cardGridProps={cardGridProps}/>;
  }
  return (
    <Grid xs={12} md={4} {...cardGridProps} item>
      <Stack direction="column" alignItems="start" justifyContent="center">
        <Typography mb="5px" className="card-item-label" variant="body2" component="label">
          {title}
        </Typography>
        <Typography mb="5px" className="card-item-description" variant="body2" component="label" minHeight={24}>
          {!children ? imageURL ? <img className="card-item-image" src={imageURL} alt="card-item"/> : description : children }
        </Typography>
      </Stack>
    </Grid>
  );
};

export default CardItem;