import React, { useCallback, useMemo } from "react";
import {
  MaterialReactTable,
  MaterialReactTableProps,
  MRT_ColumnDef,
  MRT_Row,
  useMaterialReactTable
} from "material-react-table";
import {
  ContentAdderProps,
  TableRowActions,
  ThemeOption,
  ZeroStateTemplateProps
} from "types";
import { ZeroStateTemplate } from "components/zero-state-template";
import { TopToolbar } from "components/common/data-table/toolbar";
import { Stack, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";
import { TableAdditionBar } from "components/table-addition-bar";
import { RowActions } from "components/common/data-table";
import cssVariables from "assets/scss/abstracts/_variables.scss";
import { PAGINATION_STATE } from "app-constants";
import "./DataTable.scss";

type DataTableProps<T extends Record<string, any> = {}> = Omit<MaterialReactTableProps<T>, "data"> & {
  data: T[];
  columns: Array<MRT_ColumnDef<T>>;
  zeroStateTemplateState?: ZeroStateTemplateProps;
  tableAdditionBarProps?: ContentAdderProps;
  isLoading?: boolean;
  rowActions?: TableRowActions<T>;
  onSelectRow?(row: MRT_Row<T>): void;
  hasSearchResults?: boolean;
};
export default function DataTable<T extends Record<string, any> = {}>(
  props: DataTableProps<T>
) {
  const { columns, data, rowActions, onSelectRow, ...rest } = props;

  const appendColumnAction = useCallback(
    (columns: Array<MRT_ColumnDef<T>>) => {
      if (!rowActions?.length) return columns;

      const actionColumn = {
        header: "",
        accessorKey: "actions",
        Cell: tRow => <RowActions row={tRow.row} rowActions={rowActions} />,
        enableSorting: false,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        columnDefType: "display",
        muiColumnActionsButtonProps: {
          sx: {
            display: "none"
          }
        }
      } as MRT_ColumnDef<T>;

      columns.push(actionColumn);

      return columns;
    },
    [rowActions]
  );

  const tableColumns = useMemo<Array<MRT_ColumnDef<T>>>(
    () => appendColumnAction(columns),
    [columns, appendColumnAction]
  );

  const onSelectRowHandler = useCallback(
    (row: MRT_Row<T>) => {
      if (!onSelectRow) return;
      onSelectRow(row);
    },
    [onSelectRow]
  );

  const tableBodySystemProps = useMemo<SxProps<Theme> | undefined>(
    () => () => ({
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#fff !important"
      },
      "&:hover .table-row-actions": {
        visibility: "visible"
      },
      ".MuiTableCell-root": {
        backgroundColor: `${(cssVariables as ThemeOption).primaryGray600} !important`,
        boxShadow: "none"
      },
      "&:hover .MuiTableCell-root": {
        backgroundColor: "#fff !important"
      }
    }),
    []
  );

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    enableSorting: false,
    onGlobalFilterFnChange: updaterOrValue => {
      if (rest.onGlobalFilterChange && rest.onPaginationChange) {
        rest.onPaginationChange({
          pageIndex: PAGINATION_STATE.DEFAULT_PAGE_NUMBER,
          pageSize: PAGINATION_STATE.PAGE_SIZE
        });
        rest.onGlobalFilterChange(updaterOrValue);
      }
    },
    onColumnFiltersChange: updaterOrValue => {
      if (rest.onColumnFiltersChange && rest.onPaginationChange) {
        rest.onPaginationChange({
          pageIndex: PAGINATION_STATE.DEFAULT_PAGE_NUMBER,
          pageSize: PAGINATION_STATE.PAGE_SIZE
        });
        rest.onColumnFiltersChange(updaterOrValue);
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      hover: false,
      className: "data-table-body",
      sx: tableBodySystemProps,
      onClick: () => onSelectRowHandler(row)
    }),
    renderTopToolbar: ({ table }) => <TopToolbar<T> table={table} />,
    muiColumnActionsButtonProps: {
      sx: {
        display: "none"
      }
    },
    muiTableHeadCellProps: {
      sx: {
        ".MuiBadge-root": {
          display: "inherit"
        }
      }
    },
    initialState: {
      showGlobalFilter: true,
      columnPinning: { right: ["actions"] }
    },
    muiFilterTextFieldProps: {
      variant: "outlined"
    },
    muiSearchTextFieldProps: {
      variant: "outlined"
    },
    muiTableFooterProps: {
      className: "data-table-footer data-table-pagination"
    },
    muiPaginationProps: {
      className: "data-table-pagination",
      color: "standard",
      shape: "rounded",
      showRowsPerPage: true,
      variant: "outlined"
    },
    paginationDisplayMode: "pages",
    muiTableHeadProps: {
      className: "data-table-header"
    },
    muiTablePaperProps: {
      className: "data-table-paper"
    },
    muiTopToolbarProps: {
      className: "data-table-toolbar"
    },
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    enableFilters: true,
    enableMultiSort: true,
    enableTableHead: true,
    sortDescFirst: true,
    enableGlobalFilter: true,
    enableGlobalFilterModes: true,
    enablePagination: true,
    enableBottomToolbar: true,
    ...rest
  });

  if (
    !data.length &&
    !rest.hasSearchResults &&
    !rest.isLoading &&
    rest.zeroStateTemplateState
  ) {
    return <ZeroStateTemplate {...rest.zeroStateTemplateState} />;
  }

  return (
    <Stack>
      {rest.tableAdditionBarProps && (
        <TableAdditionBar {...rest.tableAdditionBarProps} />
      )}
      <MaterialReactTable table={table} />
    </Stack>
  );
}
