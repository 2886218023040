import "./Filter.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, IconButton, Stack, Tooltip } from "@mui/material";
import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import { MRT_Column, MRT_TableInstance } from "material-react-table";
import { PrimeButton } from "components/common/buttons";
import { FilterDetails, FilterMenu } from "components/common/data-table/toolbar";

type FilterProps<TData extends Record<string, any> = {}> = {
  table: MRT_TableInstance<TData>;
};

function Filter<T extends Record<string, any> = {}>({ table }: FilterProps<T>) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Map<string, string[]>>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  
  useEffect(() => {
    const initializeFilters = () => {
      const initialColumnFilters = table.getState().columnFilters;
      const filtersMap = new Map<string, string[]>();
      
      initialColumnFilters.forEach((column) => {
        filtersMap.set(column.id, column.value as string[]);
      });
      
      setSelectedFilters(filtersMap);
    };
    
    initializeFilters();
  }, [table]);
  
  const handleSelectFilter = useCallback((selectedColumn: MRT_Column<T>, selectedValue: string) => {
    const updatedFilters = new Map<string, string[]>(selectedFilters);
    
    if(!updatedFilters.has(selectedColumn.id)) {
      updatedFilters.set(selectedColumn.id, [selectedValue]);
    } else {
      let existingValues = updatedFilters.get(selectedColumn.id) ?? [];
      
      if(existingValues.includes(selectedValue)) {
        const valueIndex = existingValues.indexOf(selectedValue);
        existingValues.splice(valueIndex, 1);
      } else {
        if(selectedColumn.columnDef.filterVariant === "select") {
          existingValues = [selectedValue];
        } else {
          existingValues.push(selectedValue);
        }
      }
      
      if(!existingValues.length) {
        updatedFilters.delete(selectedColumn.id);
      }else {
        updatedFilters.set(selectedColumn.id, existingValues);
      }
    }
    
    setSelectedFilters(updatedFilters);
    selectedColumn.setFilterValue(updatedFilters.get(selectedColumn.id));
  }, [selectedFilters]);
  
  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsFilterOpen(true);
  }, []);
  
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setIsFilterOpen(false);
  }, []);
  
  const shouldHideFilter = useMemo(() => !table.getAllColumns().some((column) => column.getCanFilter()), [table]);
  
  const shouldShowFilterBadge = useMemo(() => table.getState().columnFilters.length > 0, [table]);
  
  if(shouldHideFilter) return null;
  
  const handleClearFilters = () => {
    setSelectedFilters(new Map());
    table.getAllColumns().forEach((column) => column.setFilterValue(null));
  };
  
  return (
    <>
      <Tooltip arrow title="Show/Hide filters">
        <IconButton
          aria-controls={isMenuOpen ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? "true" : undefined}
          onClick={isFilterOpen ? handleMenuClose : handleMenuOpen}
        >
          <Badge variant="dot" color="primary" invisible={!shouldShowFilterBadge}>
            {isFilterOpen ? <FilterAltOff/> : <FilterAlt/>}
          </Badge>
        </IconButton>
      </Tooltip>
      <FilterMenu isMenuOpen={isMenuOpen} handleMenuClose={handleMenuClose} anchorEl={anchorEl}>
        <FilterDetails handleSelect={handleSelectFilter} selected={selectedFilters} table={table}/>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <PrimeButton onClick={handleClearFilters} variant="text" color="primary">
            Clear
          </PrimeButton>
        </Stack>
      </FilterMenu>
    </>
  );
}

export default Filter;