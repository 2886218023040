import { DocumentUtils } from "utils";
import {
  useBlockNavigation, useCompleteDocumentMultipartMutation,
  useDocumentMultipartAbortMutation,
  useGetDocumentMultipartDetailsMutation,
  useIsLoadingUploadDocuments,
  useUploadMultipartDocumentMutation,
  useUploadSingleDocumentMutation
} from "hooks";
import { DocumentForMultiPartUploadDetailDto } from "types";
import { APP_CONFIGURATIONS } from "config";
import { useMemo } from "react";
import { DOCUMENT_MANAGEMENT } from "app-constants";

export const useUploadDocumentMutation = (URL: string, entityId: string) => {
  useBlockNavigation();
  const { uploadSingleDocument } = useUploadSingleDocumentMutation();
  const { uploadMultipartDocumentChunk } = useUploadMultipartDocumentMutation();
  const { getDocumentMultipartDetails } = useGetDocumentMultipartDetailsMutation();
  const { isLoading } = useIsLoadingUploadDocuments();
  const { completeMultipartDocumentUpload } = useCompleteDocumentMultipartMutation();
  const { abortMultipartDocumentUpload } = useDocumentMultipartAbortMutation();

  const MAX_UPLOAD_BATCH_SIZE = useMemo(() => parseInt(APP_CONFIGURATIONS.REACT_APP_MAX_UPLOAD_BATCH_SIZE) || DOCUMENT_MANAGEMENT.DEFAULT_MAX_UPLOAD_BATCH_SIZE, []);

  const processInBatches = async (promiseFactories: Array<() => Promise<void>>, batchSize: number): Promise<boolean> => {
    for (let i = 0; i < promiseFactories.length; i += batchSize) {
      const batch = promiseFactories.slice(i, i + batchSize).map(factory => factory());
      try {
        await Promise.allSettled(batch);
      } catch (error) {
        console.error("Error processing batch", error);
        return false;
      }
    }
    return true;
  };

  const uploadSingleFiles = async (folderId: string, files: File[], documents: DocumentForMultiPartUploadDetailDto[]): Promise<void> => {
    const uploadPromises = documents.filter(d => !d.isMultiPartUpload).map(detail => {
      const file = files.find(f => f.name === detail.fileName);
      if (!file) return Promise.resolve();
      return uploadSingleDocument({ folderId, files: [file], URL, entityId });
    });
    await Promise.allSettled(uploadPromises);
  };

  const uploadMultipartFiles = async (folderId: string, files: File[], documents: DocumentForMultiPartUploadDetailDto[]): Promise<void> => {
    for (const detail of documents.filter(d => d.isMultiPartUpload)) {
      const file = files.find(f => f.name === detail.fileName);
      if (!file) continue;
      await uploadMultipartFile(folderId, file, detail);
    }
  };

  const uploadMultipartFile = async (folderId: string, file: File, detail: DocumentForMultiPartUploadDetailDto): Promise<void> => {
    const chunks = DocumentUtils.splitFile(file, detail.chunkSize);
    const uploadPromises = chunks.map((chunk, index) => {
      return () => uploadMultipartDocumentChunk({
        entityId,
        folderId,
        chunkSize: detail.chunkSize,
        multipartSessionUploadId: detail.multipartSessionUploadId!,
        draftDocumentId: detail.draftDocumentId!,
        fileName: detail.fileName,
        isLastPart: index === chunks.length - 1,
        partNumber: index + 1,
        part: chunk,
        URL
      });
    });

    const success = await processInBatches(uploadPromises, MAX_UPLOAD_BATCH_SIZE);
    const documentInfo = {
      draftDocumentId: detail.draftDocumentId,
      multipartSessionUploadId: detail.multipartSessionUploadId,
      fileName: detail.fileName
    };

    if (!success) {
      await abortMultipartDocumentUpload({ URL, folderId, entityId, abort: documentInfo });
    } else {
      await completeMultipartDocumentUpload({ URL, folderId, entityId, data: documentInfo });
    }
  };

  const onUploadFiles = async (folderId: string, files: File[]): Promise<void> => {
    const hasMultiPartUploadMechanism = DOCUMENT_MANAGEMENT.MULTIPART_UPLOAD_MODULES.includes(URL);
    if(hasMultiPartUploadMechanism) {
      try {
        const documentsInfo = DocumentUtils.prepareFilesInfo(files);
        const { documents } = await getDocumentMultipartDetails({ URL, folderId, documents: documentsInfo, entityId });
        await uploadSingleFiles(folderId, files, documents);
        await uploadMultipartFiles(folderId, files, documents);
      } catch (e) {
        console.error(e);
      }
    } else {
      return await uploadSingleDocument({ folderId, files, URL, entityId });
    }
  };

  return { onUploadFiles, isLoading };
};
