import { makeAutoObservable } from "mobx";
import { DocumentForReadDto } from "types";

export default class DocumentsStore {
  isDownloading = false;
  documents: DocumentForReadDto[] = [];
  
  constructor() {
    makeAutoObservable(this);
  }
  
  setDocuments = (documents: DocumentForReadDto[]) => {
    this.documents = documents;
  };
  
  onDeleteDocument = (document: DocumentForReadDto) => {
    const updatedDocuments = this.documents.filter(doc => doc.id !== document.id);
    this.setDocuments(updatedDocuments);
  };
  
  getDocumentByName = (documentName: string) => {
    return this.documents.find(doc => doc.name!.includes(documentName));
  };

  startDownloading = () => {
    this.isDownloading = true;
  };

  completeDownloading = () => {
    this.isDownloading = false;
  };
}