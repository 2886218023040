import { useQuery } from "@tanstack/react-query";
import { DoctorManagementService } from "api";
import { APP_CONFIGURATIONS } from "config";

export const useDoctorProfile = (doctorId = "me") => {
  const doctorProfileQuery = useQuery({
    queryKey: [DoctorManagementService.details.queryKey, doctorId],
    queryFn: () => DoctorManagementService.details.request(doctorId),
    select: (data) => {
      const faxNumber = !data?.contactPhone?.faxNumber ? APP_CONFIGURATIONS.REACT_APP_TENANT_DOCTOR_FAX_NUMBER : data.contactPhone?.faxNumber;
      return {
        ...data,
        contactPhone: {
          ...data.contactPhone,
          faxNumber
        }
      };
    }
  });
  
  return {
    ...doctorProfileQuery
  };
};