import { useProfileQuery } from "hooks/users";
import { useStore } from "stores";
import { useEffect } from "react";

export const usePermissions = () => {
  const { userPermissionsStore: { permissions, setPermissions } } = useStore();
  const { onFetchProfile } = useProfileQuery();

  useEffect(() => {
    if(permissions.length === 0) {
      onFetchProfile().then((response) => {
        setPermissions(response.data?.roles ?? []);
      }).catch(console.error);
    }
    // eslint-disable-next-line
  }, [permissions.length]);

  function hasPermission(permissionToCheck: string[]) {
    for (const role of permissions ?? []) {
      const claims = role.claims;
      for (const claim of claims) {
        if (permissionToCheck.includes(claim.claimValue)) {
          return true;
        }
      }
    }
    return false;
  }

  return {
    permissions,
    hasPermission
  };
};