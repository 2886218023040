import { Navigate, RouteObject } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";
import { FormLayout } from "layout";

const FinancialsDetails = WithLoadable(
  lazy(() => import("pages/financials/FinancialsDetailsPage"))
);
const DuplicatePaymentCreatePage = WithLoadable(
  lazy(
    () =>
      import("pages/duplicate-payments-management/DuplicatePaymentCreatePage")
  )
);
const DuplicatePaymentsListPage = WithLoadable(
  lazy(
    () =>
      import("pages/duplicate-payments-management/DuplicatePaymentsListPage")
  )
);
const DuplicatePaymentProfilePage = WithLoadable(
  lazy(
    () =>
      import("pages/duplicate-payments-management/DuplicatePaymentProfilePage")
  )
);
const DuplicatePaymentUpdatePage = WithLoadable(
  lazy(
    () =>
      import("pages/duplicate-payments-management/DuplicatePaymentUpdatePage")
  )
);

export const DuplicatePaymentsRoutes: RouteObject = {
  path: AppRoutes.financials.root,
  children: [
    {
      index: true,
      element: <Navigate to={AppRoutes.financials.details} replace />
    },
    {
      path: AppRoutes.financials.details,
      element: <FinancialsDetails />
    },
    {
      path: AppRoutes.financials.duplicatePayments.root,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.BillingManagement.Actions.View]}
        />
      ),
      children: [{ index: true, element: <DuplicatePaymentsListPage /> }]
    },
    {
      path: `${AppRoutes.financials.duplicatePayments.root}/${AppRoutes.financials.duplicatePayments.profile}/:paymentId`,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.BillingManagement.Actions.View]}
        />
      ),
      children: [{ index: true, element: <DuplicatePaymentProfilePage /> }]
    },
    {
      path: AppRoutes.financials.reports,
      element: <h1>Reports</h1>
    }
  ]
};

export const DuplicatePaymentsFormsRoutes: RouteObject = {
  path: AppRoutes.financials.root,
  element: <FormLayout />,
  children: [
    {
      path: `${AppRoutes.financials.duplicatePayments.root}/${AppRoutes.financials.duplicatePayments.create}`,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.BillingManagement.Actions.Create]}
        />
      ),
      children: [{ index: true, element: <DuplicatePaymentCreatePage /> }]
    },
    {
      path: `${AppRoutes.financials.duplicatePayments.root}/${AppRoutes.financials.duplicatePayments.update}/:paymentId`,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.BillingManagement.Actions.Update]}
        />
      ),
      children: [{ index: true, element: <DuplicatePaymentUpdatePage /> }]
    }
  ]
};
