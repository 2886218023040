function ActiveReportIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path className="sidebar-icon" d="M12 14a.968.968 0 0 1-.713-.287A.968.968 0 0 1 11 13c0-.283.096-.52.287-.713A.968.968 0 0 1 12 12c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 12 14zm-4 0a.968.968 0 0 1-.713-.287A.968.968 0 0 1 7 13c0-.283.096-.52.287-.713A.968.968 0 0 1 8 12c.283 0 .52.096.713.287.191.192.287.43.287.713s-.096.52-.287.713A.968.968 0 0 1 8 14zm8 0a.968.968 0 0 1-.713-.287A.968.968 0 0 1 15 13c0-.283.096-.52.287-.713A.968.968 0 0 1 16 12c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 16 14zm-4 4a.968.968 0 0 1-.713-.288A.968.968 0 0 1 11 17c0-.283.096-.52.287-.712A.968.968 0 0 1 12 16c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 12 18zm-4 0a.968.968 0 0 1-.713-.288A.968.968 0 0 1 7 17c0-.283.096-.52.287-.712A.968.968 0 0 1 8 16c.283 0 .52.096.713.288.191.191.287.429.287.712s-.096.52-.287.712A.968.968 0 0 1 8 18zm8 0a.968.968 0 0 1-.713-.288A.968.968 0 0 1 15 17c0-.283.096-.52.287-.712A.968.968 0 0 1 16 16c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 16 18zM4.5 22c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05V5c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h1.625v-.7c0-.227.077-.417.23-.57a.774.774 0 0 1 .57-.23c.234 0 .43.077.588.23a.762.762 0 0 1 .237.57v.7h8.5v-.7c0-.227.077-.417.23-.57a.774.774 0 0 1 .57-.23c.234 0 .43.077.588.23a.762.762 0 0 1 .237.57v.7H19.5c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v15.5c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-15zm0-1.5h15V9.75h-15V20.5z" fill="#FFF" fillRule="nonzero"/>
      </g>
    </svg>
  );
}

export default ActiveReportIcon;