import React from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";

type PrimeLoadingButtonProps = LoadingButtonProps & {}
function PrimeLoadingButton ({ children, ...rest }: PrimeLoadingButtonProps) {
  return (
    <LoadingButton variant="contained" {...rest}>
      {children}
    </LoadingButton>
  );
}

export default PrimeLoadingButton;