import { MRT_TableInstance, MRT_ToggleFullScreenButton as FullScreenToggleButton } from "material-react-table";
import React from "react";
type FilterProps<TData extends Record<string, any> = {}> = {
  table: MRT_TableInstance<TData>;
};
function FullScreen<T extends Record<string, any> = {}>({ table }: FilterProps<T>) {
  return (
    <FullScreenToggleButton table={table}/>
  );
}

export default FullScreen;