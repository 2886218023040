import { useMutation } from "@tanstack/react-query";
import { UsersManagementService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, UserForCreateUpdateDto } from "types";
import { useNavigate } from "react-router-dom";

export const useCreateUserMutation = () => {
  const navigate = useNavigate();

  const createUserMutation = useMutation({
    mutationKey: [UsersManagementService.create.queryKey],
    mutationFn: (user: UserForCreateUpdateDto) => UsersManagementService.create.request(user),
    onSuccess: () => {
      SnackbarUtils.success("User created successfully!");
      navigate(-1);
    },
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onCreateUser = (user: UserForCreateUpdateDto) => {
    if(!user) return;
    createUserMutation.mutate(user);
  };
  
  return {
    onCreateUser,
    ...createUserMutation
  };
};