import { makeAutoObservable } from "mobx";
import { DocumentForReadDto, FolderForListDto } from "types";

export default class FolderManagementStore {
  folders: FolderForListDto[] = [];
  documents: DocumentForReadDto[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  setFolders = (folders: FolderForListDto[]) => {
    this.folders = folders;
  };

  setDocuments = (documents: DocumentForReadDto[]) => {
    this.documents = documents;
  };
}