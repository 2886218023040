import { useQuery } from "@tanstack/react-query";
import { PatientService } from "api";
import { useParams } from "react-router-dom";
import { ApiVersions } from "types";

export const usePatientProfileQuery = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const patientProfileQuery = useQuery({
    queryKey: [PatientService.details.queryKey, patientId],
    queryFn: () => PatientService.details.request(patientId!, ApiVersions.V2)
  });
  
  return {
    ...patientProfileQuery
  };
};