import { Address, UserForMinimalReadDto } from "types";

export default class UserUtils {
  static getFullName(user?: UserForMinimalReadDto) {
    if(!user) return "";

    return `${user?.firstName ?? ""} ${user?.middleName ?? ""} ${user?.lastName ?? ""}`;
  }

  static getFullAddress(address?: Address | null) {
    if(!address) return "";

    return [address?.city, address?.state, address?.zipCode, address?.streetAddress].filter((address) => !!address).join(", ");
  }
}