import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Cancel from "@mui/icons-material/Cancel";
import cssVariables from "assets/scss/abstracts/_variables.scss";
import { ThemeOption } from "types";

type NoRecordMessageProps = {
  message: string
}
function NoRecordMessage({ message }: NoRecordMessageProps) {
  return (
    <Grid item xs={12}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Cancel sx={{ color: (cssVariables as ThemeOption).primaryGray50 }} />
        <Typography color={(cssVariables as ThemeOption).primaryGray}>
          {message}
        </Typography>
      </Stack>
    </Grid>
  );
}

export default NoRecordMessage;
