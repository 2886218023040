const PHONE_REGEX = /^\+1\s\d{3}\s\d{3}\s\d{4}$/;

const SPACE_NOT_ALLOWED_REGEX = /^\S*$/;

const UUID_REGEX = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const MICROSOFT_PASSWORD_RULES_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!$#% etc.]).{8,}$/;

const FAX_NUMBER_REGEX = /\(\d{3}\) \d{3} \d{4}/;

const ALPHABETIC_REGEX = /^[a-zA-Z\s]+$/;

const DECIMAL_REGEX = /^\d+(\.\d{1})?$/;

const QLINICA_REGEX = Object.freeze({
  PHONE_REGEX,
  SPACE_NOT_ALLOWED_REGEX,
  UUID_REGEX,
  MICROSOFT_PASSWORD_RULES_REGEX,
  ALPHABETIC_REGEX,
  FAX_NUMBER_REGEX,
  DECIMAL_REGEX
});
export default QLINICA_REGEX;