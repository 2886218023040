import "./FormCard.scss";
import { Grid, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import { Delete, Edit } from "@mui/icons-material";
import { PrimeActionIconButton } from "components/common";

type FormCardProps = PropsWithChildren & {
  onDelete(): void;
  onEdit(): void;
  gridSize: number
}
function FormCard({ children, onEdit, onDelete, gridSize }: FormCardProps) {
  return (
    <Grid className="form-card" container>
      {children}
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "end" }} alignItems="center" item xs={12} lg={gridSize}>
        <Stack gap={1} direction="row" justifyContent="flex-end">
          <PrimeActionIconButton onClick={onEdit}>
            <Edit/>
          </PrimeActionIconButton>
          <PrimeActionIconButton onClick={onDelete}>
            <Delete sx={{ "&:hover": { color: "red" } }}/>
          </PrimeActionIconButton>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default FormCard;