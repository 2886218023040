import { useQuery } from "@tanstack/react-query";
import { AttorneyManagementService } from "api";
import { ObjectUtils } from "utils";

export const useAttorneyProfileQuery = (attorneyId?: string) => {
  const attorneyProfileQuery = useQuery({
    queryKey: [AttorneyManagementService.details.queryKey, attorneyId],
    queryFn: () => AttorneyManagementService.details.request(attorneyId!),
    enabled: !!attorneyId,
    select: (data) => ObjectUtils.replaceEmptyStringsWithNull(data)
  });
  
  return {
    ...attorneyProfileQuery
  };
};