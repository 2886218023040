import { useMutation } from "@tanstack/react-query";
import { DocumentsService } from "api";
import { ApiError } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";

export const useIsExpectedMutation = (URL: string, entityId: string) => {
  const isExpectedMutation = useMutation({
    mutationKey: [DocumentsService.expected.queryKey],
    mutationFn: ({ folderId, expected }: { folderId: string, expected: boolean }) => DocumentsService.expected.request(URL, entityId, folderId, expected),
    onSuccess() {
      SnackbarUtils.success("Document updated successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const setFileStatus = (expected = false, folderId: string) => {
    return isExpectedMutation.mutateAsync({ expected, folderId });
  };

  return {
    ...isExpectedMutation,
    setFileStatus
  };
};