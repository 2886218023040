import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";
import { store } from "stores";
import React from "react";
import { AttorneyManagementService } from "api";

export const useDeleteAttorneyMutation = () => {
  const queryClient = useQueryClient();
  const deleteAttorneyMutation = useMutation({
    mutationKey: [AttorneyManagementService.delete.mutationKey],
    mutationFn: (attorneyId: string) =>
      AttorneyManagementService.delete.request(attorneyId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [AttorneyManagementService.list.queryKey]
      });
      SnackbarUtils.success("Attorney deleted successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onDeleteAttorney = async (attorneyId: string) => {
    return await deleteAttorneyMutation.mutateAsync(attorneyId);
  };

  const deleteAttorneyHandler = (
    attorneyId: string,
    attorneyFullName: string,
    callback?: Function
  ) => {
    store.confirmModalStore.open({
      title: "Confirm delete attorney",
      body: (
        <p>
          Are you sure you want to delete <strong>{attorneyFullName}</strong> attorney?
        </p>
      ),
      options: {
        onConfirm: async () => {
          store.confirmModalStore.setIsLoading(true);
          try {
            await onDeleteAttorney(attorneyId);
            if (callback) await callback();
          } catch (e) {
            console.error(e);
          }
        }
      },
      buttonLabel: "Delete"
    });
  };

  return {
    ...deleteAttorneyMutation,
    onDeleteAttorney,
    deleteAttorneyHandler
  };
};
