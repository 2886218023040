import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  HttpStatusCode,
  InternalAxiosRequestConfig
} from "axios";
import { APP_CONFIGURATIONS } from "config";
import { AuthService, getAccessToken } from "api";
import { SnackbarUtils } from "utils";
import { store } from "stores";
import { ApiError } from "types";
import { AUTH } from "app-constants";

const sleep = (delay: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
};
const instance: AxiosInstance = axios.create();

instance.defaults.baseURL = APP_CONFIGURATIONS.REACT_APP_BASE_URL;

instance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = await getAccessToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  }
);

instance.interceptors.response.use(
  async response => {
    if (process.env.NODE_ENV === "development") {
      await sleep(500);
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status } = error?.response as AxiosResponse<ApiError>;
    switch (status) {
      case HttpStatusCode.Unauthorized:
        AuthService.logout.request().catch(console.error);
        SnackbarUtils.error("Unauthorized");
        break;
      case HttpStatusCode.Forbidden: {
        if (
          data.code === "Forbidden" &&
          data.message.includes(AUTH.YOUR_ACCOUNT_IS_DISABLED_KEY)
        ) {
          const DISABLED_ACCOUNT_ID = "disabled-account";
          SnackbarUtils.DismissToast("login-id");
          SnackbarUtils.DismissToast(DISABLED_ACCOUNT_ID);
        }
        break;
      }
      case HttpStatusCode.InternalServerError:
        store.errorStore.setServerError(data);
        break;
    }
    return Promise.reject(data);
  }
);

export default instance;
