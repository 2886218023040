import { useCallback, useState } from "react";
import { FieldValues, Path, useFieldArray, useFormContext } from "react-hook-form";
import { ConfirmAlertsUtils } from "utils";

type FormChildConstructor<D> = new () => D;

export const useFormCard = <T extends FieldValues, D>(
  formName: Path<T>,
  FormChild: FormChildConstructor<D>
) => {
  // Use a Set<number> to track the indices of cards that are open for editing
  const [openCards, setOpenCards] = useState<Set<number>>(new Set());
  const { fields, append, remove } = useFieldArray({ name: formName });
  const { trigger, formState: { errors } } = useFormContext<T>();

  const getFormValidation = useCallback(async () => {
    return await trigger(formName);
  }, [trigger, formName]);

  const onAddCard = useCallback(async () => {
    const isValid = await getFormValidation();
    if (!isValid) return;

    append(new FormChild() as any);
    // Assuming the new card will be the last one, open it for editing by adding its index
    setOpenCards(current => new Set(current).add(fields.length));
  }, [FormChild, append, getFormValidation, fields.length]);

  const onDeleteCard = useCallback(
    (index: number) => {
      ConfirmAlertsUtils.confirmAlert(
        {
          title: "Confirm delete",
          message: "Are you sure you want to delete?"
        },
        () => {
          remove(index);
          // Adjust openCards to reflect the removal
          setOpenCards(current => {
            return new Set(Array.from(current)
              .map(cardIndex => cardIndex > index ? cardIndex - 1 : cardIndex)
              .filter(cardIndex => cardIndex !== index));
          });
        }
      );
    },
    [remove]
  );

  const onEditCard = useCallback(async (index: number) => {
    await getFormValidation();
    setOpenCards(current => {
      const newOpenCards = new Set(current);
      if (newOpenCards.has(index)) {
        newOpenCards.delete(index);
      } else {
        newOpenCards.add(index);
      }
      return newOpenCards;
    });
  }, []);

  const onCloseCard = useCallback((index: number) => {
    // Function to close a card by its index
    setOpenCards(current => {
      const newOpenCards = new Set(current);
      newOpenCards.delete(index);
      return newOpenCards;
    });
  }, []);

  const canEdit = useCallback(
    (index: number) => {
      return openCards.has(index);
    },
    [openCards]
  );

  return {
    onDeleteCard,
    onAddCard,
    onEditCard,
    onCloseCard,
    openCards,
    setOpenCards,
    fields,
    canEdit,
    errors
  };
};
