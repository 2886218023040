import { useEffect, useState } from "react";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState
} from "material-react-table";
import { useQuery } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { GlobalSearchParams, PaginatedDataTable, PaginatedQueryParams } from "types";
import { DataTableUtils } from "utils";
import { UseQueryOptions } from "@tanstack/react-query/src/types";
import { QueryKey } from "@tanstack/query-core";
import { PAGINATION_STATE } from "app-constants";
import { useQueryParams } from "hooks";

export type UsePaginatedDataTableQueryProps<
  T,
  TError = unknown,
  TData = T,
  TQueryKey extends QueryKey = QueryKey
> = {
  queryFn: (
    params: AxiosRequestConfig["params"]
  ) => Promise<PaginatedDataTable<T>>;
  queryKey: string;
  initialPagination?: MRT_PaginationState;
  initialColumnFilters?: MRT_ColumnFiltersState;
  initialSorting?: MRT_SortingState;
  params?: AxiosRequestConfig["params"];
  defaultSearchParams?: URLSearchParams;
  queryOptions?: Omit<UseQueryOptions<T, TError, TData, TQueryKey>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined
  }
}

export function usePaginatedDataTableQuery<T extends Record<string, any>>(props: UsePaginatedDataTableQueryProps<T>) {
  const {
    params,
    queryKey,
    queryFn,
    initialSorting = [],
    initialPagination = {
      pageSize: PAGINATION_STATE.PAGE_SIZE,
      pageIndex: PAGINATION_STATE.DEFAULT_PAGE_NUMBER
    },
    initialColumnFilters = [],
    defaultSearchParams
  } = props;

  const { searchParams, setSearchParams, ...restSearchParams } = useQueryParams(defaultSearchParams);

  const initialPageSize = searchParams.get(GlobalSearchParams.PageSize)
    ? parseInt(searchParams.get(GlobalSearchParams.PageSize)!, 10)
    : initialPagination.pageSize;

  const initialPageIndex = searchParams.get(GlobalSearchParams.PageIndex)
    ? parseInt(searchParams.get(GlobalSearchParams.PageIndex)!, 10) - PAGINATION_STATE.FIRST_PAGE_NUMBER
    : initialPagination.pageIndex;

  const initialGlobalFilter = searchParams?.get(GlobalSearchParams.Search) || "";

  const [globalFilter, setGlobalFilter] = useState<string>(initialGlobalFilter);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(initialColumnFilters);
  const [sorting, setSorting] = useState<MRT_SortingState>(initialSorting);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageSize: initialPageSize,
    pageIndex: initialPageIndex
  });

  // Function to update the URL parameters
  const updateUrlParams = (paginationParams: MRT_PaginationState, globalFilter: string) => {
    searchParams.set(GlobalSearchParams.PageSize, paginationParams.pageSize.toString());
    searchParams.set(GlobalSearchParams.PageIndex, (paginationParams.pageIndex + 1).toString());
    if (!globalFilter) {
      searchParams.delete(GlobalSearchParams.Search);
    } else {
      searchParams.set(GlobalSearchParams.Search, globalFilter);
    }
    setSearchParams(searchParams);
  };

  // Effect to update URL when state changes
  useEffect(() => {
    updateUrlParams(pagination, globalFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, globalFilter, sorting.length, columnFilters.length]);

  const dataTableQuery = useQuery<PaginatedDataTable<T>, Error>(
    [
      queryKey,
      columnFilters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      globalFilter
    ],
    async () => {
      const queryParams: PaginatedQueryParams = {
        pageNumber:
          pagination.pageIndex + PAGINATION_STATE.FIRST_PAGE_NUMBER ||
          PAGINATION_STATE.FIRST_PAGE_NUMBER,
        pageSize: pagination.pageSize,
        ...params
      };

      if (sorting.length) {
        queryParams.sorts = DataTableUtils.mapSorting(sorting);
      }

      if (globalFilter && globalFilter.length) {
        queryParams.search = globalFilter ?? "";
      }

      if (columnFilters) {
        columnFilters.forEach(column => {
          queryParams[column.id] = column.value;
        });
      }

      const queryString = DataTableUtils.mapQueryParamsToString(queryParams);

      return await queryFn(queryString);
    },
    {
      keepPreviousData: true
    }
  );

  return {
    ...dataTableQuery,
    data: dataTableQuery.data?.data ?? [],
    rowCount: dataTableQuery.data?.pagination.total ?? 0,
    pagination,
    setColumnFilters,
    setPagination,
    globalFilter,
    setGlobalFilter,
    setSorting,
    sorting,
    columnFilters,
    updateUrlParams,
    searchParams,
    setSearchParams,
    ...restSearchParams
  };
}
