function AppointmentIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path className="sidebar-icon" d="M14.063 22.323V20.8h5.418V9.875H4.291v5.59H2.772V5.047c0-.406.152-.762.456-1.066a1.455 1.455 0 0 1 1.063-.458h1.646V2h1.645v1.524h8.608V2h1.645v1.524h1.646c.405 0 .76.153 1.063.458.304.304.456.66.456 1.066V20.8c0 .407-.152.762-.456 1.067a1.455 1.455 0 0 1-1.063.457h-5.418zM7.835 24l-1.063-1.067 2.86-2.896H1v-1.524h8.633l-2.86-2.896 1.062-1.067 4.71 4.725L7.834 24z" fill="#FFF" fillRule="nonzero"/>
      </g>
    </svg>

  );
}

export default AppointmentIcon;