import { useMutation } from "@tanstack/react-query";
import { AdjustersManagementService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, InsuranceCompanyForCreateUpdateDto } from "types";
import { useNavigate } from "react-router-dom";
export const useCreateAdjusterMutation = () => {
  const navigate = useNavigate();
  const createAdjustersMutation = useMutation({
    mutationKey: [AdjustersManagementService.create.mutationKey],
    mutationFn: (adjuster: InsuranceCompanyForCreateUpdateDto) =>
      AdjustersManagementService.create.request(adjuster),
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Adjuster created successfully!");

      navigate(-1);
    }
  });

  const onCreateAdjusters = (adjuster: InsuranceCompanyForCreateUpdateDto) => {
    if (!adjuster) return;
    createAdjustersMutation.mutate(adjuster);
  };

  return {
    onCreateAdjusters,
    ...createAdjustersMutation
  };
};
