import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";

export const useSpecialitiesLookup = () => {
  const specialitiesLookupQuery = useQuery({
    queryKey: [LookupService.specialities.queryKey],
    queryFn: LookupService.specialities.request
  });

  const getSpecialityById = (specialityId?: string) => {
    if(!specialityId) return "";
    const specialities = specialitiesLookupQuery.data;
    return specialities?.find(speciality => speciality.id === specialityId)?.description ?? "";
  };
  
  return {
    ...specialitiesLookupQuery,
    getSpecialityById
  };
};