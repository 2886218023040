import "./LogoSection.scss";
import { Link } from "react-router-dom";
import { Box, ButtonBase } from "@mui/material";
import { AppRoutes } from "router/AppRoutes";
import { MenuOutlined } from "components/logo";
import { useTheme } from "@mui/material/styles";
import { APP_CONFIGURATIONS } from "config";

function LogoSectionBrowser() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: 260,
        display: "flex",
        [theme.breakpoints.down("md")]: {
          width: "auto"
        }
      }}
    >
      <Box component="span" sx={{
        display: {
          xs: "none",
          md: "block"
        },
        flexGrow: 1
      }}>
        <ButtonBase disableRipple component={Link} to={AppRoutes.app}>
          <img className="logo" src={APP_CONFIGURATIONS.REACT_APP_TENANT_LOGO_URL} alt="logo"/>
        </ButtonBase>
      </Box>
      <MenuOutlined/>
    </Box>
  );
}

export default LogoSectionBrowser;