import { InjuryTypes, Option } from "types";

const INJURY_TYPE: Option[] = [
  {
    id: InjuryTypes.CT,
    label: "CT"
  },
  {
    id: InjuryTypes.Specific,
    label: "Specific"
  }
];

export default INJURY_TYPE;