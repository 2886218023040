import { Grid, IconButtonProps, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Check, Delete } from "@mui/icons-material";
import React from "react";
import { PrimeActionIconButton } from "components/common/buttons";

type FormCardActionsProps = {
  onDelete(): void;
  onClose?(): void;
  title?: string;
  buttonProps?: IconButtonProps
}
function FormCardActions({ onDelete, onClose }: FormCardActionsProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid xs={12} md={1} item>
      <Stack gap={1} direction="row" alignItems="center" justifyContent={isSmallScreen ? "end" : "flex-start"} mt={isSmallScreen ? 0 : 1}>
        <PrimeActionIconButton onClick={onDelete}>
          <Delete sx={{ "&:hover": { color: "red" } }}/>
        </PrimeActionIconButton>
        {!onClose ? null : (
          <PrimeActionIconButton onClick={onClose}>
            <Check color="success" sx={{ "&:hover": { color: "green" } }}/>
          </PrimeActionIconButton>
        )}
      </Stack>
    </Grid>
  );
}

export default FormCardActions;