import { useMutation } from "@tanstack/react-query";
import { PatientService } from "api";
import { ErrorsUtils, ObjectUtils, SnackbarUtils } from "utils";
import { ApiError, ApiVersions } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { PatientFileForCreateUpdateDto } from "types/api-v2";

export const useUpdatePatientMutation = () => {
  const navigate = useNavigate();
  const { patientId } = useParams<{ patientId: string }>();
  const updatePatientMutation = useMutation({
    mutationKey: [PatientService.update.queryKey],
    mutationFn: (patient: PatientFileForCreateUpdateDto) => PatientService.update.request(patientId!, patient, ApiVersions.V2),
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Patient updated successfully!");
      navigate(-1);
    }
  });
  
  const onUpdatePatient = (patient: PatientFileForCreateUpdateDto) => {
    if(!patient) return;
    updatePatientMutation.mutate({
      ...patient,
      injuries: patient.injuries.map((i) => ObjectUtils.replaceEmptyStringsWithNull(i))
    });
  };
  
  return {
    onUpdatePatient,
    ...updatePatientMutation
  };
};