import { useMutation } from "@tanstack/react-query";
import { AppointmentService } from "api";
import {
  ApiError,
  InterpreterInformation
} from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
type InterpreterInformationAppointmentPayload = { appointmentId: string; interpreterInformation: InterpreterInformation }
export const useSetInterpreterInformationMutation = () => {
  const setInterpreterInformationMutation = useMutation({
    mutationKey: [AppointmentService.setInterpreterInformation.queryKey],
    mutationFn: ({ appointmentId, interpreterInformation }: InterpreterInformationAppointmentPayload) =>
      AppointmentService.setInterpreterInformation.request(appointmentId, interpreterInformation),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Interpreter Information set successfully!");
    }
  });

  const onSetInterpreterInformation = async (interpreterInformationAppointmentPayload: InterpreterInformationAppointmentPayload) => {
    if(!interpreterInformationAppointmentPayload) return;

    return await setInterpreterInformationMutation.mutateAsync(interpreterInformationAppointmentPayload);
  };

  return {
    ...setInterpreterInformationMutation,
    onSetInterpreterInformation
  };
};
