import { MenuItem } from "types";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { AppRoutes } from "router/AppRoutes";
import {
  DoctorsIcon, PatientIcon,
  ManagementIcon, UsersIcon,
  AppointmentIcon, ActiveReportIcon,
  ArchivedReportIcon,
  FinancialIcon
} from "components/icons";
import { AppPermissions } from "app-constants";

const items: MenuItem[] = [
  {
    label: "Dashboard",
    icon: <DashboardIcon/>,
    to: AppRoutes.app,
    permissions: [AppPermissions.DoctorManagement.Actions.View]
  },
  {
    label: "Doctors",
    icon: <DoctorsIcon/>,
    to: `/${AppRoutes.doctors.root}`,
    permissions: [AppPermissions.DoctorManagement.Actions.View]
  },
  {
    label: "Users",
    icon: <UsersIcon/>,
    to: `/${AppRoutes.users.root}`,
    permissions: [AppPermissions.UserManagement.Actions.View]
  },
  {
    label: "Patients",
    icon: <PatientIcon/>,
    to: `/${AppRoutes.patients.root}`,
    permissions: [AppPermissions.PatientFile.Actions.View]
  },
  {
    label: "Appointments",
    icon: <AppointmentIcon/>,
    to: `/${AppRoutes.appointments.root}`,
    permissions: [AppPermissions.Appointments.Actions.View]
  },
  {
    label: "Active Reports",
    icon: <ActiveReportIcon/>,
    to: `/${AppRoutes.activeReports.root}`,
    permissions: [AppPermissions.ActiveReports.Actions.View]
  },
  {
    label: "Archived Reports",
    icon: <ArchivedReportIcon/>,
    to: `/${AppRoutes.archivedReports.root}`,
    permissions: [AppPermissions.ArchiveReportPermissions.Actions.View]
  },
  {
    label: "Financials",
    icon: <FinancialIcon/>,
    to: `/${AppRoutes.financials.root}`,
    permissions: [AppPermissions.BillingManagement.Actions.View],
    children: [
      {
        label: "Financial Details",
        to: `/${AppRoutes.financials.root}/${AppRoutes.financials.details}`,
        permissions: [AppPermissions.BillingManagement.Actions.View]
      },
      {
        label: "Financial Reports",
        to: `/${AppRoutes.financials.root}/${AppRoutes.financials.reports}`,
        permissions: [AppPermissions.BillingManagement.Actions.View]
      },
      {
        label: "Payments Record",
        to: `/${AppRoutes.financials.root}/${AppRoutes.financials.payments.root}`,
        permissions: [AppPermissions.BillingManagement.Actions.View]
      },
      {
        label: "Duplicated Payments",
        to: `/${AppRoutes.financials.root}/${AppRoutes.financials.duplicatePayments.root}`,
        permissions: [AppPermissions.BillingManagement.Actions.View]
      }
    ]
  },
  {
    label: "Management",
    icon: <ManagementIcon/>,
    to: `/${AppRoutes.management.root}`,
    permissions: [AppPermissions.LocationsManagement.Actions.View, AppPermissions.RoleManagement.Actions.View],
    children: [
      {
        label: "Locations",
        to: `/${AppRoutes.management.root}/${AppRoutes.locations.root}`,
        permissions: [AppPermissions.LocationsManagement.Actions.View]
      },
      {
        label: "Adjusters",
        to: `/${AppRoutes.management.root}/${AppRoutes.adjusters.root}`,
        permissions: [AppPermissions.LocationsManagement.Actions.View]
      },
      {
        label: "Attorneys",
        to: `/${AppRoutes.management.root}/${AppRoutes.attorneys.root}`,
        permissions: [AppPermissions.LocationsManagement.Actions.View]
      },
      {
        label: "Roles & Permissions",
        to: `/${AppRoutes.management.root}/${AppRoutes.roles.root}`,
        permissions: [AppPermissions.RoleManagement.Actions.View]
      }
    ]
  }
];

export default items;