import { ThemeOption } from "types";
import { PaletteOptions } from "@mui/material/styles";

export default function themePalette(theme: ThemeOption): PaletteOptions {
  return {
    mode: "light",
    primary: {
      light: theme.primaryBlue100,
      main: theme.primaryBlue,
      dark: theme.primaryBlue500,
      200: theme.primaryBlue200
    },
    secondary: {
      light: theme.primaryGreen100,
      main: theme.primaryGreen,
      dark: theme.primaryGreen500,
      200: theme.primaryGreen200
    },
    error: {
      main: theme.statusDanger
    },
    warning: {
      main: theme.statusWarning
    },
    success: {
      main: theme.statusSuccess
    },
    grey: {
      50: theme.primaryGray,
      100: theme.primaryGray100,
      200: theme.primaryGray200,
      300: theme.primaryGray300,
      400: theme.primaryGray400,
      500: theme.primaryGray500
    },
    background: {
      paper: theme.backgroundColor,
      default: theme.backgroundColor
    }
  };
}
