import { createTheme, ThemeOptions } from "@mui/material/styles";
import colors from "assets/scss/abstracts/_variables.scss";
import componentStyleOverrides from "themes/ComponentStyleOverrides";
import themePalette from "./Palette";
import themeTypography from "./Typography";
import { ThemeOption } from "types";
import { TypographyOptions } from "@mui/material/styles/createTypography";

export const theme = () => {
  const themeOption: ThemeOption = {
    ...colors
  };
  
  const themeOptions: ThemeOptions = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px"
        }
      }
    },
    typography: themeTypography(themeOption) as TypographyOptions
  };
  
  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);
  
  return themes;
};

export default theme;
