import { useInfiniteQuery } from "@tanstack/react-query";
import { LookupService } from "api";

export const useAppointmentsForDuplicatePaymentDistributionLookupQuery = (search?: string) => {
  const query = useInfiniteQuery(
    [LookupService.appointmentsForPaymentDistribution.queryKey, search],
    ({ pageParam = 1, meta }) => {
      console.info(meta);
      const queryParam = new URLSearchParams();
      queryParam.append("searchTerm", search ?? "");
      queryParam.append("pageNumber", (+pageParam).toString());
      queryParam.append("pageSize", "10");
      return LookupService.appointmentsForDuplicatePaymentDistribution.request(queryParam.toString());
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) return undefined;
        return pages.length + 1;
      }
    }
  );

  return {
    ...query
  };
};
