export const AppRoutes = {
  app: "/",
  login: "login",
  notFound: "not-found",
  forbidden: "forbidden",
  users: {
    root: "users",
    create: "create",
    update: "update",
    profile: "profile"
  },
  appointments: {
    root: "appointments",
    create: "create",
    update: "update",
    profile: "profile"
  },
  reports: {
    root: "reports"
  },
  settings: {
    root: "settings"
  },
  financials: {
    root: "financials",
    details: "details",
    reports: "reports",
    payments: {
      root: "payments",
      create: "create",
      update: "update",
      profile: "profile"
    },
    duplicatePayments: {
      root: "duplicate-payments",
      create: "duplicate-create",
      update: "duplicate-update",
      profile: "duplicate-profile"
    }
  },
  management: {
    root: "management"
  },
  doctors: {
    root: "doctors",
    create: "create",
    profile: "profile",
    update: "update"
  },
  patients: {
    root: "patients",
    create: "create",
    profile: "profile",
    update: "update"
  },
  roles: {
    root: "roles-management",
    create: "create",
    update: "update"
  },
  adjusters: {
    root: "adjuster-management",
    create: "create",
    update: "update",
    profile: "profile",
    list: "list"
  },
  attorneys: {
    root: "attorney-management",
    create: "create",
    update: "update",
    profile: "profile",
    list: "list"
  },
  locations: {
    root: "locations",
    create: "create",
    update: "update",
    profile: "profile"
  },
  folders: {
    root: "folders",
    preEvaluationFolder: "pre-evaluation",
    postEvaluationFolder: "post-evaluation",
    notices: "notices"
  },
  activeReports: {
    root: "active-reports",
    create: "create",
    update: "update",
    profile: "profile"
  },
  archivedReports: {
    root: "archived-reports",
    profile: "profile"
  },
  privacyPolicy: {
    root: "privacy-policy"
  }
} as const;
