const INTERPRETER_LANGUAGES = [
  { id: "1", name: "English" },
  { id: "2", name: "Spanish" },
  { id: "3", name: "Mandarin" },
  { id: "4", name: "Arabic" },
  { id: "5", name: "Hindi" },
  { id: "6", name: "French" },
  { id: "7", name: "Russian" },
  { id: "8", name: "Bengali" },
  { id: "9", name: "Portuguese" },
  { id: "10", name: "Urdu" },
  { id: "11", name: "Indonesian" },
  { id: "12", name: "German" },
  { id: "13", name: "Japanese" },
  { id: "14", name: "Turkish" },
  { id: "15", name: "Persian" },
  { id: "16", name: "Malay" },
  { id: "17", name: "Italian" },
  { id: "18", name: "Tamil" },
  { id: "19", name: "Korean" },
  { id: "20", name: "Vietnamese" },
  { id: "21", name: "Telugu" },
  { id: "22", name: "Marathi" }
];

const LANGUAGES = Object.freeze({
  INTERPRETER_LANGUAGES
});

export default LANGUAGES;