import { UseFormReturn } from "react-hook-form";
import { DATE_TIME_FORMAT, UserRoles } from "app-constants";
import { useEffect } from "react";
import {
  ApiError,
  AppointmentForReadDto,
  DoctorAssignedToPatienForAppointmentCreateUpdateDto,
  EAppointmentType,
  RescheduleCreateFormContext
} from "types";
import { Moment } from "moment/moment";
import moment from "moment";
import { AppointmentUtils, DateUtils, ErrorsUtils } from "utils";
import { useUsersLookupQuery, useDoctorAssignedToPatientFileMutation } from "hooks";

export const useRescheduleAppointmentForm = (formContext: UseFormReturn<RescheduleCreateFormContext>, appointment: AppointmentForReadDto) => {
  const { data: doctorsLookup } = useUsersLookupQuery(UserRoles.Doctor);
  const selectedDate = formContext.watch("scheduleDetail.date");
  const type = appointment?.type;

  const { data: doctorAssignedToPatientFile, onFetchDoctorAssignedToPatientFile } = useDoctorAssignedToPatientFileMutation();
  const availabilitySlots = doctorAssignedToPatientFile?.availabilitySlots;

  const onChangePatientProfile = async (patientFileId: string) => {
    try {
      await onFetchDoctorAssignedToPatientFile(patientFileId);
      fillNonEditableFields();
    } catch(e) {
      ErrorsUtils.renderApiErrors(e as ApiError);
    }
  };
  const setCalculateDurationToTime = (value: Moment) => {
    const periodInMinutes = getAppointmentInfo(doctorAssignedToPatientFile).period;
    if (periodInMinutes !== undefined && value.isValid()) {
      const newTime = value.clone().add(periodInMinutes, "minutes");
      const newTimeFormatted = newTime.format(DATE_TIME_FORMAT.FULL_TIME);
      formContext.setValue("scheduleDetail.duration.endTime", newTimeFormatted);
    }
  };

  useEffect(() => {
    if(type && doctorAssignedToPatientFile) {
      const startTime = moment(`1970-01-01T${formContext.getValues("scheduleDetail.duration.startTime")}`);
      setCalculateDurationToTime(startTime);
    }
    // eslint-disable-next-line
  }, [type, doctorAssignedToPatientFile, formContext]);

  useEffect(() => {
    if(appointment.patientFileId) {
      onChangePatientProfile(appointment.patientFileId);
    }
    // eslint-disable-next-line
  }, [appointment.patientFileId]);

  const getAppointmentInfo = (doctorAssigned?: DoctorAssignedToPatienForAppointmentCreateUpdateDto) => {
    if(!doctorAssigned) {
      return {
        instructions: "",
        period: 0
      };
    }
    const type = appointment?.type;
    if(type === EAppointmentType.Deposition) {
      return {
        instructions: doctorAssigned.depositionAppointmentInfo?.instructions,
        period: doctorAssigned.depositionAppointmentInfo?.period
      };
    }
    if(type === EAppointmentType.ReEvaluation) {
      return {
        instructions: doctorAssigned.reEvaluationAppointmentInfo?.instructions,
        period: doctorAssigned.reEvaluationAppointmentInfo?.period
      };
    }

    return {
      instructions: doctorAssigned.initialEvaluationAppointmentInfo?.instructions,
      period: doctorAssigned.initialEvaluationAppointmentInfo?.period
    };
  };

  const fillNonEditableFields = () => {
    formContext.setValue("scheduleDetail", {
      date: "",
      duration: {
        startTime: "",
        endTime: ""
      }
    });
  };

  const onDisabledRangeTimeDuration = (value: Moment) => {
    const selectedTime = value.format(DATE_TIME_FORMAT.FULL_TIME);
    const timeRange = appointment?.doctorAvailabilityTimeRange;
    const start = DateUtils.formatToFullTime(timeRange?.startTime ?? "");
    const end = DateUtils.formatToFullTime(timeRange?.endTime ?? "");
    const isSameOrAfter = DateUtils.formatToFullTime(selectedTime).isSameOrAfter(start);
    const isBefore = DateUtils.formatToFullTime(selectedTime).isBefore(end);
    return !(isSameOrAfter && isBefore);
  };

  const onChangeAppointmentTime = (value: string) => {
    if(!value) {
      formContext.setValue("doctorAvailabilityTimeRange.startTime", "");
      formContext.setValue("doctorAvailabilityTimeRange.endTime", "");
      formContext.setValue("scheduleDetail.duration.startTime", "");
      formContext.setValue("scheduleDetail.duration.endTime", "");
      return;
    }
    const timeRange = AppointmentUtils.getSelectedTimeRange(value);
    formContext.setValue("doctorAvailabilityTimeRange.startTime", timeRange.startTime);
    formContext.setValue("doctorAvailabilityTimeRange.endTime", timeRange.endTime);
  };

  const onChangeScheduleDetailDurationStartTime = (value: Moment | null) => {
    if(!value) return;
    setCalculateDurationToTime(value);
  };

  const onChangeScheduleDetailDate = () => {
    formContext.setValue("scheduleDetail.duration", {
      startTime: "",
      endTime: ""
    });
  };

  const shouldDisabledScheduleDetailDate = (date: Moment) => {
    const currentDay = date.format(DATE_TIME_FORMAT.DATE);
    return !availabilitySlots?.[currentDay];
  };

  const formattedSelectedDate = moment(selectedDate)?.format(DATE_TIME_FORMAT.DATE);

  const appointmentTimeOptions = availabilitySlots?.[formattedSelectedDate]?.map((availabilitySlot) => {
    return {
      id: `${formattedSelectedDate}_${availabilitySlot.startTime}_${availabilitySlot.endTime}`,
      label: `${DateUtils.formatToTime(availabilitySlot.startTime)} - ${DateUtils.formatToTime(availabilitySlot.endTime)}`
    };
  }) ?? [];

  const getMinMaxTime = () => {
    if(!formContext.watch("appointmentTime") || formContext.watch("id")) return;

    const selectedTimeRange = AppointmentUtils.getSelectedTimeRange(formContext.watch("appointmentTime") ?? "");

    const minTime = moment(selectedTimeRange.startTime, DATE_TIME_FORMAT.FULL_TIME);
    const maxTime = moment(selectedTimeRange.endTime, DATE_TIME_FORMAT.FULL_TIME);

    return {
      minTime,
      maxTime
    };
  };
  return {
    appointmentTimeOptions,
    shouldDisabledScheduleDetailDate,
    onChangeScheduleDetailDurationStartTime,
    onChangeScheduleDetailDate,
    onChangeAppointmentTime,
    onDisabledRangeTimeDuration,
    onChangePatientProfile,
    doctorsLookup,
    minTime: getMinMaxTime()?.minTime,
    maxTime: getMinMaxTime()?.maxTime
  };
};
