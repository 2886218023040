import { ApiURLs, httpClient } from "api/core";
import { PaginatedDataTable, AttorneyForListDto, AttorneyForCreateUpdateDto, AttorneyForReadDto } from "types";

const URL = ApiURLs.attorneyManagement;

async function getAttorneys(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<AttorneyForListDto>>(
    `${URL}?${queryParams}`
  );
}

async function createAttorney(data: AttorneyForCreateUpdateDto) {
  return await httpClient.post<{ attorneyId: string }>(URL, data);
}

async function getAttorneyDetails(attorneyId: string) {
  return await httpClient.get<AttorneyForReadDto>(`${URL}/${attorneyId}`);
}

async function updateAttorney(attorneyId: string, data: AttorneyForCreateUpdateDto) {
  return await httpClient.put<{ attorneyId: string }>(`${URL}/${attorneyId}`, data);
}

async function deleteAttorney(attorneyId: string) {
  return await httpClient.delete(`${URL}/${attorneyId}`);
}

export const AttorneyManagementService = {
  list: {
    request: getAttorneys,
    queryKey: "attorney-list"
  },
  create: {
    request: createAttorney,
    mutationKey: "create-attorney"
  },
  details: {
    request: getAttorneyDetails,
    queryKey: "attorney-details"
  },
  update: {
    request: updateAttorney,
    mutationKey: "update-attorney"
  },
  delete: {
    request: deleteAttorney,
    mutationKey: "delete-attorney"
  }
};
