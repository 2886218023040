import { makeAutoObservable } from "mobx";
import { ActiveReportForReadDto } from "types";
export default class ActiveReportStore {
  activeReport: ActiveReportForReadDto | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setActiveReport = (activeReport: ActiveReportForReadDto) => {
    this.activeReport = activeReport;
  };

  clearActiveReport = () => {
    this.activeReport = null;
  };
}