import { DocumentForReadDto, FileStatus } from "types";
import {
  useDeleteDocumentMutation,
  useDownloadDocumentMutation,
  useFoldersQuery, useGetDocumentURLQuery,
  useIsExpectedMutation,
  useUploadDocumentMutation
} from "hooks";

export const useFolderManagement = <T>(URL: string, entityId: string) => {
  const { data: folders, isLoading, onFetchFolders, ...restQueryFolder } = useFoldersQuery<T>(URL, entityId);
  const { deleteDocumentHandler, isLoading: isDeleteLoading } = useDeleteDocumentMutation(URL, entityId);
  const { setFileStatus } = useIsExpectedMutation(URL, entityId);
  const { downloadDocumentHandler } = useDownloadDocumentMutation(URL, entityId);
  const { onUploadFiles } = useUploadDocumentMutation(URL, entityId);
  const { getDocumentHandler, isLoading: isGettingDocumentURLLoading } = useGetDocumentURLQuery(URL, entityId);
  const onUploadFilesHandler = async (folderId: string, files: File[]) => {
    try {
      await onUploadFiles(folderId, files);
      onFetchFolders();
    } catch(error) {
      console.error(error);
    }
  };

  const onDeleteDocument = (folderId: string, document: DocumentForReadDto) => {
    deleteDocumentHandler(document.id, folderId, document.name ?? "", () => {
      onFetchFolders();
    });
  };

  const onDownloadDocument = (folderId: string, document: DocumentForReadDto) => {
    downloadDocumentHandler(document.id, folderId, document.name!);
  };

  const onGetDocumentURL = (folderId: string, document: DocumentForReadDto) => {
    getDocumentHandler(folderId, document.id);
  };

  const onEditFileStatus = (status: FileStatus, folderId: string | null) => {
    if(!folderId) {
      console.error("The folder Id is required!");
      return;
    }

    if(status === FileStatus.Pending) {
      return setFileStatus(true, folderId).then(() => {
        onFetchFolders();
      }).catch(console.error);
    }

    return setFileStatus(false, folderId).then(() => {
      onFetchFolders();
    }).catch(console.error);
  };

  return {
    onDownloadDocument,
    onDeleteDocument,
    isLoading,
    isDeleteLoading,
    onUploadFilesHandler,
    folders,
    onEditFileStatus,
    onFetchFolders,
    isGettingDocumentURLLoading,
    onGetDocumentURL,
    ...restQueryFolder
  };
};