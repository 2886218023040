import React, { Fragment } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { MRT_Row } from "material-react-table";
import { TableRowActions } from "types";
import { AuthorizationWrapper } from "components/permissions";

type RowActionsProps<T extends Record<string, any>> = {
  row: MRT_Row<T>;
  rowActions: TableRowActions<T>;
}

export default function RowActions<T extends Record<string, any> = {}>({
  rowActions,
  row
}: RowActionsProps<T>) {
  return (
    <Box className="table-row-actions" sx={{
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      justifyContent: "flex-end"
    }}>
      {rowActions.map(({ id, Icon, onClick, tooltipProps, buttonProps, hidden, permissions }) => {
        if(hidden && hidden(row)) return null;
        return (
          <AuthorizationWrapper key={id} permissions={permissions}>
            <Fragment>
              <Tooltip title={tooltipProps(row).title} arrow placement="bottom">
                <span>
                  <IconButton
                    {...(buttonProps && buttonProps(row))}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      onClick(row);
                    }}
                  >
                    {Icon(row)}
                  </IconButton>
                </span>
              </Tooltip>
            </Fragment>
          </AuthorizationWrapper>
        
        );
      })}
    </Box>
  );
}