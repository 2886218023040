import { FieldErrors, UseFormReturn } from "react-hook-form";
import { AvailabilitySlotType, DoctorForCreateUpdateFormContext } from "types";

export const useDetermineSlotType = () => {
  const determineSlotType = (
    errors: FieldErrors<DoctorForCreateUpdateFormContext>
  ): AvailabilitySlotType => {
    if (errors.availabilitySlots?.weekDays) {
      return "WeekDays";
    } else if (errors.availabilitySlots?.specificDates) {
      return "SpecificDate";
    }

    return "DateRange";
  };

  const onErrorHandler = (
    errors: FieldErrors<DoctorForCreateUpdateFormContext>,
    formContext: UseFormReturn<DoctorForCreateUpdateFormContext>
  ) => {
    const slotType = determineSlotType(errors);
    formContext.setValue("slotType", slotType);
  };

  return {
    onErrorHandler,
    determineSlotType
  };
};
