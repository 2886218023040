import { ApiURLs, httpClient } from "api/core";
import { PaginatedDataTable, InsuranceCompanyForListDto, InsuranceCompanyForCreateUpdateDto, InsuranceCompanyForReadDto } from "types";

const URL = ApiURLs.adjustersManagement;

async function getAdjusters(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<InsuranceCompanyForListDto>>(
    `${URL}?${queryParams}`
  );
}

async function createAdjuster(data: InsuranceCompanyForCreateUpdateDto) {
  return await httpClient.post<{ insuranceCompanyId: string }>(URL, data);
}

async function getAdjusterDetails(adjusterId: string) {
  return await httpClient.get<InsuranceCompanyForReadDto>(`${URL}/${adjusterId}`);
}

async function updateAdjuster(adjusterId: string, data: InsuranceCompanyForCreateUpdateDto) {
  return await httpClient.put<{ insuranceCompanyId: string }>(
    `${URL}/${adjusterId}`,
    data
  );
}

async function deleteAdjuster(adjusterId: string) {
  return await httpClient.delete(`${URL}/${adjusterId}`);
}

export const AdjustersManagementService = {
  list: {
    request: getAdjusters,
    queryKey: "adjusters-list"
  },
  create: {
    request: createAdjuster,
    mutationKey: "create-adjuster"
  },
  details: {
    request: getAdjusterDetails,
    queryKey: "adjuster-details"
  },
  update: {
    request: updateAdjuster,
    mutationKey: "update-adjuster"
  },
  delete: {
    request: deleteAdjuster,
    mutationKey: "delete-adjuster"
  }
};
