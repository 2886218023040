function ArchivedReportIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path className="sidebar-icon" d="m12 17.25 3.9-3.9-1-1-2.15 2.15V9.475h-1.5V14.5L9.1 12.35l-1 1 3.9 3.9zM4.5 21c-.412 0-.766-.147-1.06-.44A1.444 1.444 0 0 1 3 19.5V6.275c0-.165.025-.324.075-.476a1.4 1.4 0 0 1 .225-.424L4.6 3.6c.133-.183.308-.33.524-.438.215-.108.44-.162.676-.162h12.375c.235 0 .46.054.676.163.216.108.39.254.524.437L20.7 5.375c.1.13.175.27.225.424.05.152.075.311.075.476V19.5c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-15zm.425-15.35H19.05l-.91-1.15H5.825l-.9 1.15z" fill="#FFF" fillRule="nonzero"/>
      </g>
    </svg>
  );
}

export default ArchivedReportIcon;