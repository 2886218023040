import "./BackdropLoader.scss";
import { Alert, Snackbar } from "@mui/material";
import { useStore } from "stores";
import { observer } from "mobx-react-lite";
import LinearProgress from "@mui/material/LinearProgress";

function DownloadLoader() {
  const { documentsStore } = useStore();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={documentsStore.isDownloading}
      onClose={() => {}}

    >
      <Alert severity="info" sx={{ width: "100%" }}>
        Downloading
        <LinearProgress variant="indeterminate" />
      </Alert>
    </Snackbar>
  );
}
export default observer(DownloadLoader);
