import { msalInstance, ServerAPIRequest } from "config";

export const getAccessToken = async (): Promise<string | null> => {
  try {
    return await acquireAccessToken();
  } catch (error) {
    console.error(error);
    return null;
  }
};

const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
    * User is not signed in. Throw error or wait for user to login.
    * Do not attempt to log a user in outside of the context of MsalProvider
    */
  }
  const request = {
    ...ServerAPIRequest,
    account: activeAccount || accounts[0]
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
};