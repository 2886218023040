import { AppRoutes } from "router/AppRoutes";
import { APP_CONFIGURATIONS } from "config";
const APP_FEATURES = Object.freeze<{ [key: string]: boolean }>({
  [`/${AppRoutes.doctors.root}`]: true,
  [`/${AppRoutes.users.root}`]: true,
  [`/${AppRoutes.management.root}`]: true,
  [`/${AppRoutes.management.root}/${AppRoutes.locations.root}`]: true,
  [`/${AppRoutes.management.root}/${AppRoutes.roles.root}`]: true,
  [`/${AppRoutes.appointments.root}`]: true,
  [`/${AppRoutes.patients.root}`]: true,
  [`/${AppRoutes.activeReports.root}`]: true,
  [`/${AppRoutes.archivedReports.root}`]: true,
  [`/${AppRoutes.financials.root}`]: APP_CONFIGURATIONS.REACT_APP_ENVIRONMENT === "Development"
});

export default APP_FEATURES;