import { UsersManagementService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";
import { EUserStatus, UserForListDto } from "types";

export const useUserManagementList = () => {
  const paginatedDataTableQuery = usePaginatedDataTableQuery<UserForListDto>({
    queryFn: UsersManagementService.list.request,
    queryKey: UsersManagementService.list.queryKey,
    initialColumnFilters: [{ id: "userStatusFilters", value: [EUserStatus.Enabled] }]
  });
  
  const onFetchData = async () => {
    await paginatedDataTableQuery.refetch();
  };
  
  return {
    onFetchData,
    ...paginatedDataTableQuery
  };
};