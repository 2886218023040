import "./BackdropLoader.scss";
import { Backdrop, Stack, Typography } from "@mui/material";
import { HeartRateImage } from "components/app-loader";

function BackdropLoader() {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Stack alignItems="center" justifyContent="center" spacing={2} sx={{ height: "80vh" }} direction="column">
        <HeartRateImage/>
        <Typography variant="body2">Please Wait ...</Typography>
      </Stack>
    </Backdrop>
  );
}

export default BackdropLoader;