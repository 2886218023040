import { BillingManagementService } from "api";
import { usePaginatedDataTableQuery } from "hooks";
import { EDuplicatePaymentTransactionPaidStatus } from "types";

export const useAppointmentFinancesListQuery = () => {
  const query = usePaginatedDataTableQuery({
    queryFn: BillingManagementService.appointmentFinances.request,
    queryKey: BillingManagementService.appointmentFinances.queryKey,
    initialColumnFilters: [
      {
        id: "statusFilter",
        value: [
          EDuplicatePaymentTransactionPaidStatus.NotPaid,
          EDuplicatePaymentTransactionPaidStatus.PartiallyPaid
        ]
      }
    ]
  });

  const onFetchData = async () => {
    await query.refetch();
  };

  return {
    onFetchData,
    ...query
  };
};
