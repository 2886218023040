import React, { JSX, PropsWithChildren } from "react";
import { CardHeader, CardContent, Box, Card, CardProps } from "@mui/material";

type PrimaryCardProps = PropsWithChildren & {
  title?: string;
  removeCardContentPadding?: boolean;
  cardProps?: CardProps,
  cardHeader?: JSX.Element;
}

const PrimaryCard = ({ children, removeCardContentPadding = false, ...rest }: PrimaryCardProps) => {
  const renderCardHeader = !rest.cardHeader ? <CardHeader sx={{ p: "18px" }} title={rest.title}/> : rest.cardHeader;
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined" {...rest.cardProps}>
        {renderCardHeader}
        <CardContent sx={removeCardContentPadding ? { padding: "0px !important" } : {}}>
          {children}
        </CardContent>
      </Card>
    </Box>
  );
};

export default PrimaryCard;