import { DocumentsService } from "api";
import { CompleteMultipartUploadDto } from "types";
import { useMutation } from "@tanstack/react-query";

type DocumentMultipartCompleteMutationPayload = {
  URL: string;
  entityId: string;
  folderId: string;
  data: CompleteMultipartUploadDto;
};
export const useCompleteDocumentMultipartMutation = () => {
  const completeDocumentMultipartMutation = useMutation({
    mutationKey: [DocumentsService.completeMultiPartUpload.queryKey],
    mutationFn: (payload: DocumentMultipartCompleteMutationPayload) =>
      DocumentsService.completeMultiPartUpload.request(
        payload.URL,
        payload.entityId,
        payload.folderId,
        payload.data
      )
  });

  const completeMultipartDocumentUpload = (payload: DocumentMultipartCompleteMutationPayload) => {
    return completeDocumentMultipartMutation.mutateAsync(payload);
  };

  return {
    ...completeDocumentMultipartMutation,
    completeMultipartDocumentUpload
  };
};
