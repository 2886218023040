import { ArchivedReportService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";
import { ArchivedReportForListDto } from "types";

export const useArchivedReportsListQuery = () => {
  const archivedReportsListQuery = usePaginatedDataTableQuery<ArchivedReportForListDto>({
    queryFn: ArchivedReportService.list.request,
    queryKey: ArchivedReportService.list.queryKey
  });
  
  const onFetchData = async () => {
    await archivedReportsListQuery.refetch();
  };
  
  return {
    onFetchData,
    ...archivedReportsListQuery
  };
};