import { DoctorManagementService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";
import { DoctorForListDto, EUserStatus } from "types";

export const useDoctorManagementList = () => {
  const paginatedDataTableQuery = usePaginatedDataTableQuery<DoctorForListDto>({
    queryFn: DoctorManagementService.list.request,
    queryKey: DoctorManagementService.list.queryKey,
    initialColumnFilters: [{ id: "doctorStatusFilters", value: [EUserStatus.Enabled] }]
  });
  
  const onFetchData = async () => {
    await paginatedDataTableQuery.refetch();
  };
  
  return {
    onFetchData,
    ...paginatedDataTableQuery
  };
};