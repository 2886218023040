import { useMutation } from "@tanstack/react-query";
import { AppointmentService } from "api";
import { ApiError, AppointmentForCreateUpdateDto, AppointmentForCreateUpdateFormContext } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";

export const useCreateAppointmentMutation = () => {
  const navigate = useNavigate();
  const createAppointmentMutation = useMutation({
    mutationKey: [AppointmentService.create.queryKey],
    mutationFn: (appointment: AppointmentForCreateUpdateDto) => AppointmentService.create.request(appointment),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess: () => {
      SnackbarUtils.success("Appointment created successfully!");
      navigate(`/${AppRoutes.appointments.root}`);
    }
  });

  const onCreateAppointment = (appointment: AppointmentForCreateUpdateFormContext) => {
    if(!appointment) return;
    createAppointmentMutation.mutate(appointment);
  };

  return {
    ...createAppointmentMutation,
    onCreateAppointment
  };
};