import { Option } from "types";

const SLOT_EXCEPTIONS: Option[] = [
  {
    id: "Available",
    label: "Available"
  },
  {
    id: "Unavailable",
    label: "Unavailable"
  }
];
export default SLOT_EXCEPTIONS;