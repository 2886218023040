import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";

export const useGetRolesWithManagersRolesLookupQuery = () => {
  const query = useQuery({
    queryKey: [LookupService.rolesWithManagersRoles.queryKey],
    queryFn: LookupService.rolesWithManagersRoles.request
  });

  return {
    ...query,
    options:
      query.data?.map((role) => ({
        ...role,
        id: role.id,
        label: role.displayName
      })) || []
  };
};
