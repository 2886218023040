import { useMutation } from "@tanstack/react-query";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";
import { store } from "stores";
import React from "react";
import { DoctorManagementService } from "api";

export const useDeleteDoctorMutation = () => {
  const deleteDoctorMutation = useMutation({
    mutationKey: [DoctorManagementService.delete.queryKey],
    mutationFn: (doctorId: string) => DoctorManagementService.delete.request(doctorId),
    onSuccess() {
      SnackbarUtils.success("Doctor deleted successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });
  
  const onDeleteDoctor = async (doctorId: string) => {
    return await deleteDoctorMutation.mutateAsync(doctorId);
  };
  
  const deleteDoctorHandler = (doctorId: string, doctorFullName: string, callback?: Function) => {
    store.confirmModalStore.open({
      title: "Confirm delete doctor",
      body: <p>Are you sure you want to delete <strong>{doctorFullName}</strong> doctor file?</p>,
      options: {
        onConfirm: async () => {
          try {
            await onDeleteDoctor(doctorId);
            if(callback) await callback();
          } catch(e) {
            console.error(e);
          }
        }
      }
    });
  };
  
  return {
    ...deleteDoctorMutation,
    onDeleteDoctor,
    deleteDoctorHandler
  };
};