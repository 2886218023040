import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { Router } from "router";
import { store, StoreContext } from "stores";
import { msalInstance } from "config";
import { TokenUtils } from "utils";
import { ToastContainer } from "react-toastify";

TokenUtils.initialize();

type AppProps = {}
const App: React.FC<AppProps> = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <StoreContext.Provider value={store}>
        <ToastContainer position="top-right"/>
        <Router/>
      </StoreContext.Provider>
    </MsalProvider>
  );
};

export default App;