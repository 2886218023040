import { MRT_SortingState } from "material-react-table";
import { PaginatedQueryParams, SortDirection } from "types";

export default class DataTableUtils {
  static mapSorting(sorting: MRT_SortingState) {
    return sorting?.map((column) => `${column.id}_${column.desc ? SortDirection.Desc : SortDirection.Asc}`)
      .join(",");
  }
  
  static mapQueryParamsToString = (queryParams: PaginatedQueryParams) => {
    return Object.keys(queryParams)
      .map(key => {
        if(Array.isArray(queryParams[key])) {
          return queryParams[key]?.map((value: any) => `${key}=${encodeURIComponent(value)}`).join("&");
        }
        return `${key}=${encodeURIComponent(queryParams[key])}`;
      })
      .join("&");
  };
}