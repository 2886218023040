type AnyObject = {
  [key: string]: any;
};
export default class ObjectUtils {
  static replaceEmptyStringsWithNull<T>(obj: T): T {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => ObjectUtils.replaceEmptyStringsWithNull(item)) as unknown as T;
    }

    const result: AnyObject = {};
    for (const key in obj as AnyObject) {
      if ((obj as AnyObject).hasOwnProperty(key)) {
        const value = (obj as AnyObject)[key];
        if (typeof value === "string" && value.trim() === "") {
          result[key] = null;
        } else if (Array.isArray(value) && value.every((item) => typeof item === "string")) {
          // Handle arrays of strings and return as is
          result[key] = value.map((item) => (item.trim() === "" ? null : item));
        } else if (Array.isArray(value)) {
          // Handle nested arrays
          result[key] = value.map((item) => ObjectUtils.replaceEmptyStringsWithNull(item));
        } else if (typeof value === "object" && value !== null) {
          // Handle nested objects
          result[key] = ObjectUtils.replaceEmptyStringsWithNull(value);
        } else {
          result[key] = value;
        }
      }
    }
    return result as T;
  }

  static replaceEmptyStringsWithNullInArray<T extends AnyObject>(
    arr: T[]
  ): T[] {
    return arr.map(obj => ObjectUtils.replaceEmptyStringsWithNull(obj));
  }
}
