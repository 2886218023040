import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";

import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";

import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "App";

import { DocumentUtils } from "utils";

document.addEventListener("DOMContentLoaded", () => {
  DocumentUtils.setDocumentMetadata();
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<StrictMode><App/></StrictMode>);