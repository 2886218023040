const PHONE_VALIDATION = "Phone number must be in the format of +1 xxx xxx xxxx";

const FAX_NUMBER_VALIDATION = "Fax number must be in the format of +1 xxx xxx xxxx";

const POSITIVE_NUMBER_VALIDATION = "Value must be greater than or equal to 0";

const SCHEMA_MESSAGES = Object.freeze({
  PHONE_VALIDATION,
  POSITIVE_NUMBER_VALIDATION,
  FAX_NUMBER_VALIDATION
});

export default SCHEMA_MESSAGES;