import { useMutation } from "@tanstack/react-query";
import { DocumentsService } from "api";
import { UploadMultiPartFilePayload } from "types";

import { useIsLoadingMutation } from "hooks/utils";

export const useUploadMultipartDocumentMutation = () => {
  const uploadMultipartDocumentMutation = useMutation({
    mutationKey: [DocumentsService.uploadMultiPartFile.queryKey],
    mutationFn: DocumentsService.uploadMultiPartFile.request,
    retry: 3,
    retryDelay: 1000
  });

  const { isLoading } = useIsLoadingMutation(
    DocumentsService.uploadMultiPartFile.queryKey
  );

  const uploadMultipartDocumentChunk = (payload: UploadMultiPartFilePayload) => {
    return uploadMultipartDocumentMutation.mutateAsync(payload);
  };

  return {
    ...uploadMultipartDocumentMutation,
    uploadMultipartDocumentChunk,
    isLoading
  };
};
