import { makeAutoObservable } from "mobx";
import { BreadcrumbItem } from "types";
export default class BreadcrumbStore {
  breadcrumbs: BreadcrumbItem[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  setBreadcrumbs = (breadcrumbs: BreadcrumbItem[]) => {
    this.breadcrumbs = breadcrumbs;
  };

  clearBreadcrumbs = () => {
    this.breadcrumbs = [];
  };
}