import { useQuery } from "@tanstack/react-query";
import { AppointmentService } from "api";
import { AppointmentCalendar } from "types";
import moment from "moment/moment";
import { DATE_TIME_FORMAT } from "app-constants";

export const useAppointmentCalendarQuery = (currentDate: string) => {
  function getYearAndMonth(): { year: number; month: number } {
    const inputDate = moment(currentDate, DATE_TIME_FORMAT.DATE);

    const year = inputDate.year();
    const month = inputDate.month() + 1; // Moment.js months are 0-indexed

    return { year, month };
  }
  const appointmentCalendarQuery = useQuery({
    queryKey: [AppointmentService.calendar.queryKey, getYearAndMonth().month, getYearAndMonth().year],
    queryFn: () => AppointmentService.calendar.request(currentDate),
    select: (data) => {
      return data.map((appointmentCalendar) => {
        const {
          patientUser: { firstName, lastName, middleName },
          scheduleDetail: { date, duration }
        } = appointmentCalendar;

        const patientFullName = `${firstName} ${middleName ?? ""} ${lastName}`;

        const title = `${patientFullName}`;
        const startDate = new Date(`${date}T${duration?.startTime}`);
        const endDate = new Date(`${date}T${duration?.endTime}`);

        return { ...appointmentCalendar, title, startDate, endDate };
      }) as AppointmentCalendar[];
    }
  });
  return {
    ...appointmentCalendarQuery
  };
};