import { GridProps } from "@mui/material/Grid/Grid";
import { Grid } from "@mui/material";
import { PropsWithChildren } from "react";

type FormCardGridProps = GridProps & PropsWithChildren & {
  withBorderLeft?: boolean
}
function FormCardGrid({ children, withBorderLeft = true, ...props }: FormCardGridProps) {
  return (
    <Grid sx={{ borderLeft: withBorderLeft ? "1px solid #ccc" : null, paddingLeft: "20px", paddingRight: "20px" }} item xs={12} {...props}>
      {children}
    </Grid>
  );
}

export default FormCardGrid;