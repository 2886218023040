import { makeAutoObservable } from "mobx";
import { LookupListItemDto } from "types";

export default class RolesStore {
  roles: LookupListItemDto[] = [];
  
  constructor() {
    makeAutoObservable(this);
  }
  
  setRoles = (roles: LookupListItemDto[]) => {
    this.roles = roles;
  };
  
  getRoleByName = (name: string) => {
    return this.roles.find(role => role.name === name);
  };
}