import "components/table-addition-bar/TableAdditionBar.scss";
import { Box, Stack, Typography } from "@mui/material";
import { PrimeButton } from "components/common";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ContentAdderProps } from "types";
import AuthorizationWrapper from "components/permissions/AuthorizationWrapper";

function TableAdditionBar({
  title,
  itemsCount,
  onAdd,
  children,
  permissions,
  hideActions,
  ...rest
}: ContentAdderProps) {
  return (
    <Stack
      marginY='20px'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box>
        <Typography variant='h5'>
          {title} <span className='items-count'>({itemsCount})</span>
        </Typography>
      </Box>
      {!hideActions && (
        <Stack height='44px' direction='row' gap={2}>
          <Box>{children}</Box>
          {rest.buttonElement ? (
            rest.buttonElement
          ) : (
            <AuthorizationWrapper permissions={permissions ?? []}>
              <PrimeButton
                startIcon={<AddCircleIcon />}
                onClick={onAdd}
                color='secondary'
              >
                Add
              </PrimeButton>
            </AuthorizationWrapper>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default TableAdditionBar;
