import { useQuery } from "@tanstack/react-query";
import { DoctorManagementService } from "api";
import { useParams } from "react-router-dom";
import { useState } from "react";

export const useAvailabilitySlotsQuery = () => {
  const [startDate, setStartDate] = useState<string | undefined>("");
  const [endDate, setEndDate] = useState<string | undefined>("");
  const { doctorId } = useParams<{ doctorId: string }>();
  
  const availabilitySlotsQuery = useQuery({
    queryKey: [DoctorManagementService.availabilitySlots.queryKey, doctorId, startDate, endDate],
    queryFn: () => DoctorManagementService.availabilitySlots.request(doctorId!, startDate, endDate),
    retry: false,
    select: (data) => data?.slots
  });
  
  const fetchAvailabilitySlots = async (startDate?: string, endDate?: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
    
    return await availabilitySlotsQuery.refetch();
  };
  
  return {
    ...availabilitySlotsQuery,
    fetchAvailabilitySlots
  };
};