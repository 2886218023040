import { useQuery } from "@tanstack/react-query";
import { BillingManagementService } from "api";

export const usePaymentDetailsQuery = (paymentId?: string) => {
  const query = useQuery({
    queryKey: [BillingManagementService.details.queryKey, paymentId],
    queryFn: () => BillingManagementService.details.request(paymentId!),
    enabled: !!paymentId
  });

  return {
    ...query
  };
};
