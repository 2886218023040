import { makeAutoObservable } from "mobx";
import { ConfirmModalStoreProps } from "types";

type ConfirmModalStoreState = {
  open: boolean;
  props: ConfirmModalStoreProps | null;
};

export default class ConfirmModalStore {
  modal: ConfirmModalStoreState = {
    open: false,
    props: null
  };

  constructor() {
    makeAutoObservable(this);
  }

  open = (openModalProps: ConfirmModalStoreProps) => {
    this.modal.props = openModalProps;
    this.modal.open = true;
  };

  close = () => {
    const options = this.modal.props?.options;
    if (options?.onCancel) {
      options.onCancel();
    }
    this.closeModalHandler();
  };

  confirm = () => {
    const options = this.modal.props?.options;
    if (options?.onConfirm) {
      options.onConfirm();
    }
    this.closeModalHandler();
  };

  private readonly closeModalHandler = () => {
    this.modal.open = false;
    this.modal.props = null;
  };

  setIsLoading = (isLoading: boolean) => {
    this.modal.props!.isLoading = isLoading;
  };
}
