import { toast, ToastOptions } from "react-toastify";
import { ToastType } from "types";
import React from "react";
import { DeleteIcon } from "components/icons";

export default class SnackbarUtils {
  private static showToast<T>(type: ToastType, message: string, callback?: () => void, options?: ToastOptions) {
    toast[type]<T>(message, options);
    if (callback) {
      setTimeout(callback, 2000);
    }
  }

  static success = <T>(message: string, options?: ToastOptions) => {
    toast.success<T>(message, {
      ...options,
      autoClose: 3000,
      hideProgressBar: false
    });
  };

  static warning = <T>(message: string, options?: ToastOptions) => {
    toast.warning<T>(message, options);
  };

  static info = <T>(message: string, options?: ToastOptions) => {
    toast.info<T>(message, options);
  };

  static error = <T>(message: string, options?: ToastOptions) => {
    toast.error<T>(message, {
      autoClose: false,
      hideProgressBar: true,
      ...options
    });
  };

  static errorWithDelay = <T>(
    message: string,
    callback?: () => void,
    options?: ToastOptions
  ) => {
    SnackbarUtils.showToast<T>("error", message, callback, options);
  };

  static DismissToast(toastId: string) {
    if (toast.isActive(toastId)) {
      toast.dismiss(toastId);
    }
  }

  static IsActiveToast(toastId: string) {
    return toast.isActive(toastId);
  }

  static DeleteIcon() {
    return React.createElement(DeleteIcon, {
      style: {
        color: "#FF0000"
      }
    });
  };
}
