import { useQuery } from "@tanstack/react-query";
import { DoctorManagementService } from "api";

export const useSpecialitiesLocationsForDoctorQuery = (doctorId?: string) => {
  const specialitiesLocationsForDoctorQuery = useQuery(
    [DoctorManagementService.specialitiesLocationsForDoctor.queryKey, doctorId],
    () => {
      if(!doctorId) return [];
      return DoctorManagementService.specialitiesLocationsForDoctor.request(doctorId);
    },
    {
      enabled: !!doctorId
    }
  );

  return {
    ...specialitiesLocationsForDoctorQuery
  };
};
