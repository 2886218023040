import { PropsWithChildren } from "react";
import { ApiError } from "types";
import { GeneralScreenTemplate } from "components/general-screen-template";
import ServerErrorImage from "assets/images/server-error.webp";
import { useNavigate } from "react-router-dom";
type ErrorServerBoundaryProps = PropsWithChildren & {
  error?: ApiError | unknown
}
function ErrorServerBoundary({ error, children }: ErrorServerBoundaryProps) {
  const navigate = useNavigate();

  if(error && (error as ApiError)?.status === 500) {
    return (
      <GeneralScreenTemplate
        image={ServerErrorImage}
        title="Internal Server Error"
        description="The server encountered an error and could not complete your request"
        button={{
          text: "Go Back",
          click: () => navigate(-1)
        }}
      />
    );
  }
  return (<>{children}</>);
}

export default ErrorServerBoundary;