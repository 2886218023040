import { ApiURLs } from "api/core/ApiURLs";

const DOCUMENT_MANAGEMENT = Object.freeze({
  DEFAULT_MAX_UPLOAD_BATCH_SIZE: 20,
  MULTIPART_UPLOAD_MODULES: [
    ApiURLs?.appointmentManagement,
    ApiURLs?.activeReportManagement
  ]
});

export default DOCUMENT_MANAGEMENT;