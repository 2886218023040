import { store } from "stores";

export default class ConfirmAlertsUtils {
  static confirmAlert({ title, message }: { title: string; message: string }, callback: Function) {
    store.confirmModalStore.open({
      title,
      body: <p>{message}</p>,
      options: {
        onConfirm: () => {
          callback();
        }
      }
    });
  }
}