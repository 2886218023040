import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { RouteObject } from "react-router-dom";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";
import { FormLayout } from "layout";

const UserListPage = WithLoadable(
  lazy(() => import("pages/users/UserListPage"))
);
const UserProfilePage = WithLoadable(
  lazy(() => import("pages/users/UserProfilePage"))
);

const UserCreatePage = WithLoadable(
  lazy(() => import("pages/users/UserCreatePage"))
);

const UserUpdatePage = WithLoadable(
  lazy(() => import("pages/users/UserUpdatePage"))
);

export const UsersRoutes: RouteObject = {
  path: AppRoutes.users.root,
  element: (
    <AuthorizationGuard
      permissions={[AppPermissions.UserManagement.Actions.View]}
    />
  ),
  children: [
    {
      index: true,
      element: <UserListPage />
    },
    {
      path: `${AppRoutes.users.profile}/:userId`,
      element: <UserProfilePage />
    }
  ]
};

export const UserFormsRoutes: RouteObject = {
  path: AppRoutes.users.root,
  element: <FormLayout />,
  children: [
    {
      path: AppRoutes.users.create,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.UserManagement.Actions.Create]}
        />
      ),
      children: [{ index: true, element: <UserCreatePage /> }]
    },
    {
      path: `${AppRoutes.users.update}/:userId`,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.UserManagement.Actions.Update]}
        />
      ),
      children: [{ index: true, element: <UserUpdatePage /> }]
    }
  ]
};
