import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";
import { UserRoles } from "app-constants";

export const useRolesLookup = () => {
  const lookupRolesQuery = useQuery({
    queryKey: [LookupService.roles.queryKey],
    queryFn: LookupService.roles.request
  });
  
  const hasDoctorRole = (roleIds: string[]): boolean => {
    const rolesIds = lookupRolesQuery.data;
    return roleIds?.some(roleId => rolesIds?.some(role => role.id === roleId && role.name === UserRoles.Doctor));
  };

  const getUserRoleById = (roleId?: string) => {
    if(!roleId) return "";
    const rolesIds = lookupRolesQuery.data;
    return rolesIds?.find(role => role.id === roleId)?.displayName ?? "";
  };

  const getRoleNamesByIds = (roles?: string[]): string[] => {
    if(!roles?.length) return [];
    const rolesIds = lookupRolesQuery.data;
    return rolesIds?.filter(role => roles?.includes(role.id))?.map((role) => role.displayName ?? "") ?? [];
  };

  const getRoleByIds = (roles?: string[]): string[] => {
    if(!roles?.length) return [];
    const rolesIds = lookupRolesQuery.data;
    return rolesIds?.filter(role => roles?.includes(role.id))?.map((role) => role.name ?? "") ?? [];
  };

  const getAllRolesExcludingDoctor = () => {
    return lookupRolesQuery.data?.filter(role => role.name !== UserRoles.Doctor);
  };

  const getDoctorRoleId = () => {
    return lookupRolesQuery.data?.find(r => r.name === UserRoles.Doctor);
  };
  
  return {
    ...lookupRolesQuery,
    getUserRoleById,
    hasDoctorRole,
    getAllRolesExcludingDoctor,
    getRoleNamesByIds,
    getDoctorRoleId,
    getRoleByIds
  };
};