import { Divider, Grid, Typography, TypographyProps } from "@mui/material";
import React from "react";
import { GridProps } from "@mui/material/Grid/Grid";

type CardSubtitleProps = {
  title: string;
  cardGridProps?: GridProps;
  titleProps?: TypographyProps;
}

function CardSubtitle({ title, cardGridProps, titleProps }: CardSubtitleProps) {
  return (
    <Grid xs={9} item {...cardGridProps}>
      <Typography mb={1} {...titleProps}>{title}</Typography>
      <Divider/>
    </Grid>
  );
}

export default CardSubtitle;