export default class FileUtils {
  static downloadFile(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  
  static getFileExtension(file: File): string {
    const fileType = file.type;
    const mimeTypeMap: { [key: string]: string } = {
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/gif": "gif",
      "image/bmp": "bmp",
      "image/webp": "webp",
      "application/pdf": "pdf",
      "application/msword": "doc",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
      "application/vnd.ms-excel": "xls",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
      "application/vnd.ms-powerpoint": "ppt",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
      "text/plain": "txt",
      "text/csv": "csv",
      "application/json": "json",
      "application/xml": "xml"
      // Add more file types and their corresponding extensions if needed
    };
    
    const defaultExtension = "txt"; // Default extension if the file type is not found in the map
    
    const mimeType = fileType.toLowerCase();
    return mimeTypeMap[mimeType] || defaultExtension;
  }
}