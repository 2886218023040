import {
  ApplicationRoleLookupListItemDto,
  FeaturesWithClaimsForListDto,
  GroupedFeatures, RoleClaimsForUpdateDto,
  RoleGroup
} from "types";
import { UserRoles } from "app-constants";

export default class RolesUtils {
  static groupRoles(roles: ApplicationRoleLookupListItemDto[]) {
    const ADMINISTRATORS_TEAM_ROLES = UserRoles.AdministratorsRoles;
    const MEDICAL_TEAM_ROLES = UserRoles.MedicalTeamRoles;
    const BACKOFFICE_TEAM_ROLES = UserRoles.BackOfficeRoles;

    const roleGroup: RoleGroup = {
      administrators: [],
      medicalTeam: [],
      backoffice: []
    };

    for (const role of roles) {
      const { name } = role;
      if (ADMINISTRATORS_TEAM_ROLES.includes(name!)) {
        roleGroup.administrators.push(role);
      } else if (MEDICAL_TEAM_ROLES.includes(name!)) {
        roleGroup.medicalTeam.push(role);
      } else if(BACKOFFICE_TEAM_ROLES.includes(name!)) {
        roleGroup.backoffice.push(role);
      }
    }

    return roleGroup;
  }

  static groupFeaturesByCategory(features: FeaturesWithClaimsForListDto[]): GroupedFeatures {
    const groupedFeatures: GroupedFeatures = {
      managements: [],
      users: [],
      appointments: []
    };

    for (const feature of features) {
      const { featureName, claims } = feature;
      const sortedClaims = [...claims].sort((a, b) => {
        // Function to check if claimValue is "Permission:<FeatureName>:View"
        const isViewClaim = (claim: RoleClaimsForUpdateDto) => claim.claimValue.includes(":View") && !claim.claimValue.includes("ViewDocument");

        if (isViewClaim(a)) {
          return -1;
        } else if (isViewClaim(b)) {
          return 1;
        }
        return 0;
      });

      const featureWithSortedClaims = {
        featureName,
        claims: sortedClaims
      };

      if (featureName.includes("Locations Management") || featureName.includes("Role Management")) {
        groupedFeatures.managements.push(featureWithSortedClaims);
      } else if (featureName.includes("Patient Files") || featureName.includes("User Management") || featureName.includes("Doctor Management")) {
        groupedFeatures.users.push(featureWithSortedClaims);
      } else {
        groupedFeatures.appointments.push(featureWithSortedClaims);
      }
    }

    return groupedFeatures;
  }
}