import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";
import { MedicalTeamKeySelector, MedicalTeamLookupListDto } from "types";

const getMedicalTeamNamesByIds = (medicalTeamData: MedicalTeamLookupListDto, ids: string[], keySelector: MedicalTeamKeySelector) => {
  const medicalTeamList = medicalTeamData[keySelector] ?? [];
  return medicalTeamList
    .filter(teamMember => ids.includes(teamMember.id))
    .map(teamMember => teamMember.name);
};

export const useMedicalTeamLookupQuery = () => {
  const medicalTeamLookupQuery = useQuery({
    queryKey: [LookupService.usersMedical.queryKey],
    queryFn: LookupService.usersMedical.request
  });

  const getTeamNamesByIds = (ids: string[], keySelector: MedicalTeamKeySelector) => {
    if (!medicalTeamLookupQuery?.data) {
      return [];
    }
    return getMedicalTeamNamesByIds(medicalTeamLookupQuery?.data, ids, keySelector);
  };

  return {
    ...medicalTeamLookupQuery,
    getTeamNamesByIds
  };
};
