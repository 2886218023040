export const ApiURLs = {
  userManagement: "/users-management",
  doctorManagement: "/doctors-management",
  patientFiles: "/patient-files-management",
  LookUp: "/lookup",
  locations: "/locations-management",
  appointmentManagement: "/appointments-management",
  activeReportManagement: "/active-reports-management",
  archivedReportManagement: "/archived-reports-management",
  roleManagement: "/roles-claims-management",
  attorneyManagement: "/attorneys-management",
  adjustersManagement: "/insurance-companies-management",
  billingManagement: "/billing-management",
  duplicatePayment: "/duplicate-payment-management"
};
