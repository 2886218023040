import { useQuery } from "@tanstack/react-query";
import { AdjustersManagementService } from "api";
import { ObjectUtils } from "utils";

export const useAdjusterProfileQuery = (adjusterId?: string) => {
  const adjusterProfileQuery = useQuery({
    queryKey: [AdjustersManagementService.details.queryKey, adjusterId],
    queryFn: () => AdjustersManagementService.details.request(adjusterId!),
    enabled: !!adjusterId,
    select: (data) => ObjectUtils.replaceEmptyStringsWithNull(data)
  });
  
  return {
    ...adjusterProfileQuery
  };
};