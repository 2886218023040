import { useMutation } from "@tanstack/react-query";
import { LocationService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";
import { store } from "stores";
import React from "react";

export const useDeleteLocationMutation = () => {
  const deleteLocationMutation = useMutation({
    mutationKey: [LocationService.delete.queryKey],
    mutationFn: (locationId: string) => LocationService.delete.request(locationId),
    onSuccess() {
      SnackbarUtils.success("Location deleted successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });
  
  const onDeleteLocation = async (locationId: string) => {
    return await deleteLocationMutation.mutateAsync(locationId);
  };
  
  const deleteLocationHandler = (locationId: string, locationFullName: string, callback?: Function) => {
    store.confirmModalStore.open({
      title: "Confirm delete location",
      body: <p>Are you sure you want to delete <strong>{locationFullName}</strong> location file?</p>,
      options: {
        onConfirm: async () => {
          try {
            await onDeleteLocation(locationId);
            if(callback) await callback();
          } catch(e) {
            console.error(e);
          }
        }
      }
    });
  };
  
  return {
    ...deleteLocationMutation,
    onDeleteLocation,
    deleteLocationHandler
  };
};