import { useEffect, useState } from "react";
import { AppointmentFoldersSectionDto, DocumentForReadDto, FolderForListDto } from "types";
import { useDeleteDocumentMutation, useFoldersQuery, useDownloadDocumentMutation, useUploadDocumentMutation } from "hooks";

export const useDocumentManagement = <T>(URL: string, entityId: string, folderId: string, keySelector?: keyof T) => {
  const [currentFolder, setCurrentFolder] = useState<FolderForListDto | null>(null);
  const { data: folders, isLoading, onFetchFolders, ...folderQuery } = useFoldersQuery<T>(URL, entityId);
  const { deleteDocumentHandler, isLoading: isDeleteLoading } = useDeleteDocumentMutation(URL, entityId);
  const { downloadDocumentHandler } = useDownloadDocumentMutation(URL, entityId);
  const { onUploadFiles } = useUploadDocumentMutation(URL, entityId);
  const onUploadFilesHandler = async (folderId: string, files: File[]) => {
    await onUploadFiles(folderId, files);
    onFetchFolders();
  };

  useEffect(() => {
    if(Array.isArray(folders)) {
      const currentFolder = (folders as FolderForListDto[])?.find(folder => folder.id === folderId);
      setCurrentFolder(currentFolder!);
    }else if(keySelector) {
      const appointmentFolders = (folders?.[keySelector] as AppointmentFoldersSectionDto)?.folders ?? [];
      const currentFolder = appointmentFolders.find(folder => folder.id === folderId);
      setCurrentFolder(currentFolder!);
    }
  }, [folders, folderId, keySelector]);

  const onDeleteDocument = (folderId: string, document: DocumentForReadDto) => {
    deleteDocumentHandler(document.id, folderId, document.name ?? "", () => {
      const documents = currentFolder?.documents.filter(doc => doc.id !== document.id) ?? [];
      setCurrentFolder({
        ...currentFolder!,
        documents
      });
    });
  };

  const onDownloadDocument = (folderId: string, document: DocumentForReadDto) => {
    downloadDocumentHandler(document.id, folderId, document.name!);
  };

  return {
    onDownloadDocument,
    onDeleteDocument,
    onUploadFilesHandler,
    isDeleteLoading,
    isLoading,
    folders,
    currentFolder,
    ...folderQuery
  };
};