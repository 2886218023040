import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Avatar, ButtonBase } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStore } from "stores";

function MenuOutlined() {
  const { commonStore } = useStore();
  const theme = useTheme();
  return (
    <ButtonBase sx={{
      borderRadius: "8px",
      overflow: "hidden"
    }}>
      <Avatar
        variant="square"
        sx={{
          width: "34px",
          height: "34px",
          fontSize: "1.2rem",
          cursor: "pointer",
          transition: "all .2s ease-in-out",
          background: theme.palette.secondary.light,
          color: theme.palette.secondary.dark,
          "&:hover": {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.light
          }
        }}
        onClick={() => commonStore.toggleNavbar()}
        color="inherit"
      >
        <MenuOutlinedIcon/>
      </Avatar>
    </ButtonBase>
  
  );
}

export default MenuOutlined;