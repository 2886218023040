function ManagementIcon() {
  return (
    <svg style={{ marginLeft: "3.5px" }} width="24" height="24" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>management icon</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Left-Side-Nav-/-drop-down" transform="translate(-44, -500)">
          <g id="Group-3" transform="translate(0, 489)">
            <g id="management-icon" transform="translate(42, 8)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <g className="sidebar-icon" id="Group-16" transform="translate(2, 3)" fill="#FFFFFF" fillRule="nonzero">
                <g id="select_window_FILL1_wght400_GRAD0_opsz48">
                  <path d="M0.975,13 C0.715,13 0.4875,12.9025 0.2925,12.7075 C0.0975,12.5125 0,12.285 0,12.025 L0,5.73625 C0,5.47625 0.0975,5.24875 0.2925,5.05375 C0.4875,4.85875 0.715,4.76125 0.975,4.76125 L2.84375,4.76125 L2.84375,0.975 C2.84375,0.715 2.94125,0.4875 3.13625,0.2925 C3.33125,0.0975 3.55875,0 3.81875,0 L12.025,0 C12.285,0 12.5125,0.0975 12.7075,0.2925 C12.9025,0.4875 13,0.715 13,0.975 L13,7.26375 C13,7.52375 12.9025,7.75125 12.7075,7.94625 C12.5125,8.14125 12.285,8.23875 12.025,8.23875 L10.1725,8.23875 L10.1725,12.025 C10.1725,12.285 10.075,12.5125 9.88,12.7075 C9.685,12.9025 9.4575,13 9.1975,13 L0.975,13 Z M0.975,12.025 L9.1975,12.025 L9.1975,6.71125 L0.975,6.71125 L0.975,12.025 Z M10.1725,7.26375 L12.025,7.26375 L12.025,1.95 L3.81875,1.95 L3.81875,4.76125 L8.97,4.76125 C9.31666667,4.76125 9.60375,4.875 9.83125,5.1025 C10.05875,5.33 10.1725,5.61708333 10.1725,5.96375 L10.1725,7.26375 Z" id="Shape"></path>
                </g>
                <g id="settings_FILL1_wght400_GRAD0_opsz48" transform="translate(7, 5)">
                  <path d="M5.005,13 L4.68,10.9525 C4.47416667,10.8766667 4.2575,10.77375 4.03,10.64375 C3.8025,10.51375 3.60208333,10.3783333 3.42875,10.2375 L1.51125,11.115 L0,8.45 L1.755,7.16625 C1.73333333,7.06875 1.71979167,6.95770833 1.714375,6.833125 C1.70895833,6.70854167 1.70625,6.5975 1.70625,6.5 C1.70625,6.4025 1.70895833,6.29145833 1.714375,6.166875 C1.71979167,6.04229167 1.73333333,5.93125 1.755,5.83375 L0,4.55 L1.51125,1.885 L3.42875,2.7625 C3.60208333,2.62166667 3.8025,2.48625 4.03,2.35625 C4.2575,2.22625 4.47416667,2.12875 4.68,2.06375 L5.005,0 L7.995,0 L8.32,2.0475 C8.52583333,2.12333333 8.74520833,2.22354167 8.978125,2.348125 C9.21104167,2.47270833 9.40875,2.61083333 9.57125,2.7625 L11.48875,1.885 L13,4.55 L11.245,5.80125 C11.2666667,5.90958333 11.2802083,6.02604167 11.285625,6.150625 C11.2910417,6.27520833 11.29375,6.39166667 11.29375,6.5 C11.29375,6.60833333 11.2910417,6.72208333 11.285625,6.84125 C11.2802083,6.96041667 11.2666667,7.07416667 11.245,7.1825 L13,8.45 L11.48875,11.115 L9.57125,10.2375 C9.39791667,10.3783333 9.20020833,10.5164583 8.978125,10.651875 C8.75604167,10.7872917 8.53666667,10.8875 8.32,10.9525 L7.995,13 L5.005,13 Z M6.5,8.6125 C7.085,8.6125 7.58333333,8.40666667 7.995,7.995 C8.40666667,7.58333333 8.6125,7.085 8.6125,6.5 C8.6125,5.915 8.40666667,5.41666667 7.995,5.005 C7.58333333,4.59333333 7.085,4.3875 6.5,4.3875 C5.915,4.3875 5.41666667,4.59333333 5.005,5.005 C4.59333333,5.41666667 4.3875,5.915 4.3875,6.5 C4.3875,7.085 4.59333333,7.58333333 5.005,7.995 C5.41666667,8.40666667 5.915,8.6125 6.5,8.6125 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ManagementIcon;