function ExpectedAmountIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2682_29353)'>
        <path
          d='M17.5 24C13.916 24 11 21.084 11 17.5C11 13.916 13.916 11 17.5 11C21.084 11 24 13.916 24 17.5C24 21.084 21.084 24 17.5 24ZM17.5 12.5C14.743 12.5 12.5 14.743 12.5 17.5C12.5 20.257 14.743 22.5 17.5 22.5C20.257 22.5 22.5 20.257 22.5 17.5C22.5 14.743 20.257 12.5 17.5 12.5Z'
          fill='#929292'
        />
        <path
          d='M17.5 21C17.3011 21 17.1103 20.921 16.9697 20.7803C16.829 20.6397 16.75 20.4489 16.75 20.25V14.75C16.75 14.5511 16.829 14.3603 16.9697 14.2197C17.1103 14.079 17.3011 14 17.5 14C17.6989 14 17.8897 14.079 18.0303 14.2197C18.171 14.3603 18.25 14.5511 18.25 14.75V20.25C18.25 20.4489 18.171 20.6397 18.0303 20.7803C17.8897 20.921 17.6989 21 17.5 21Z'
          fill='#929292'
        />
        <path
          d='M20.25 18.25H14.75C14.5511 18.25 14.3603 18.171 14.2197 18.0303C14.079 17.8897 14 17.6989 14 17.5C14 17.3011 14.079 17.1103 14.2197 16.9697C14.3603 16.829 14.5511 16.75 14.75 16.75H20.25C20.4489 16.75 20.6397 16.829 20.7803 16.9697C20.921 17.1103 21 17.3011 21 17.5C21 17.6989 20.921 17.8897 20.7803 18.0303C20.6397 18.171 20.4489 18.25 20.25 18.25ZM9.19 21H2.75C2.02082 20.9995 1.32165 20.7096 0.806041 20.194C0.290431 19.6783 0.000529737 18.9792 0 18.25L0 2.75C0.000529737 2.02082 0.290431 1.32165 0.806041 0.806041C1.32165 0.290431 2.02082 0.000529737 2.75 0L14.25 0C14.9792 0.000529737 15.6783 0.290431 16.194 0.806041C16.7096 1.32165 16.9995 2.02082 17 2.75V8.84C17 9.03891 16.921 9.22968 16.7803 9.37033C16.6397 9.51098 16.4489 9.59 16.25 9.59C16.0511 9.59 15.8603 9.51098 15.7197 9.37033C15.579 9.22968 15.5 9.03891 15.5 8.84V2.75C15.5 2.061 14.939 1.5 14.25 1.5H2.75C2.061 1.5 1.5 2.061 1.5 2.75V18.25C1.5 18.939 2.061 19.5 2.75 19.5H9.19C9.38891 19.5 9.57968 19.579 9.72033 19.7197C9.86098 19.8603 9.94 20.0511 9.94 20.25C9.94 20.4489 9.86098 20.6397 9.72033 20.7803C9.57968 20.921 9.38891 21 9.19 21Z'
          fill='#929292'
        />
        <path
          d='M13.25 9.5H3.75C3.55109 9.5 3.36032 9.42098 3.21967 9.28033C3.07902 9.13968 3 8.94891 3 8.75C3 8.55109 3.07902 8.36032 3.21967 8.21967C3.36032 8.07902 3.55109 8 3.75 8H13.25C13.4489 8 13.6397 8.07902 13.7803 8.21967C13.921 8.36032 14 8.55109 14 8.75C14 8.94891 13.921 9.13968 13.7803 9.28033C13.6397 9.42098 13.4489 9.5 13.25 9.5ZM9.25 13.5H3.75C3.55109 13.5 3.36032 13.421 3.21967 13.2803C3.07902 13.1397 3 12.9489 3 12.75C3 12.5511 3.07902 12.3603 3.21967 12.2197C3.36032 12.079 3.55109 12 3.75 12H9.25C9.44891 12 9.63968 12.079 9.78033 12.2197C9.92098 12.3603 10 12.5511 10 12.75C10 12.9489 9.92098 13.1397 9.78033 13.2803C9.63968 13.421 9.44891 13.5 9.25 13.5ZM8.25 5.5H3.75C3.55109 5.5 3.36032 5.42098 3.21967 5.28033C3.07902 5.13968 3 4.94891 3 4.75C3 4.55109 3.07902 4.36032 3.21967 4.21967C3.36032 4.07902 3.55109 4 3.75 4H8.25C8.44891 4 8.63968 4.07902 8.78033 4.21967C8.92098 4.36032 9 4.55109 9 4.75C9 4.94891 8.92098 5.13968 8.78033 5.28033C8.63968 5.42098 8.44891 5.5 8.25 5.5Z'
          fill='#929292'
        />
      </g>
      <defs>
        <clipPath id='clip0_2682_29353'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ExpectedAmountIcon;
