import { useMutation } from "@tanstack/react-query";
import { DoctorManagementService } from "api";

export const useDoctorAssignedToPatientFileMutation = () => {
  const doctorAssignedToPatientFileMutation = useMutation({
    mutationKey: [DoctorManagementService.doctorAssignedToPatientFile.queryKey],
    mutationFn: (patientFileId: string) => DoctorManagementService.doctorAssignedToPatientFile.request(patientFileId)
  });

  const onFetchDoctorAssignedToPatientFile = (patientFileId: string) => {
    return doctorAssignedToPatientFileMutation.mutateAsync(patientFileId);
  };

  return {
    ...doctorAssignedToPatientFileMutation,
    onFetchDoctorAssignedToPatientFile
  };
};