import { useMutation } from "@tanstack/react-query";
import { DoctorManagementService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, DoctorForCreateUpdateFormContext } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useUploadSignatureMutation } from "hooks/index";

type MutationFnProps = {
  doctor: DoctorForCreateUpdateFormContext;
  doctorId: string;
}
export const useUpdateDoctorMutation = () => {
  const [doctor, setDoctor] = useState<DoctorForCreateUpdateFormContext>();
  const { doctorId } = useParams<{ doctorId: string }>();
  const { onUploadSignature } = useUploadSignatureMutation();
  const navigate = useNavigate();
  
  const updateDoctorMutation = useMutation({
    mutationKey: [DoctorManagementService.update.queryKey],
    mutationFn: ({ doctor, doctorId }: MutationFnProps) => DoctorManagementService.update.request(doctor, doctorId),
    onSuccess: async () => {
      if(!doctorId || !doctor) return;
      try {
        SnackbarUtils.success("Doctor updated successfully!");
        await onUploadSignature(doctorId, doctor);
        navigate(-1);
      } catch(e) {
        console.error(e);
      }
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onUpdateDoctor = (doctor: DoctorForCreateUpdateFormContext) => {
    if(!doctor || !doctorId) return;
    setDoctor(doctor);
    updateDoctorMutation.mutate({
      doctor,
      doctorId
    });
  };
  
  return {
    onUpdateDoctor,
    ...updateDoctorMutation
  };
};