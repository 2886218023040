import { useQuery } from "@tanstack/react-query";
import { DocumentsService } from "api";
import { ErrorsUtils } from "utils";
import { ApiError } from "types";

export const useFoldersQuery = <T>(URL: string, entityId: string) => {
  const foldersQuery = useQuery({
    queryKey: [DocumentsService.folders.queryKey],
    queryFn: () => DocumentsService.folders.request<T>(URL, entityId)
  });

  const onFetchFolders = async () => {
    try {
      await foldersQuery.refetch();
    }catch(e) {
      ErrorsUtils.renderApiErrors(e as ApiError);
    }
  };

  return {
    ...foldersQuery,
    onFetchFolders
  };
};