import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";

export const useUsersLookupQuery = (role: string | null) => {
  const usersLookupQuery = useQuery({
    queryKey: [LookupService.users.queryKey, role],
    queryFn: () => LookupService.users.request(role!),
    enabled: !!role
  });
  
  return {
    ...usersLookupQuery
  };
};