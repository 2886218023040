import { usePaginatedDataTableQuery } from "hooks/utils/usePaginatedDataTableQuery";
import { EPatientStatus, PatientFileForListDto } from "types";
import { PatientService } from "api";

export const usePatientsListQuery = () => {
  const paginatedDataTableQuery = usePaginatedDataTableQuery<PatientFileForListDto>({
    queryFn: PatientService.list.request,
    queryKey: PatientService.list.queryKey,
    initialColumnFilters: [{ id: "patientFileStatusFilter", value: [EPatientStatus.InProgress, EPatientStatus.Draft] }]
  });
  
  const onFetchData = async () => {
    await paginatedDataTableQuery.refetch();
  };
  
  return {
    onFetchData,
    ...paginatedDataTableQuery
  };
};