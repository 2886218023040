import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type QueryParams = URLSearchParams;

const parseQueryParams = (search: string): QueryParams => {
  return new URLSearchParams(search);
};

export const useQueryParams = (defaultParams?: QueryParams) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState<QueryParams>(() => {
    const params = parseQueryParams(location.search);
    if (defaultParams) {
      defaultParams.forEach((value, key) => {
        if (!params.has(key)) {
          params.append(key, value);
        }
      });
    }
    return params;
  });

  const updateQueryParams = (newParams: QueryParams) => {
    const search = newParams.toString();
    navigate(`${location.pathname}?${search}`, { replace: true });
  };

  useEffect(() => {
    setQueryParams(parseQueryParams(location.search));
  }, [location.search]);

  return {
    searchParams: queryParams,
    setSearchParams: updateQueryParams,
    navigate,
    location
  };
};
