import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { AppRoutes } from "router/AppRoutes";

type AuthGuardProps = {
  requiredAuth: boolean;
}
export default function AuthGuard({ requiredAuth }: AuthGuardProps) {
  const location = useLocation();
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();

  if(!(accounts.length > 0) && requiredAuth) {
    return <Navigate to={AppRoutes.login} state={{ from: location }}/>;
  }

  if(accounts.length > 0 && !requiredAuth) {
    return <Navigate to={AppRoutes.app} state={{ from: location }}/>;
  }

  return <Outlet/>;
}