import { useMutation } from "@tanstack/react-query";
import { ActiveReportService } from "api";
import { ApiError } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useParams } from "react-router-dom";

export const useSignReportMutation = () => {
  const { activeReportId } = useParams<{ activeReportId: string }>();
  const signReportMutation = useMutation({
    mutationKey: [ActiveReportService.sign.queryKey],
    mutationFn: () => ActiveReportService.sign.request(activeReportId!),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Active report signed successfully");
    }
  });

  const onSignReport = async () => {
    return await signReportMutation.mutateAsync();
  };

  return {
    ...signReportMutation,
    onSignReport
  };
};