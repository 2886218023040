export default class UserRoles {
  public static readonly SuperAdmin = "SuperAdmin";

  public static readonly Admin = "Admin";
  public static readonly Doctor = "Doctor";
  public static readonly DoctorOfficeManager = "DoctorOfficeManager";
  public static readonly DoctorExclusiveMedicalAssistant = "DoctorExclusiveMedicalAssistant";
  public static readonly GeneralMedicalAssistant = "GeneralMedicalAssistant";
  public static readonly GeneralDoctorLiaison = "GeneralDoctorLiaison";
  public static readonly Biller = "Biller";
  public static readonly Historians = "Historians";
  public static readonly Transcriber = "Transcriber";
  public static readonly MedLegalConsultant = "MedLegalConsultant";
  public static readonly Scheduler = "Scheduler";
  public static readonly RecordManagement = "RecordManagement";
  public static readonly Finance = "Finance";
  public static readonly SchedulerManager = "SchedulerManager";
  public static readonly MedLegalConsultantManager = "MedLegalConsultantManager";
  public static readonly HistorianManager = "HistorianManager";
  public static readonly TranscriberManager = "TranscriberManager";
  public static readonly BillerManager = "BillerManager";
  public static readonly DoctorLiaisonManager = "DoctorLiaisonManager";
  public static readonly MedicalAssistantManager = "MedicalAssistantManager";

  public static readonly MedicalTeamRoles: string[] = [
    UserRoles.Doctor,
    UserRoles.DoctorOfficeManager,
    UserRoles.DoctorExclusiveMedicalAssistant,
    UserRoles.GeneralMedicalAssistant,
    UserRoles.GeneralDoctorLiaison,
    UserRoles.DoctorLiaisonManager,
    UserRoles.MedicalAssistantManager
  ];

  public static readonly AdministratorsRoles: string[] = [
    UserRoles.Admin,
    UserRoles.SuperAdmin,
    UserRoles.Finance,
    UserRoles.RecordManagement
  ];

  public static readonly BackOfficeRoles: string[] = [
    UserRoles.Biller,
    UserRoles.Historians,
    UserRoles.Transcriber,
    UserRoles.MedLegalConsultant,
    UserRoles.Scheduler,
    UserRoles.SchedulerManager,
    UserRoles.MedLegalConsultantManager,
    UserRoles.HistorianManager,
    UserRoles.TranscriberManager,
    UserRoles.BillerManager
  ];

  public static readonly List: Array<{ name: string, displayName: string }> = [
    { name: UserRoles.SuperAdmin, displayName: "Super Admin" },
    { name: UserRoles.Admin, displayName: "Admin" },
    { name: UserRoles.Doctor, displayName: "Doctor" },
    { name: UserRoles.DoctorOfficeManager, displayName: "Doctor Office Manager" },
    { name: UserRoles.DoctorExclusiveMedicalAssistant, displayName: "Doctor Exclusive Medical Assistant" },
    { name: UserRoles.GeneralMedicalAssistant, displayName: "General Medical Assistant" },
    { name: UserRoles.GeneralDoctorLiaison, displayName: "General Doctor Liaison" },
    { name: UserRoles.Biller, displayName: "Biller" },
    { name: UserRoles.Historians, displayName: "Historians" },
    { name: UserRoles.Transcriber, displayName: "Transcriber" },
    { name: UserRoles.MedLegalConsultant, displayName: "Med-Legal Consultant" },
    { name: UserRoles.Scheduler, displayName: "Scheduler" },
    { name: UserRoles.RecordManagement, displayName: "Record Management" },
    { name: UserRoles.Finance, displayName: "Finance" },
    { name: UserRoles.SchedulerManager, displayName: "Scheduler Manager" },
    { name: UserRoles.MedLegalConsultantManager, displayName: "Med Legal Consultant Manager" },
    { name: UserRoles.HistorianManager, displayName: "Historian Manager" },
    { name: UserRoles.TranscriberManager, displayName: "Transcriber Manager" },
    { name: UserRoles.BillerManager, displayName: "Biller Manager" },
    { name: UserRoles.DoctorLiaisonManager, displayName: "Doctor Liaison Manager" },
    { name: UserRoles.MedicalAssistantManager, displayName: "Medical Assistant Manager" }
  ];
}