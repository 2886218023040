import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";

export const useUserGroupsLookup = () => {
  const lookupUserGroupsQuery = useQuery({
    queryKey: [LookupService.userGroups.queryKey],
    queryFn: LookupService.userGroups.request
  });

  const getUserGroupNameById = (groupId?: string | null) => {
    if(!groupId) return "";
    const userGroups = lookupUserGroupsQuery.data;
    return userGroups?.find(group => group.id === groupId)?.name ?? "";
  };

  return {
    ...lookupUserGroupsQuery,
    getUserGroupNameById
  };
};