import { Box } from "@mui/material";
import { LogoSectionBrowser } from "components/logo";
import { ProfileSection } from "components/layout/profile";
import { observer } from "mobx-react-lite";

function Header() {
  return (
    <>
      <LogoSectionBrowser/>
      
      <Box sx={{ flexGrow: 1 }}/>
      <Box sx={{ flexGrow: 1 }}/>
      
      <ProfileSection/>
    </>
  );
}

export default observer(Header);