import { styled } from "@mui/material/styles";
import { Chip, ChipProps } from "@mui/material";

const ChipStatus = styled(Chip)`
  min-width: 101px;
  min-height: 24px;
  border-radius: 4px;
  background-color: rgba(34, 181, 156, 0.1);
`;

type PrimeChipProps = ChipProps & {

}
function PrimeChip(props: PrimeChipProps) {
  return <ChipStatus {...props} />;
}

export default PrimeChip;