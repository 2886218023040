import { useMutation } from "@tanstack/react-query";
import { AppointmentService } from "api";
import { ErrorsUtils, SnackbarUtils } from "../../utils";
import { ApiError } from "types";
import { store } from "stores";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export const useCompleteDepositionOrEvaluationMutation = (isDeposition = false) => {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const navigate = useNavigate();
  const completeDepositionOrEvaluationMutation = useMutation({
    mutationKey: [AppointmentService.complete.queryKey],
    mutationFn: (appointmentId: string) => AppointmentService.complete.request(appointmentId),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      const message = isDeposition ? "Deposition has been Archived successfully" : "Evaluation has been completed successfully, Appointment moved to Active Reports";
      SnackbarUtils.success(message);
      navigate(-1);
    }
  });

  const onCompleteDepositionOrEvaluation = (appointmentId: string) => {
    return completeDepositionOrEvaluationMutation.mutate(appointmentId);
  };

  const onConfirmCompleteDepositionOrEvaluation = () => {
    store.confirmModalStore.open({
      title: `Confirm complete appointment ${isDeposition ? "deposition" : "evaluation"}`,
      body: <p>Are you sure you want to complete appointment { isDeposition ? "deposition" : "evaluation" }?</p>,
      options: {
        onConfirm() {
          onCompleteDepositionOrEvaluation(appointmentId!);
        }
      }
    });
  };

  return {
    ...completeDepositionOrEvaluationMutation,
    onCompleteDepositionOrEvaluation,
    onConfirmCompleteDepositionOrEvaluation
  };
};