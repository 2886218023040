function CalenderIcon() {
  return (
    <svg
      width='23'
      height='24'
      viewBox='0 0 23 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.847 1.20805H17.4612V0.724832C17.4612 0.532595 17.3848 0.348231 17.2489 0.212298C17.113 0.0763661 16.9286 0 16.7364 0C16.5441 0 16.3598 0.0763661 16.2238 0.212298C16.0879 0.348231 16.0115 0.532595 16.0115 0.724832V1.20805H6.7804V0.724832C6.7804 0.532595 6.70404 0.348231 6.5681 0.212298C6.43217 0.0763661 6.24781 0 6.05557 0C5.86333 0 5.67897 0.0763661 5.54304 0.212298C5.4071 0.348231 5.33074 0.532595 5.33074 0.724832V1.20805H4.94497C3.63387 1.20933 2.37684 1.73073 1.44976 2.65781C0.522676 3.5849 0.00127933 4.84193 0 6.15302V19.055C0.00127933 20.3661 0.522676 21.6232 1.44976 22.5502C2.37684 23.4773 3.63387 23.9987 4.94497 24H11.0255C11.2177 24 11.4021 23.9236 11.538 23.7877C11.674 23.6518 11.7503 23.4674 11.7503 23.2752C11.7503 23.0829 11.674 22.8986 11.538 22.7626C11.4021 22.6267 11.2177 22.5503 11.0255 22.5503H4.94497C4.01828 22.5493 3.12986 22.1807 2.47459 21.5254C1.81933 20.8701 1.45073 19.9817 1.44966 19.055V9.2996H21.3423V12.2336C21.3423 12.4258 21.4186 12.6102 21.5546 12.7461C21.6905 12.882 21.8749 12.9584 22.0671 12.9584C22.2594 12.9584 22.4437 12.882 22.5796 12.7461C22.7156 12.6102 22.7919 12.4258 22.7919 12.2336V6.15302C22.7907 4.84193 22.2693 3.5849 21.3422 2.65781C20.4151 1.73073 19.1581 1.20933 17.847 1.20805ZM1.44966 7.84993V6.15302C1.45073 5.22634 1.81933 4.33791 2.47459 3.68265C3.12986 3.02738 4.01828 2.65878 4.94497 2.65772H5.33074V3.13933C5.33074 3.33157 5.4071 3.51593 5.54304 3.65186C5.67897 3.7878 5.86333 3.86416 6.05557 3.86416C6.24781 3.86416 6.43217 3.7878 6.5681 3.65186C6.70404 3.51593 6.7804 3.33157 6.7804 3.13933V2.65772H16.0107V3.13933C16.0107 3.33157 16.0871 3.51593 16.223 3.65186C16.359 3.7878 16.5433 3.86416 16.7356 3.86416C16.9278 3.86416 17.1122 3.7878 17.2481 3.65186C17.384 3.51593 17.4604 3.33157 17.4604 3.13933V2.65772H17.8462C18.7729 2.65857 19.6615 3.0271 20.3168 3.68241C20.9721 4.33772 21.3406 5.22627 21.3415 6.15302V7.84993H1.44966Z'
        fill='#929292'
      />
      <path
        d='M6.85935 12.8164H5.72459C5.53235 12.8164 5.34799 12.8928 5.21205 13.0287C5.07612 13.1646 4.99976 13.349 4.99976 13.5412C4.99976 13.7335 5.07612 13.9178 5.21205 14.0538C5.34799 14.1897 5.53235 14.2661 5.72459 14.2661H6.85935C7.05159 14.2661 7.23596 14.1897 7.37189 14.0538C7.50782 13.9178 7.58419 13.7335 7.58419 13.5412C7.58419 13.349 7.50782 13.1646 7.37189 13.0287C7.23596 12.8928 7.05159 12.8164 6.85935 12.8164ZM11.9638 12.8164H10.829C10.6368 12.8164 10.4524 12.8928 10.3165 13.0287C10.1806 13.1646 10.1042 13.349 10.1042 13.5412C10.1042 13.7335 10.1806 13.9178 10.3165 14.0538C10.4524 14.1897 10.6368 14.2661 10.829 14.2661H11.9638C12.156 14.2661 12.3404 14.1897 12.4763 14.0538C12.6123 13.9178 12.6886 13.7335 12.6886 13.5412C12.6886 13.349 12.6123 13.1646 12.4763 13.0287C12.3404 12.8928 12.156 12.8164 11.9638 12.8164ZM6.85935 17.5842H5.72459C5.53235 17.5842 5.34799 17.6606 5.21205 17.7965C5.07612 17.9324 4.99976 18.1168 4.99976 18.309C4.99976 18.5013 5.07612 18.6856 5.21205 18.8216C5.34799 18.9575 5.53235 19.0339 5.72459 19.0339H6.85935C7.05159 19.0339 7.23596 18.9575 7.37189 18.8216C7.50782 18.6856 7.58419 18.5013 7.58419 18.309C7.58419 18.1168 7.50782 17.9324 7.37189 17.7965C7.23596 17.6606 7.05159 17.5842 6.85935 17.5842ZM17.7053 13.8255C16.3564 13.827 15.0633 14.3635 14.1095 15.3173C13.1557 16.2711 12.6192 17.5642 12.6177 18.9131C12.6194 20.2617 13.156 21.5547 14.1098 22.5083C15.0635 23.4619 16.3566 23.9983 17.7053 23.9998C19.0539 23.9983 20.3468 23.4619 21.3005 22.5083C22.2541 21.5546 22.7905 20.2617 22.792 18.9131C22.7905 17.5644 22.2541 16.2713 21.3005 15.3176C20.3469 14.3638 19.054 13.8272 17.7053 13.8255ZM17.7053 22.5501C15.6999 22.5501 14.0674 20.9184 14.0674 18.9122C14.0674 16.9061 15.6991 15.2744 17.7053 15.2744C19.7114 15.2744 21.3423 16.9061 21.3423 18.9122C21.3423 20.9184 19.7106 22.5501 17.7053 22.5501Z'
        fill='#929292'
      />
      <path
        d='M19.1976 18.1893H18.4301V17.4201C18.4301 17.2279 18.3538 17.0435 18.2178 16.9076C18.0819 16.7717 17.8975 16.6953 17.7053 16.6953C17.5131 16.6953 17.3287 16.7717 17.1928 16.9076C17.0568 17.0435 16.9805 17.2279 16.9805 17.4201V18.1893H16.2121C16.0199 18.1893 15.8355 18.2656 15.6996 18.4016C15.5637 18.5375 15.4873 18.7219 15.4873 18.9141C15.4873 19.1063 15.5637 19.2907 15.6996 19.4266C15.8355 19.5626 16.0199 19.6389 16.2121 19.6389H16.9805V20.4073C16.9805 20.5995 17.0568 20.7839 17.1928 20.9198C17.3287 21.0557 17.5131 21.1321 17.7053 21.1321C17.8975 21.1321 18.0819 21.0557 18.2178 20.9198C18.3538 20.7839 18.4301 20.5995 18.4301 20.4073V19.6389H19.1976C19.3899 19.6389 19.5742 19.5626 19.7102 19.4266C19.8461 19.2907 19.9225 19.1063 19.9225 18.9141C19.9225 18.7219 19.8461 18.5375 19.7102 18.4016C19.5742 18.2656 19.3899 18.1893 19.1976 18.1893Z'
        fill='#929292'
      />
    </svg>
  );
}

export default CalenderIcon;
