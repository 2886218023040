import { Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { PrimeMenu } from "components/common/menu";

type FilterMenuProps = PropsWithChildren & {
  isMenuOpen: boolean;
  handleMenuClose(): void;
  anchorEl: HTMLElement | null
}

function FilterMenu({ children, handleMenuClose, isMenuOpen, anchorEl }: FilterMenuProps) {
  return (
    <PrimeMenu
      anchorEl={anchorEl}
      id="filter-menu"
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography py={1} pl={2} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Filter
      </Typography>
      {children}
    </PrimeMenu>
  );
}

export default FilterMenu;