import { useQuery } from "@tanstack/react-query";
import { DuplicatePaymentManagementService } from "api";

export const useDuplicatePaymentDetailsQuery = (paymentId?: string) => {
  const query = useQuery({
    queryKey: [DuplicatePaymentManagementService.details.queryKey, paymentId],
    queryFn: () => DuplicatePaymentManagementService.details.request(paymentId!),
    enabled: !!paymentId
  });

  return {
    ...query
  };
};
