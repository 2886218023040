import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { RoleManagementService } from "api";
import { ApiError, RoleClaimsForUpdateDto } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";

export const useUpdateRoleMutation = () => {
  const navigate = useNavigate();
  const { roleId } = useParams<{ roleId: string }>();
  const updateRoleMutation = useMutation({
    mutationKey: [RoleManagementService.update.queryKey],
    mutationFn: (roles: RoleClaimsForUpdateDto[]) => RoleManagementService.update.request(roleId!, roles),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Role updated successfully");
      navigate(-1);
    }
  });

  const onUpdateRoles = (roles: RoleClaimsForUpdateDto[]) => {
    updateRoleMutation.mutate(roles);
  };

  return {
    ...updateRoleMutation,
    onUpdateRoles
  };
};