import { APP_CONFIGURATIONS } from "config";
import { DocumentInfo } from "types";

export default class DocumentUtils {
  static setDocumentMetadata() {
    document.title = APP_CONFIGURATIONS.REACT_APP_TENANT_NAME;

    const faviconLink: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    faviconLink.type = "image/x-icon";
    faviconLink.rel = "icon";

    faviconLink.href = APP_CONFIGURATIONS.REACT_APP_FAVICON_PATH;

    document.getElementsByTagName("head")[0].appendChild(faviconLink);
  }

  static prepareFilesInfo(files: File[]): DocumentInfo[] {
    return files.map(file => ({
      fileName: file.name,
      fileSize: file.size
    }));
  }

  static splitFile(file: File, chunkSize: number) {
    const chunks: Blob[] = [];
    const checkSizeMB = chunkSize / 1024 / 1024;
    const fileSizeMB = file.size / 1024 / 1024;

    if (checkSizeMB > fileSizeMB) {
      chunks.push(file);
      return chunks;
    }

    let start = 0;
    let end = chunkSize;
    while (start < file.size) {
      chunks.push(file.slice(start, end));
      start = end;
      end = start + chunkSize;
    }

    return chunks;
  }
}
