function SwitchToDoctor() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <g fill="#929292" fillRule="nonzero">
          <path d="M13.05 19c-1.307 0-2.392-.458-3.255-1.375S8.5 15.612 8.5 14.339v-.613a3.913 3.913 0 0 1-2.494-1.323C5.336 11.65 5 10.759 5 9.731V5.7h2.1V5h1.05v2.453H7.1v-.7H6.05V9.73c0 .83.289 1.533.866 2.111.578.579 1.28.868 2.109.868.828 0 1.531-.29 2.109-.868.577-.578.866-1.282.866-2.111V6.752h-1.05v.701H9.9V5h1.05v.7h2.1v4.03c0 1.029-.335 1.92-1.006 2.673a3.913 3.913 0 0 1-2.494 1.323v.613c0 .993.33 1.843.989 2.55.659.706 1.496 1.06 2.511 1.06.945 0 1.765-.35 2.459-1.052.694-.7 1.041-1.553 1.041-2.558V12.99a1.907 1.907 0 0 1-1.006-.683 1.863 1.863 0 0 1-.394-1.174c0-.538.187-.993.56-1.367.373-.374.828-.56 1.365-.56s.992.186 1.365.56c.373.374.56.83.56 1.367 0 .444-.131.835-.394 1.174a1.907 1.907 0 0 1-1.006.683v1.35c0 1.296-.446 2.397-1.339 3.302C15.37 18.547 14.298 19 13.05 19zM8.55.5a9.579 9.579 0 0 1 1.712-.388A13.42 13.42 0 0 1 12 0c1.567 0 3.046.28 4.438.838a11.989 11.989 0 0 1 3.7 2.325 12.328 12.328 0 0 1 2.637 3.5A11.666 11.666 0 0 1 24 11h-2a9.588 9.588 0 0 0-.95-3.362 10.095 10.095 0 0 0-4.775-4.688c-1.05-.5-2.175-.8-3.375-.9l1.55 1.55-1.4 1.4L8.55.5zm6.9 23a9.579 9.579 0 0 1-1.712.387A13.42 13.42 0 0 1 12 24c-1.567 0-3.046-.28-4.438-.837a11.989 11.989 0 0 1-3.7-2.326 12.328 12.328 0 0 1-2.637-3.5A11.666 11.666 0 0 1 0 13h2c.133 1.2.454 2.32.962 3.363a9.883 9.883 0 0 0 1.975 2.75 10.248 10.248 0 0 0 2.788 1.937c1.05.5 2.175.8 3.375.9L9.55 20.4l1.4-1.4 4.5 4.5z"/>
        </g>
      </g>
    </svg>
  );
}

export default SwitchToDoctor;