import { useFormContext } from "react-hook-form";
import { AppointmentForCreateUpdateFormContext } from "types/AppointmentForCreateUpdateFormContext";
import { useUsersLookupQuery } from "hooks/lookups";
import { APPOINTMENT_NOTICE, UserRoles } from "app-constants";
import { useDoctorAssignedToPatientFileMutation } from "hooks/doctor-management";
import { useEffect, useMemo } from "react";
import {
  ApiError,
  AppointmentType,
  DoctorAssignedToPatienForAppointmentCreateUpdateDto, EAppointmentType,
  Option
} from "types";
import { ErrorsUtils } from "utils";

export const useSupplementalAppointmentForm = () => {
  const formContext = useFormContext<AppointmentForCreateUpdateFormContext>();
  const patientFileId = formContext.watch("patientFileId");
  const { data: doctorsLookup } = useUsersLookupQuery(UserRoles.Doctor);
  const { onFetchDoctorAssignedToPatientFile } = useDoctorAssignedToPatientFileMutation();
  const appointmentType = useMemo<Array<{ id: AppointmentType, label: string }>>(() => {
    return [
      {
        id: EAppointmentType.Supplemental,
        label: "Supplemental"
      }
    ];
  }, []);

  const appointmentNoticeRequesterOptions = useMemo<Option[]>(() => APPOINTMENT_NOTICE, []);

  useEffect(() => {
    if(patientFileId) {
      formContext.clearErrors();
    }
  }, [formContext, patientFileId]);

  const resetFields = () => {
    formContext.setValue("fileId", "");
    formContext.setValue("doctorUserId", "");
    formContext.setValue("instructions", "");
    formContext.setValue("generalMedicalLiaisonId", "");
    formContext.setValue("doctorExclusiveMedicalAssistantId", "");
    formContext.setValue("doctorOfficeManagerId", "");
  };
  const fillNonEditableFields = (response: DoctorAssignedToPatienForAppointmentCreateUpdateDto) => {
    formContext.setValue("doctorUserId", response.id);
    formContext.setValue("generalMedicalLiaisonId", response.generalMedicalLiaisonId);
    formContext.setValue("doctorExclusiveMedicalAssistantId", response.doctorExclusiveMedicalAssistantId);
    formContext.setValue("doctorOfficeManagerId", response.doctorOfficeManagerId);
    formContext.setValue("specialityId", response.specialityIdAssignedToPatientFile ?? "");
  };

  const onChangePatientProfile = async (patientFileId?: string) => {
    if(!patientFileId) {
      resetFields();
      return;
    }
    try {
      const response = await onFetchDoctorAssignedToPatientFile(patientFileId);
      fillNonEditableFields(response);
    } catch(e) {
      ErrorsUtils.renderApiErrors(e as ApiError);
    }
  };

  return {
    doctorsLookup,
    appointmentType,
    appointmentNoticeRequesterOptions,
    onChangePatientProfile
  };
};
