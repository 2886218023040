import { useMutation } from "@tanstack/react-query";
import { ActiveReportService } from "api";
import { ActiveReportForCreateUpdateDto, ApiError } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useNavigate } from "react-router-dom";

export const useCreateActiveReportMutation = () => {
  const navigate = useNavigate();
  const createActiveReportMutation = useMutation({
    mutationKey: [ActiveReportService.create.queryKey],
    mutationFn: (appointment: ActiveReportForCreateUpdateDto) => ActiveReportService.create.request(appointment),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess: () => {
      SnackbarUtils.success("Active report created successfully!");
      navigate(-1);
    }
  });

  const onCreateActiveReport = (appointment: ActiveReportForCreateUpdateDto) => {
    if(!appointment) return;
    createActiveReportMutation.mutate(appointment);
  };

  return {
    ...createActiveReportMutation,
    onCreateActiveReport
  };
};