import { useMutation } from "@tanstack/react-query";
import { DocumentsService } from "api";
import { AbortMultipartUploadDto } from "types";

type DocumentMultipartAbortMutationPayload = {
  URL: string;
  entityId: string;
  folderId: string;
  abort: AbortMultipartUploadDto;
};
export const useDocumentMultipartAbortMutation = () => {
  const documentMultipartAbortMutation = useMutation({
    mutationKey: [DocumentsService.abortMultipartUpload.queryKey],
    mutationFn: (payload: DocumentMultipartAbortMutationPayload) =>
      DocumentsService.abortMultipartUpload.request(
        payload.URL,
        payload.entityId,
        payload.folderId,
        payload.abort
      )
  });

  const abortMultipartDocumentUpload = (payload: DocumentMultipartAbortMutationPayload) => {
    return documentMultipartAbortMutation.mutateAsync(payload);
  };

  return {
    ...documentMultipartAbortMutation,
    abortMultipartDocumentUpload
  };
};
