import { ComponentType, Suspense, ComponentProps } from "react";
import { Loader } from "components/app-loader";

const WithLoadable = <T extends ComponentType<any>>(Component: T) => (props: ComponentProps<T>) => (
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>
);

export default WithLoadable;
