import React, { useRef, useState } from "react";
import { Box, ButtonBase, MenuItem, Stack, Typography } from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { useTheme } from "@mui/material/styles";
import { useLogout } from "hooks";
import { useMsal } from "@azure/msal-react";
import { PrimeMenu } from "components/common";
function ProfileSection() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { onLogoutHandler } = useLogout();
  const anchorRef = useRef(null);
  const theme = useTheme();
  const { accounts } = useMsal();
  const account = accounts[0];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    await onLogoutHandler();
    handleClose();
  };

  const renderedUsername = <Typography variant="subtitle1">{ account.name }</Typography>;

  return (

    <Box sx={{
      flexShrink: 0,
      ml: 0.75
    }}>
      <ButtonBase
        sx={{
          p: 0.25,
          backgroundColor: "transparent",
          borderRadius: 1,
          "&:hover": { backgroundColor: "secondary.lighter" }
        }}
        aria-label="open profile"
        ref={ anchorRef }
        aria-haspopup="true"
        aria-controls={ open ? "doctor-profile" : undefined }
        aria-expanded={ open ? "true" : undefined }
        onClick={ handleClick }
        id="doctor-profile"
      >
        <Stack direction="row" spacing={ 2 } alignItems="center" sx={ { p: 0.5 } }>
          { renderedUsername }
          <ArrowDropDownOutlinedIcon sx={ {
            fontSize: "1.6rem",
            cursor: "pointer",
            marginLeft: "0px !important",
            transition: "all .2s ease-in-out",
            color: theme.palette.text.secondary
          } }/>
        </Stack>
      </ButtonBase>
      <PrimeMenu
        id="doctor-profile"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            minWidth: "170px"
          }
        }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </PrimeMenu>
    </Box>
  );
}

export default ProfileSection;