import "./ZeroStateTemplate.scss";

import { Box, Stack } from "@mui/material";
import { PrimeButton } from "components/common";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ZeroStateImage from "assets/images/zero-state.webp";
import { ZeroStateTemplateProps } from "types";
import { AuthorizationWrapper } from "components/permissions";

type GeneralScreenTemplateProps = ZeroStateTemplateProps & {};

function ZeroStateTemplate({
  title,
  description,
  onAdd,
  permissions,
  ...rest
}: GeneralScreenTemplateProps) {
  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      spacing={1}
      sx={{ height: "80vh" }}
      direction='column'
    >
      <Box className='oval'>
        <img
          className='zero-template-image'
          src={ZeroStateImage}
          alt='template'
        />
      </Box>
      <Box>
        <Stack alignItems='center' spacing={1} mt={0}>
          <span className='zero-template-title'>{title}</span>
          <span className='zero-template-description'>{description}</span>
        </Stack>
      </Box>
      <Box>
        {rest.buttonElement ? (
          rest.buttonElement
        ) : (
          <AuthorizationWrapper permissions={permissions ?? []}>
            <PrimeButton
              color='secondary'
              onClick={onAdd}
              startIcon={<AddCircleRoundedIcon />}
            >
              Add
            </PrimeButton>
          </AuthorizationWrapper>
        )}
      </Box>
    </Stack>
  );
}

export default ZeroStateTemplate;
