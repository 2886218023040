const OPTIONS = [
  { id: "CheckingAccount", label: "Checking Account" },
  { id: "SavingsAccount", label: "Savings Account" }
];

const MAPPED_TYPES: { [key: string]: string } = {
  CheckingAccount: "Checking Account",
  SavingsAccount: "Savings Account"
};

const BANK_TYPE_OF_ACCOUNTS = Object.freeze({
  OPTIONS,
  MAPPED_TYPES
});

export default BANK_TYPE_OF_ACCOUNTS;