import { useMutation } from "@tanstack/react-query";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";
import { store } from "stores";
import React from "react";
import { DoctorManagementService } from "api";
import { Typography } from "@mui/material";

export const usePromotingUserToDoctorMutation = () => {
  const promotingUserToDoctorMutation = useMutation({
    mutationKey: [DoctorManagementService.promoteToDoctor.queryKey],
    mutationFn: (userId: string) => DoctorManagementService.promoteToDoctor.request(userId),
    onSuccess() {
      SnackbarUtils.success("Role has been successfully changed to Doctor");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });
  
  const onPromotingUserToDoctor = async (userId: string) => {
    return await promotingUserToDoctorMutation.mutateAsync(userId);
  };
  
  const promotingUserToDoctorHandler = (userId: string, userFullName: string, callback?: Function) => {
    store.confirmModalStore.open({
      title: "Promotion to Doctor Role",
      body: (
        <Typography variant="body2">
          Are you sure you want to change <strong>{userFullName}</strong> role to Doctor?
        </Typography>
      ),
      options: {
        onConfirm: async () => {
          try {
            await onPromotingUserToDoctor(userId);
            if(callback) await callback();
          } catch(e) {
            console.error(e);
          }
        }
      }
    });
  };
  
  return {
    ...promotingUserToDoctorMutation,
    onPromotingUserToDoctor,
    promotingUserToDoctorHandler
  };
};