import { ApiURLs, httpClient } from "api/core";
import {
  DuplicatePaymentForCreateUpdateDto,
  DuplicatePaymentForReadDto,
  PaginatedDataTable,
  PaymentProfileForReadDto,
  type DuplicatePaymentForListItemDto
} from "types";

const URL = ApiURLs.duplicatePayment;

async function getDuplicatePayments(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<DuplicatePaymentForListItemDto>>(`${URL}?${queryParams}`);
}

async function createDuplicatePayment(
  payment: DuplicatePaymentForCreateUpdateDto
) {
  return await httpClient.post<void>(URL, payment);
}

async function deleteDuplicatePayment(paymentId: string) {
  return await httpClient.delete<void>(`${URL}/${paymentId}`);
}

async function getDuplicatePaymentDetails(paymentId: string) {
  return await httpClient.get<DuplicatePaymentForReadDto>(
    `${URL}/${paymentId}`
  );
}
async function updateDuplicatePayment(
  payment: DuplicatePaymentForCreateUpdateDto
) {
  return await httpClient.put<PaymentProfileForReadDto>(
    `${URL}/${payment.id}`,
    payment
  );
}

async function payBack({ duplicatePaymentId, paybackDate }: { duplicatePaymentId: string; paybackDate: string }) {
  return await httpClient.put<void>(`${URL}/${duplicatePaymentId}/pay-back`, { paybackDate });
}

export const DuplicatePaymentManagementService = {
  list: {
    request: getDuplicatePayments,
    queryKey: "duplicate-payments-list"
  },
  create: {
    request: createDuplicatePayment,
    mutationKey: "create-duplicate-payment"
  },
  details: {
    request: getDuplicatePaymentDetails,
    queryKey: "get-duplicate-payment-details"
  },
  update: {
    request: updateDuplicatePayment,
    mutationKey: "update-duplicate-payment"
  },
  delete: {
    request: deleteDuplicatePayment,
    mutationKey: "delete-duplicate-payment"
  },
  payBack: {
    request: payBack,
    mutationKey: "pay-back"
  }
};
