import { makeAutoObservable } from "mobx";

export default class FormLayoutStore {
  header: string = "";
  
  constructor() {
    makeAutoObservable(this);
  }
  
  setPageHeader = (header: string) => {
    this.header = header;
  };
  
  resetStore = () => {
    this.header = "";
  };
}