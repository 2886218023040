import { usePaginatedDataTableQuery } from "hooks/utils/usePaginatedDataTableQuery";
import { LocationService } from "api";
import { LocationForListDto } from "types";

export const useLocationsListQuery = () => {
  const paginatedDataTableQuery = usePaginatedDataTableQuery<LocationForListDto>({
    queryFn: LocationService.list.request,
    queryKey: LocationService.list.queryKey
  });
  
  const onFetchData = async () => {
    await paginatedDataTableQuery.refetch();
  };
  
  return {
    onFetchData,
    ...paginatedDataTableQuery
  };
};