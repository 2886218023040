import { useMutation } from "@tanstack/react-query";
import { ActiveReportService } from "api";
import { useNavigate, useParams } from "react-router-dom";
import { ApiError } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { store } from "stores";

export const useMoveToFinancialAndBillingMutation = () => {
  const { activeReportId } = useParams<{ activeReportId: string }>();
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationKey: [ActiveReportService.moveToFinancialAndBilling.queryKey],
    mutationFn: (activeReportId: string) => ActiveReportService.moveToFinancialAndBilling.request(activeReportId),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success(
        "Report has been moved to financial and billing successfully"
      );
      navigate(-1);
    }
  });

  const onMoveToFinancialAndBilling = (activeReportId: string) => {
    return mutation.mutate(activeReportId);
  };

  const onConfirmMoveToFinancialAndBilling = () => {
    store.confirmModalStore.open({
      title: "Confirm move to financial and billing",
      body: (
        <p>
          Are you sure you want to move this report to financial and billing?
        </p>
      ),
      options: {
        onConfirm() {
          onMoveToFinancialAndBilling(activeReportId!);
        }
      }
    });
  };

  return {
    ...mutation,
    onConfirmMoveToFinancialAndBilling
  };
};
