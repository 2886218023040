import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { BrowserView, MobileView } from "react-device-detect";
import { useStore } from "stores";
import variables from "assets/scss/abstracts/_variables.scss";
import { observer } from "mobx-react-lite";
import { MenuItems } from "components/layout/menu";
import { items } from "components/layout/sidebar";
import { LogoSectionMobile } from "components/logo";
import { ThemeOption } from "types";

type SidebarProps = {
  drawerOpen: boolean
}
const Sidebar = ({ drawerOpen }: SidebarProps) => {
  const colors: ThemeOption = { ...variables };
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const { commonStore } = useStore();
  
  const drawer = (
    <>
      <LogoSectionMobile/>
      <BrowserView>
        <MenuItems menuListItems={items}/>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuItems menuListItems={items}/>
        </Box>
      </MobileView>
    </>
  );
  
  return (
    <Box component="nav" sx={{
      flexShrink: { md: 0 },
      width: matchUpMd ? `${colors.drawerWidth}px` : "auto"
    }} aria-label="mailbox folders">
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={() => commonStore.toggleNavbar()}
        sx={{
          "& .MuiDrawer-paper": {
            width: `${colors.drawerWidth}px`,
            background: colors.primaryBlue,
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "60px"
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default observer(Sidebar);