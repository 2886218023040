import { useMutation } from "@tanstack/react-query";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";
import { store } from "stores";
import React from "react";
import { DuplicatePaymentManagementService } from "api";

export const useDeleteDuplicatePaymentMutation = () => {
  const mutation = useMutation({
    mutationKey: [DuplicatePaymentManagementService.delete.mutationKey],
    mutationFn: DuplicatePaymentManagementService.delete.request,
    onSuccess() {
      SnackbarUtils.success("Payment deleted successfully!");

    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onDeleteDuplicatePayment = async (paymentId: string) => {
    return await mutation.mutateAsync(paymentId);
  };

  const deletePaymentHandler = (paymentId: string, paymentNumber: string, callback?: Function) => {
    store.confirmModalStore.open({
      title: "Confirm delete payment",
      body: <p>Are you sure you want to delete <strong>{paymentNumber}</strong> payment?</p>,
      options: {
        onConfirm: async () => {
          try {
            await onDeleteDuplicatePayment(paymentId);
            if(callback) await callback();
          } catch(e) {
            console.error(e);
          }
        }
      }
    });
  };

  return {
    ...mutation,
    onDeleteDuplicatePayment,
    deletePaymentHandler
  };
};