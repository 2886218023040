import { Outlet } from "react-router-dom";
import { LayoutWrapper, MainTag } from "layout";
import React from "react";
import { AppBar, Box, Divider, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { useStore } from "stores";
import { Header } from "components/layout/header";
import { Sidebar } from "components/layout/sidebar";
import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "components/error-boundary";

function MainLayout() {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { commonStore } = useStore();
  const leftDrawerOpened = commonStore.isOpenNavbar;
  
  return (
    <LayoutWrapper>
      <Box sx={{ display: "flex" }}>
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            transition: leftDrawerOpened ? theme.transitions.create("width") : "none",
            boxShadow: "0 2px 16px 0 rgba(182, 182, 182, 0.15)",
            paddingRight: "0px !important"
          }}
        >
          <Box position="relative">
            <Toolbar style={{
              padding: "10px 10px 10px 35px"
            }}>
              <Header/>
            </Toolbar>
            <Divider className="line"/>
          </Box>
        </AppBar>
        
        <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}/>
        
        <MainTag theme={theme} open={leftDrawerOpened}>
          <ErrorBoundary>
            <Outlet/>
          </ErrorBoundary>
        </MainTag>
      </Box>
    </LayoutWrapper>
  );
}

export default observer(MainLayout);