import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";
import { useAuthorization } from "hooks";
import { Loader } from "components/app-loader";

type AuthorizationGuardProps = {
  permissions: string[];
}
export default function AuthorizationGuard({ permissions }: AuthorizationGuardProps) {
  const { loading, hasAccess } = useAuthorization(permissions);
  const location = useLocation();

  if (loading) {
    return (
      <Loader/>
    );
  }

  if (!hasAccess) {
    return <Navigate to={`/${AppRoutes.forbidden}`} state={{ from: location }} />;
  }

  return <Outlet />;
}