import { useMutation } from "@tanstack/react-query";
import { DocumentsService } from "api";
import { ApiError } from "types";
import { ErrorsUtils } from "utils";

export const useGetDocumentURLQuery = (URL: string, entityId: string) => {
  const getDocumentURLQuery = useMutation({
    mutationKey: [DocumentsService.documentUrl.queryKey],
    mutationFn: ({ folderId, documentId }: { folderId: string, documentId: string }) => DocumentsService.documentUrl.request(URL, entityId, folderId, documentId),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const getDocumentHandler = (folderId: string, documentId: string) => {
    getDocumentURLQuery.mutateAsync({ documentId, folderId }).then(url => {
      window.open(url, "_blank");
    });
  };

  const getDocumentUrlHandler = async (folderId: string, documentId: string) => {
    return await getDocumentURLQuery.mutateAsync({ documentId, folderId });
  };
  
  return {
    ...getDocumentURLQuery,
    getDocumentHandler,
    getDocumentUrlHandler
  };
};