import { useMutation } from "@tanstack/react-query";
import { DocumentsService } from "api";
import { FileUtils, SnackbarUtils } from "utils";
import { useIsLoadingMutation } from "hooks/utils";
import { useStore } from "stores";

export const useDownloadDocumentMutation = (URL: string, entityId: string) => {
  const { documentsStore } = useStore();
  const downloadDocumentMutation = useMutation({
    mutationKey: [DocumentsService.download.queryKey],
    mutationFn: ({ folderId, documentId }: { folderId: string, documentId: string }) => DocumentsService.download.request(URL, entityId, folderId, documentId, {
      asStream: true
    })
  });

  const downloadDocumentHandler = (documentId: string, folderId: string, documentName: string) => {
    documentsStore.startDownloading();
    downloadDocumentMutation.mutateAsync({ documentId, folderId }).then(data => {
      FileUtils.downloadFile(data, documentName);
      SnackbarUtils.success("Downloaded file successfully!");
    }).catch(() => {
      SnackbarUtils.error("Failed to download file! Please try again.");
    }).finally(() => {
      documentsStore.completeDownloading();
    });
  };

  const { isLoading } = useIsLoadingMutation(DocumentsService.download.queryKey);
  
  return {
    ...downloadDocumentMutation,
    downloadDocumentHandler,
    isLoading
  };
};