import { useMutation } from "@tanstack/react-query";
import { AppointmentService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, AppointmentForCreateUpdateFormContext } from "types";
import { useNavigate, useParams } from "react-router-dom";

type MutationFnProps = {
  appointment: AppointmentForCreateUpdateFormContext;
  appointmentId: string;
}
export const useUpdateAppointmentMutation = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const navigate = useNavigate();

  const updateAppointmentMutation = useMutation({
    mutationKey: [AppointmentService.update.queryKey],
    mutationFn: ({ appointment, appointmentId }: MutationFnProps) => AppointmentService.update.request(appointment, appointmentId),
    onSuccess() {
      SnackbarUtils.success("Appointment updated successfully!");
      navigate(-1);
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onUpdateAppointment = (appointment: AppointmentForCreateUpdateFormContext) => {
    if(!appointment || !appointmentId) return;
    updateAppointmentMutation.mutate({
      appointment,
      appointmentId
    });
  };

  return {
    onUpdateAppointment,
    ...updateAppointmentMutation
  };
};