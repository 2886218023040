import { Option } from "types";

const USER_GENDERS: Option[] = [
  {
    id: "Male",
    label: "Male"
  },
  {
    id: "Female",
    label: "Female"
  },
  {
    id: "Gender Neutral",
    label: "Gender Neutral"
  }
];

export default USER_GENDERS;