import { useMutation } from "@tanstack/react-query";
import { AdjustersManagementService } from "api";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, InsuranceCompanyForCreateUpdateDto } from "types";
import { useNavigate, useParams } from "react-router-dom";

export const useUpdateAdjusterMutation = () => {
  const navigate = useNavigate();
  const { adjusterId } = useParams<{ adjusterId: string }>();
  const updateAdjusterMutation = useMutation({
    mutationKey: [AdjustersManagementService.update.mutationKey],
    mutationFn: (adjuster: InsuranceCompanyForCreateUpdateDto) => AdjustersManagementService.update.request(adjusterId!, adjuster),
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Adjuster updated successfully!");
      navigate(-1);
    }
  });

  const onUpdateAdjuster = (adjuster: InsuranceCompanyForCreateUpdateDto) => {
    if(!adjuster) return;
    updateAdjusterMutation.mutate(adjuster);
  };

  return {
    onUpdateAdjuster,
    ...updateAdjusterMutation
  };
};