import React, { PropsWithChildren, useEffect, useState, Component } from "react";
import { useLocation, useNavigate, NavigateFunction } from "react-router-dom";
import { GeneralScreenTemplate } from "components/general-screen-template";
import NotFoundImagePage from "assets/images/not-found.webp";

type ErrorBoundaryProps = PropsWithChildren<{}>;

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.pathname]);

  return (
    <ErrorBoundaryInner navigate={navigate} hasError={hasError} setHasError={setHasError}>
      {children}
    </ErrorBoundaryInner>
  );
};

interface ErrorBoundaryInnerProps {
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
  navigate: NavigateFunction;
  children: React.ReactNode;
}

interface ErrorBoundaryInnerState {
  hasError: boolean;
}

class ErrorBoundaryInner extends Component<ErrorBoundaryInnerProps, ErrorBoundaryInnerState> {
  state: ErrorBoundaryInnerState = {
    hasError: false
  };

  static getDerivedStateFromError(): ErrorBoundaryInnerState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps: ErrorBoundaryInnerProps) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch() {
    this.props.setHasError(true);
  }

  render() {
    if (this.state.hasError) {
      return (
        <GeneralScreenTemplate
          image={NotFoundImagePage}
          title="Something went wrong!"
          description="Please try again later"
          button={{
            text: "Go Back",
            click: () => this.props.navigate(-1)
          }}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;