import { useForm } from "react-hook-form";
import { FolderForListDto, FolderManagementFormContext } from "types";
import { useCallback, useState } from "react";
import { useStore } from "stores";
import { useIsLoadingMutation } from "hooks/utils";
import { DocumentsService } from "api";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";

export const useFolderManagementState = (folders: FolderForListDto[]) => {
  const { folderId: currentFolderId } = useParams<{ folderId: string }>();
  const [open, setOpen] = useState(false);
  const { folderManagementStore } = useStore();
  const { isLoading: isFetching } = useIsLoadingMutation(DocumentsService.uploadFiles.queryKey);
  const formContext = useForm<FolderManagementFormContext>({
    defaultValues: {
      documentCategory: "",
      attachments: []
    },
    resolver: yupResolver(Yup.object().shape({
      documentCategory: Yup.string().required("This field is required"),
      attachments: Yup.array().min(1, "At least one file is required")
    })),
    mode: "onChange"
  });

  const fileUploadHandler = useCallback((folderId?: string) => {
    folderManagementStore.setFolders(folders);

    if(currentFolderId) {
      formContext.setValue("documentCategory", currentFolderId);
    }else {
      formContext.setValue("documentCategory", folderId ?? "");
    }
    setOpen(true);
  }, [folders, folderManagementStore, formContext, currentFolderId]);

  const closeHandler = useCallback(() => {
    setOpen(false);
    formContext.reset();
  }, [formContext]);

  return {
    open,
    fileUploadHandler,
    closeHandler,
    formContext,
    isFetching
  };
};
