import "./LogoSection.scss";
import { Link } from "react-router-dom";
import { Box, ButtonBase } from "@mui/material";
import { AppRoutes } from "router/AppRoutes";
import { useTheme } from "@mui/material/styles";
import { APP_CONFIGURATIONS } from "config";

function LogoSectionMobile() {
  const theme = useTheme();
  return (
    <Box sx={{
      display: {
        xs: "block",
        md: "none"
      }
    }}>
      <Box sx={{
        display: "flex",
        p: 2,
        mx: "auto",
        backgroundColor: theme.palette.background.paper,
        height: "63px",
        padding: "10px 35px"
      }}>
        <ButtonBase disableRipple component={Link} to={AppRoutes.app}>
          <img className="logo" src={APP_CONFIGURATIONS.REACT_APP_TENANT_LOGO_URL} alt="logo"/>
        </ButtonBase>
      </Box>
    </Box>
  );
}

export default LogoSectionMobile;