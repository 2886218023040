import { ApiURLs, httpClient } from "api/core";
import { FeaturesWithClaimsForListDto, RoleClaimsForUpdateDto } from "types";

const URL = ApiURLs.roleManagement;

async function getRole(roleId: string) {
  return await httpClient.get<FeaturesWithClaimsForListDto[]>(`${URL}/${roleId}/features-with-selected-claims`);
}

async function setRoles(roleId: string, roles: RoleClaimsForUpdateDto[]) {
  return await httpClient.put<void>(`${URL}/${roleId}/set-claims`, roles);
}

export const RoleManagementService = {
  details: {
    request: getRole,
    queryKey: "getRole"
  },
  update: {
    request: setRoles,
    queryKey: "setRoles"
  }
};