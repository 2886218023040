import { useQuery } from "@tanstack/react-query";
import { LocationService } from "api";
import { useParams } from "react-router-dom";

export const useLocationProfileQuery = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const locationProfileQuery = useQuery({
    queryKey: [LocationService.details.queryKey, locationId],
    queryFn: () => LocationService.details.request(locationId!)
  });
  
  return {
    ...locationProfileQuery
  };
};