import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AppointmentService } from "api";
import {
  ApiError,
  AppointmentForRescheduleRequestDto,
  RescheduleCreateFormContext
} from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useParams } from "react-router-dom";

type AppointmentPayload = {
  appointmentId: string;
  appointmentForReschedule: AppointmentForRescheduleRequestDto;
};
export const useAppointmentRescheduleMutation = () => {
  const queryClient = useQueryClient();
  const { appointmentId } = useParams<{ appointmentId: string }>();

  const appointmentRescheduleMutation = useMutation({
    mutationKey: [AppointmentService.reschedule.queryKey],
    mutationFn: ({ appointmentId, appointmentForReschedule }: AppointmentPayload) =>
      AppointmentService.reschedule.request(appointmentId, appointmentForReschedule),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [AppointmentService.details.queryKey, appointmentId] })
        .then(() => {
          SnackbarUtils.success("Appointment rescheduled successfully!");
        });
    }
  });

  const onRescheduleAppointment = (rescheduleCreateFormContext: RescheduleCreateFormContext) => {
    if (!rescheduleCreateFormContext) return;
    return appointmentRescheduleMutation.mutateAsync({
      appointmentId: appointmentId ?? "",
      appointmentForReschedule: {
        reason: rescheduleCreateFormContext.reason,
        note: rescheduleCreateFormContext.note,
        scheduleDetail: rescheduleCreateFormContext.scheduleDetail,
        doctorAvailabilityTimeRange:
          rescheduleCreateFormContext.doctorAvailabilityTimeRange
      }
    });
  };

  return {
    ...appointmentRescheduleMutation,
    onRescheduleAppointment
  };
};
