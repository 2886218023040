import { useMutation } from "@tanstack/react-query";
import { DocumentsService } from "api";
import { ApiError } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { store } from "stores";
import React from "react";

export const useDeleteDocumentMutation = (URL: string, entityId: string) => {
  const deleteDocumentMutation = useMutation({
    mutationKey: [DocumentsService.delete.queryKey],
    mutationFn: ({ folderId, documentId }: { folderId: string, documentId: string }) => DocumentsService.delete.request(URL, entityId, folderId, documentId),
    onSuccess() {
      SnackbarUtils.success("Document deleted successfully!");
    },
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });
  
  const deleteDocumentHandler = (documentId: string, folderId: string, documentName: string, callback?: Function) => {
    store.confirmModalStore.open({
      title: "Confirm Document Deletion",
      body: <p>Are you sure you want to delete <strong>{documentName}</strong> file?</p>,
      options: {
        onConfirm: async () => {
          await deleteDocumentMutation.mutateAsync({
            documentId,
            folderId
          });
          if(callback) await callback();
        }
      }
    });
  };
  
  return {
    ...deleteDocumentMutation,
    deleteDocumentHandler
  };
};