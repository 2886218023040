function PatientIcon() {
  return (
    <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Patients icon</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Left-Side-Nav-/-drop-down" transform="translate(-43, -188)">
          <g id="Group-3-Copy" transform="translate(0, 177)">
            <g id="Group" transform="translate(41, 8)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <g id="single_bed_FILL1_wght400_GRAD0_opsz48" className="sidebar-icon" transform="translate(2, 3)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M3,18 L2.0625,18 L1.4375,15.4285714 L0,15.4285714 L0,8.55 C0,7.99285714 0.177083333,7.52142857 0.53125,7.13571429 C0.885416667,6.75 1.33333333,6.55714286 1.875,6.55714286 L2.6875,6.55714286 L2.6875,1.92857143 C2.6875,1.39285714 2.86979167,0.9375 3.234375,0.5625 C3.59895833,0.1875 4.04166667,0 4.5625,0 L15.40625,0 C15.9270833,0 16.3697917,0.1875 16.734375,0.5625 C17.0989583,0.9375 17.28125,1.39285714 17.28125,1.92857143 L17.28125,6.55714286 L18.125,6.55714286 C18.6458333,6.55714286 19.0885417,6.74464286 19.453125,7.11964286 C19.8177083,7.49464286 20,7.95 20,8.48571429 L20,15.4285714 L18.5625,15.4285714 L17.9375,18 L17,18 L16.375,15.4285714 L3.65625,15.4285714 L3,18 Z M10.9375,6.55714286 L15.40625,6.55714286 L15.40625,1.92857143 L10.9375,1.92857143 L10.9375,6.55714286 Z M4.5625,6.55714286 L9.0625,6.55714286 L9.0625,1.92857143 L4.5625,1.92857143 L4.5625,6.55714286 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PatientIcon;