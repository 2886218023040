import { IconButton, IconButtonProps } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

type PrimeActionIconButtonProps = IconButtonProps & {
  loading?: boolean
}

const StyledPrimeActionIconButton = styled(IconButton)`
  padding: 2.5px;
  border-radius: 4px;
  border: solid 1px #e9e9e9;
  background-color: #fff;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;
function PrimeActionIconButton({ children, loading, ...rest }: PrimeActionIconButtonProps) {
  if(loading) {
    return (
      <StyledPrimeActionIconButton {...rest} >
        <CircularProgress size="25px" color="secondary" />
      </StyledPrimeActionIconButton>
    );
  }

  return (
    <StyledPrimeActionIconButton {...rest} >
      {children}
    </StyledPrimeActionIconButton>
  );
}

export default PrimeActionIconButton;