import { lazy } from "react";
import { WithLoadable } from "components/app-loader";
import { MinimalLayout } from "layout";
import { RouteObject } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";
import AuthGuard from "router/AuthGuard";

const LoginPage = WithLoadable(
  lazy(() => import("pages/authentication/LoginPage"))
);
const PrivacyPolicy = WithLoadable(
  lazy(() => import("pages/privacy-policy/PrivacyPolicy"))
);
const AuthenticationRoutes: RouteObject = {
  path: AppRoutes.app,
  element: <MinimalLayout />,
  children: [
    {
      element: <AuthGuard requiredAuth={false} />,
      children: [
        {
          path: AppRoutes.login,
          element: <LoginPage />
        }
      ]
    },
    {
      element: <AuthGuard requiredAuth={false} />,
      children: [
        {
          path: AppRoutes.privacyPolicy.root,
          element: <PrivacyPolicy />
        }
      ]
    }
  ]
};
export default AuthenticationRoutes;
