import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";
import { BackOfficeTeamKeySelector, BackOfficeTeamLookupListDto } from "types";

const getOfficeTeamNamesByIds = (backOfficeTeamData: BackOfficeTeamLookupListDto, ids: string[], keySelector: BackOfficeTeamKeySelector) => {
  const backOfficeTeamList = backOfficeTeamData[keySelector] ?? [];
  return backOfficeTeamList
    .filter(teamMember => ids.includes(teamMember.id))
    .map(teamMember => teamMember.name);
};

export const useBackOfficeTeamLookupQuery = () => {
  const backOfficeTeamLookupQuery = useQuery({
    queryKey: [LookupService.usersBackOffice.queryKey],
    queryFn: LookupService.usersBackOffice.request
  });

  const getTeamNamesByIds = (ids: string[], keySelector: BackOfficeTeamKeySelector) => {
    if (!backOfficeTeamLookupQuery?.data) {
      return [];
    }
    return getOfficeTeamNamesByIds(backOfficeTeamLookupQuery?.data, ids, keySelector);
  };

  return {
    ...backOfficeTeamLookupQuery,
    getTeamNamesByIds
  };
};
