import React from "react";
import { MRT_TableInstance, MRT_ToggleDensePaddingButton as ToggleDensePaddingButton } from "material-react-table";

type FilterProps<TData extends Record<string, any> = {}> = {
  table: MRT_TableInstance<TData>;
};

function Density<T extends Record<string, any> = {}>({ table }: FilterProps<T>) {
  return <ToggleDensePaddingButton table={table}/>;
}

export default Density;
