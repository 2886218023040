import { Outlet } from "react-router-dom";
import { LayoutWrapper } from "layout";
import React from "react";

function MinimalLayout() {
  return (
    <LayoutWrapper>
      <Outlet/>
    </LayoutWrapper>
  );
}

export default MinimalLayout;
