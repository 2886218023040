import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDownloadSignatureQuery } from "hooks/doctor-management/useDownloadSignatureQuery";

export const useSignature = (eSignatureDocumentId?: string | null) => {
  const { doctorId } = useParams<{ doctorId: string }>();
  const [imageURL, setImageURL] = useState<string>("");
  const [blob, setBlob] = useState<Blob | null>(null);
  const { refetch } = useDownloadSignatureQuery(doctorId!, eSignatureDocumentId);

  const onDownloadImage = useCallback(() => {
    if(!blob) return;

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "e-signature.png");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [blob]);

  useEffect(() => {
    if(eSignatureDocumentId) {
      refetch().then(response => {
        const blob = response.data;
        if(blob) {
          const url = URL.createObjectURL(blob);
          setImageURL(url);
          setBlob(blob);
        }
      });
    }
  }, [eSignatureDocumentId, refetch]);

  return {
    imageURL,
    eSignatureDocumentId,
    onDownloadImage
  };
};