import { useMutation } from "@tanstack/react-query";
import { DoctorManagementService } from "api";
import { ErrorsUtils } from "utils";
import { ApiError, DoctorForCreateUpdateFormContext } from "types";

type UploadDoctorProps = {
  doctorId: string;
  signature: File[] | null
}
export const useUploadSignatureMutation = () => {
  const uploadSignatureMutation = useMutation({
    mutationKey: [DoctorManagementService.uploadSignature.queryKey],
    mutationFn: ({ doctorId, signature }: UploadDoctorProps) => DoctorManagementService.uploadSignature.request(doctorId, signature),
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const onUploadSignature = async (doctorId: string, doctor: DoctorForCreateUpdateFormContext) => {
    return await uploadSignatureMutation.mutateAsync({
      doctorId,
      signature: doctor.signature
    });
  };

  return {
    ...uploadSignatureMutation,
    onUploadSignature
  };
};