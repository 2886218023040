import { LookupService } from "api";
import { useQuery } from "@tanstack/react-query";

export const useAttorneyLookupQuery = (attorneyName: string) => {
  const attorneysLookupQuery = useQuery({
    queryKey: [LookupService.attorneys.queryKey, attorneyName],
    queryFn: ({ queryKey }) => {
      const [, attorneyName] = queryKey;
      return LookupService.attorneys.request(attorneyName);
    },
    enabled: !!attorneyName,
    cacheTime: 0
  });

  return {
    ...attorneysLookupQuery,
    options:
      attorneysLookupQuery.data
        ?.filter(x => !!x.name)
        .map(x => ({ id: x.id, label: x.name })) ?? []
  };
};
