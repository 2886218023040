import { useMutation } from "@tanstack/react-query";
import { PatientService } from "api";
import { ErrorsUtils, ObjectUtils, SnackbarUtils } from "utils";
import {
  ApiError, ApiVersions
} from "types";
import { createSearchParams, useNavigate } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";
import { PatientFileForCreateUpdateDto } from "types/api-v2";
export const useCreatePatientMutation = () => {
  const navigate = useNavigate();
  const createPatientMutation = useMutation({
    mutationKey: [PatientService.create.queryKey],
    mutationFn: (patient: PatientFileForCreateUpdateDto) => PatientService.create.request(patient, ApiVersions.V2),
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess(response) {
      SnackbarUtils.success("Patient created successfully!");
      const params = {
        patientId: response.patientFileId
      };
      navigate({
        pathname: `/${AppRoutes.appointments.root}/${AppRoutes.appointments.create}`,
        search: createSearchParams(params).toString()
      });
    }
  });

  const onCreatePatient = (patient: PatientFileForCreateUpdateDto) => {
    if(!patient) return;
    createPatientMutation.mutate({
      ...patient,
      injuries: patient.injuries.map((i) => ObjectUtils.replaceEmptyStringsWithNull(i))
    });
  };
  
  return {
    onCreatePatient,
    ...createPatientMutation
  };
};