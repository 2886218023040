import { getAccessToken } from "api";
import { AppWindow } from "types";

export default class TokenUtils {
  public static initialize() {
    (window as AppWindow)["generateToken"] = TokenUtils.generateToken;
    console.info("Use 'generateToken()' to generates a token for BE and will copy to clipboard");
  }

  public static async generateToken(): Promise<void> {
    try {
      // Generate a token using the getAccessToken() function
      const token: string = await getAccessToken() ?? "";

      // Create a temporary element to hold the token
      const temporaryTokenElement: HTMLTextAreaElement = document.createElement("textarea");
      temporaryTokenElement.value = `${token}`;

      // Add the temporary element to the document body
      document.body.appendChild(temporaryTokenElement);

      // Select the contents of the temporary element
      temporaryTokenElement.select();

      // Copy the contents of the temporary element to the clipboard
      document.execCommand("copy");

      // Remove the temporary element from the document body
      document.body.removeChild(temporaryTokenElement);

      // Log a success message to the console
      console.info("Token successfully generated and copied to clipboard!");
    } catch (error) {
      // Log an error message to the console if an error occurs
      console.error("Error generating token: ", error);
    }
  }
}
