import { useMutation } from "@tanstack/react-query";
import { LocationService } from "api";
import { ErrorsUtils, ObjectUtils, SnackbarUtils } from "utils";
import { ApiError, LocationForCreateUpdateDto } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";

export const useUpdateLocationMutation = () => {
  const navigate = useNavigate();
  const { locationId } = useParams<{ locationId: string }>();
  const updateLocationMutation = useMutation({
    mutationKey: [LocationService.update.queryKey],
    mutationFn: (location: LocationForCreateUpdateDto) => LocationService.update.request(locationId!, location),
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Location updated successfully!");
      const locationProfilePageUrl = `/${AppRoutes.management.root}/${AppRoutes.locations.root}/${AppRoutes.locations.profile}/${locationId}`;
      navigate(locationProfilePageUrl);
    }
  });
  
  const onUpdateLocation = (location: LocationForCreateUpdateDto) => {
    if(!location) return;
    updateLocationMutation.mutate(ObjectUtils.replaceEmptyStringsWithNull(location));
  };
  
  return {
    onUpdateLocation,
    ...updateLocationMutation
  };
};