import moment from "moment";

export default class SlotUtils {
  
  static formatDateAppointment(appointments: any[]) {
    const currentDate = moment();
    let date = currentDate.date();

    return appointments.map(({ ...restArgs }: any) => {
      const result = {
        ...restArgs
      };
      date += 1;
      if(date > 31) date = 1;
      return result;
    });
  }
}