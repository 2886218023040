import "react-toastify/dist/ReactToastify.css";
import "assets/scss/style.scss";

import React, { PropsWithChildren } from "react";
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ConfirmModalContainer } from "components/common";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "layout";
import theme from "themes";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DownloadLoader } from "components/app-loader";

type LayoutWrapper = PropsWithChildren & {}

function LayoutWrapper({ children }: LayoutWrapper) {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="top-left"/>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme()}>
              <ConfirmModalContainer/>
              <DownloadLoader/>
              <CssBaseline/>
              {children}
            </ThemeProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </>
  );
}

export default observer(LayoutWrapper);